<template>
  <div class="fix-menu">
    <div
      class="modal fade bd-example-modal-lg"
      id="imageModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            البوم تصاویر
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-7">
                  <div class="row">
                    <img :src="selectImageUrl" class="w-100 mr-2 rounded" />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="row">
                    <div
                      class="col-4 pl-2 pr-0 pb-2"
                      v-for="item in model.media.album"
                      v-on:click="selectImageUrl = item.url"
                      :key="item.id"
                    >
                      <img :src="item.url" class="w-100 rounded-lg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="videoModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            ویدیو ها
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <video  class="w-100 mr-2 rounded" controls>
                       <source :src="selectVideoUrl" type="video/mp4">
                    </video>
                  </div>
                </div>
                <!-- <div class="col-md-5">
                  <div class="row">
                    <div
                      class="col-4 pl-2 pr-0 pb-2"
                      v-for="item in model.media.video"
                      v-on:click="selectUrl = item.url"
                      :key="item.id"
                    >
                      <video :src="item.url" class="w-100 rounded-lg" />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="container pt-3">
        <div class="card border-1 rounded-0">
          <div class="row px-3">
            <div class="col-lg-7 col-md-12 col-sm-12 img-project p-0">
              <img
                :src="model.media.cover"
                class="w-100 h-100"
                :alt="model.info.title"
                v-if="model != null && model.media != null && model.media.cover"
              />
              <img src="http://karvam.ir/noimage.jpg" class="w-100" v-else />
              <div class="">
                <button
                  class="btn btnimg rounded-0 d-inline-block"
                  data-toggle="modal"
                  data-target="#imageModal"
                >
                  <i class="far fa-image"></i>
                </button>
                <button
                  class="btn btnvideo rounded-0 d-inline-block"
                  data-toggle="modal"
                  data-target="#videoModal"
                >
                  <i class="fas fa-play"></i>
                </button>
              </div>
            </div>
            <div
              class="col-lg-5 col-md-12 col-sm-12 cardSingleProject text-center"
            >
              <div class="card-body pt-4">
                <h2>{{ model.info.title }}</h2>
                <p class="py-2">{{ model.owner.profile.full_name }}</p>
                <div
                  class="alert alert-secondary"
                  v-if="messagePay"
                  role="alert"
                >
                  {{ messagePay }}
                </div>
                <div class="d-flex justify-content-between participationGift">
                  <div class="row">
                    <div class="col-md-6 py-1">
                      <label class="checking">
                        <input
                          type="checkbox"
                          v-model="check1"
                          v-on:change="chengeCheck1()"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <span> مشارکت نقدی </span>
                    </div>
                    <div class="col-md-6 py-1">
                      <label class="checking">
                        <input
                          type="checkbox"
                          v-model="check2"
                          v-on:change="chengeCheck2()"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <span style="display: inline-block; width: 200px"
                        >اهدای امتیاز بانک</span
                      >
                    </div>
                  </div>
                </div>
                <div class="giftMoney">
                  <div v-if="check1" class="gift">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button class="btn" type="button">
                          ورود مبلغ دلخواه
                        </button>
                      </div>
                      <span class="toman">تومان</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="supported.price"
                      />
                    </div>

                    <div class="checking-gift-1">
                      <label class="checking-gift">
                        <input type="checkbox" checked="checked" />
                        <span class="checkmark-gift"></span>
                      </label>
                      <span class="giftperson-1"
                        >میخواهم ناشناس باقی بمانم</span
                      >
                    </div>
                    <div class="checking-gift-2">
                      <label class="checking-gift">
                        <input type="checkbox" />
                        <span class="checkmark-gift"></span>
                      </label>
                      <span class="giftperson-2"
                        >مبلغ را هدیه میکنم (کمک بلاعوض)</span
                      >
                      <button
                        v-on:click="SendSupported()"
                        class="btn btn-block rounded-lg mt-5"
                      >
                        پرداخت و ثبت
                      </button>
                    </div>
                  </div>
                  <div v-else-if="check2" class="money">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <button class="btn" type="button">
                          شماره سپرده مبدا
                        </button>
                      </div>
                      <input
                        type="text"
                        v-model="resalat.source_account"
                        class="form-control"
                      />
                    </div>
                    <div class="input-group date-date mb-2">
                      <div class="input-group-prepend date-calender-title">
                        <button class="btn" type="button">تاریخ انتقال</button>
                      </div>
                      <date-picker
                        clearable
                        class="date-calender"
                        v-model="resalat.date"
                      />
                      <!-- <input type="text"  /> -->
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <button class="btn" type="button">مبلغ انتقال</button>
                      </div>
                      <input
                        type="text"
                        v-model="resalat.price"
                        class="form-control"
                      />
                    </div>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <button class="btn" type="button">کد رهگیری</button>
                      </div>
                      <input
                        type="text"
                        v-model="resalat.receipt"
                        class="form-control"
                      />
                    </div>
                    <button
                      v-on:click="SendResalat()"
                      class="btn btn-block rounded-lg"
                    >
                      پرداخت و ثبت
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div
          class="progress mt-3 rounded-0 flex-row-reverse"
          :style="{ height: '10px' }"
        >
          <div
            :style="{
              width:
                $Utility.GetPercent(model.finance.collected, model.finance.loan_need) + '%',
            }"
            class="progress-bar progress-bar-striped"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <div class="row mt-2 pricesingle">
          <div class="col-sm">
            <p class="m-0">
              تامین شده: {{ formatPrice(model.finance.collected) }} تومان
            </p>
          </div>
          <div class="col-sm text-right">
            <p class="m-0">
              {{
                 $Utility.GetPercent(model.finance.collected, model.finance.loan_need)
              }}%
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row d-flex justify-content-between px-3">
          <div class="py-3">
            <div class="text-center listSingle">
              <p class="listSingle-Title">مبلغ مورد نیاز</p>
              <p class="listSingle-Topic">
                {{ formatPrice(model.finance.loan_need - model.finance.collected) }}
                تومان
              </p>
            </div>
          </div>
          <div class="py-3">
            <div class="text-center listSingle">
              <p class="listSingle-Title">موضوع پروژه</p>
              <p class="listSingle-Topic">{{ model.info.master_subject }}</p>
            </div>
          </div>
          <div class="py-3">
            <div class="text-center listSingle">
              <p class="listSingle-Title">مکان ایجاد پروژه</p>
              <p class="listSingle-Topic">{{ model.info.city }}</p>
            </div>
          </div>
          <div class="py-3">
            <div class="text-center listSingle">
              <p class="listSingle-Title">وضعیت پروژه</p>
              <p class="listSingle-Topic">{{ model.status.name }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="topnav">
          <button
            :class="activeMenu == 1 ? 'active' : ''"
            v-on:click="ChangeMenu(1)"
          >
            شرح پروژه
          </button>
          <button
            :class="activeMenu == 2 ? 'active' : ''"
            v-on:click="ChangeMenu(2)"
          >
            اطلاعات کامل پروژه
          </button>
          <button
            :class="activeMenu == 3 ? 'active' : ''"
            v-on:click="ChangeMenu(3)"
          >
            حامیان پروژه
          </button>
          <button
            :class="activeMenu == 4 ? 'active' : ''"
            v-on:click="ChangeMenu(4)"
          >
            تاریخچه وضعیت
          </button>
          <button
            :class="activeMenu == 5 ? 'active' : ''"
            v-on:click="ChangeMenu(5)"
          >
            گزارشات
          </button>
          <button
            :class="activeMenu == 6 ? 'active' : ''"
            v-on:click="ChangeMenu(6)"
          >
            نظرات کاربران
          </button>
        </div>
      </div>

      <div class="container-fluid p-0">
        <div class="line"></div>
      </div>
    </div>
    <div class="mb-3" v-if="activeMenu == 1">
      <div class="container">
        <div class="informationProject">
          <div class="row my-3 px-2" v-html="model.info.desc"></div>
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="activeMenu == 2">
      <div class="container">
        <div class="informationProject">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div>
                <div>
                  <h5>شناسه و تاریخ</h5>
                  <div class="lineinfo"></div>
                </div>

                <ul class="listInfoProject">
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span style="">شناسه پروژه</span
                    ><span class="number">{{ model.id }}</span>
                  </li>
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span style="">تاریخ ایجاد</span
                    ><span class="number">{{ model.info.created }}</span>
                  </li>
                  <li style="">
                    <i class="fal fa-trophy-alt"></i>

                    <span style="">زمان شروع</span
                    ><span class="number">{{ model.info.start_date }}</span>
                  </li>
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span>زمان خاتمه</span
                    ><span class="number">{{ model.info.end_date }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h5>مشخصات مالک</h5>
                <div class="lineinfo"></div>
              </div>
              <div v-if="model.owner && model.owner.profile">
                <div class="col-12 d-flex p-0 topprojectmanag py-2">
                  <img
                    width="60px"
                    :src="model.owner.avatar"
                    :alt="model.owner.profile.full_name"
                    v-if="model.owner && model.owner.avatar && model.owner.profile"
                  />
                  <img
                    width="60px"
                    src="http://karvam.ir/noimage.jpg"
                    alt="no image"
                    v-else
                  />
                  <div v-if="model.owner && model.owner.profile">
                    <span v-if="model.owner.profile.gender">جناب آقای</span>
                    <span v-else>سرکار خانم</span>
                    <p>{{ model.owner.profile.full_name }}</p>
                  </div>
                </div>
                <ul class="listInfoProject" v-if="model.owner && model.owner.profile">
                  <li >
                    <i class="fal fa-trophy-alt"></i>

                    <span>سن</span
                    ><span class="number"
                      >{{ model.owner.profile.age }} سال</span
                    >
                  </li>
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span> تحصیلات</span
                    ><span class="number">{{
                      model.owner.profile.education
                    }}</span>
                  </li>
                </ul>
              </div>
              <div v-else>
                مشخصات مالک ثبت نشده است
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h5>مشخصات معرف (امین)</h5>
                <div class="lineinfo"></div>
              </div>
              <div v-if="model.advisor && model.advisor.profile" class="col-12 d-flex p-0 topprojectmanag py-2">
                <img
                  width="60px"
                  :src="model.advisor.avatar"
                  :alt="model.advisor.profile.full_name"
                  v-if="model.advisor && model.advisor.avatar && model.advisor.profile"
                />
                <img
                  width="60px"
                  src="http://karvam.ir/noimage.jpg"
                  alt="no image"
                  v-else
                />
                <div v-if="model.advisor && model.advisor.profile">
                  <span v-if="model.advisor.profile.gender">جناب آقای</span>
                  <span v-else>سرکار خانم</span>
                  <p>{{ model.advisor.profile.full_name }}</p>
                </div>
              </div>
              <div v-if="model.advisor && model.advisor.profile" >
                <ul class="listInfoProject">
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span> تحصیلات</span
                    ><span class="number">{{
                      model.advisor.profile.education
                    }}</span>
                  </li>
                </ul>
              </div>
              <div v-else>
                  مشخصات معرف (امین) ثبت نشده است
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h5>موضوع و رده شغلی پروژه</h5>
                <div class="lineinfo"></div>
              </div>
              <div>
                <ul class="listInfoProject">
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span>موضوع اصلی </span
                    ><span class="number">{{ model.info.master_subject }}</span>
                  </li>
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span style="">موضوع فرعی</span
                    ><span class="number">{{ model.info.subject }}</span>
                  </li>
                  <li style="">
                    <i class="fal fa-trophy-alt"></i>

                    <span style=""> رده شغلی</span
                    ><span class="number">{{ model.info.job_type }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h5>اطلاعات جغرافیایی طرح</h5>
                <div class="lineinfo"></div>
              </div>
              <div>
                <ul class="listInfoProject">
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span> استان محل اجرا </span
                    ><span class="number">{{ model.info.state }}</span>
                  </li>
                  <li>
                    <i class="fal fa-trophy-alt"></i>
                    <span style=""> شهر محل اجرا</span
                    ><span class="number">{{ model.info.city }}</span>
                  </li>
                  <li style="">
                    <i class="fal fa-trophy-alt"></i>

                    <span style="">منطقه اجرایی پروژه </span
                    ><span class="number">{{ model.info.area }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h5>اطلاعات جغرافیایی طرح</h5>
                <div class="lineinfo"></div>
              </div>
              <div>
                <ul class="listInfoProject">
                  <li>
                    <i class="fal fa-trophy-alt"></i>
                    <span>آدرس دقیق محل اجرا </span
                    ><span class="number">{{ model.info.address }}</span>
                  </li>
                  <li style="">
                    <i class="fal fa-trophy-alt"></i>

                    <span style=""> کدپستی</span
                    ><span class="number">{{ model.info.postalcode }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h5>اطلاعات مالی پروژه</h5>
                <div class="lineinfo"></div>
              </div>
              <div>
                <ul class="listInfoProject">
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span> هزینه کامل اجرا: </span
                    ><span class="number"
                      >{{ model.info.total_expence }} تومان</span
                    >
                  </li>
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span style="">وام مورد نیاز: </span
                    ><span class="number">{{ model.info.loan }} تومان</span>
                  </li>
                  <li style="">
                    <i class="fal fa-trophy-alt"></i>

                    <span style=""> تعداد اقساط برگشتی: </span
                    ><span class="number"
                      >{{ model.info.installment_no }} قسط</span
                    >
                  </li>
                  <!--                   
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span> مدت تنفس بازپرداخت اقساط: </span>
                    <span class="number">6 ماه</span>
                  </li>
                  <li>
                    <i class="fal fa-trophy-alt"></i>

                    <span>داکیومنت هزینه های پروژه:</span>
                    <span class="number">دانلود</span>
                  </li> -->
                </ul>
              </div>
            </div>
            <div class="col-8">
              <div>
                <h5>اطلاعات تکمیلی</h5>
                <div class="lineinfopro"></div>
              </div>
              <div class="infosingleproject">
                <p>
                  <i class="fal fa-trophy-alt"></i>
                  <span>مزیت رقابتی پروژه:</span>
                  {{ model.info.executive_features }}
                </p>
              </div>
              <div class="infosingleproject">
                <p>
                  <i class="far fa-users"></i>
                  <span>مشتریان پروژه چه کسانی هستند؟</span>
                  {{ model.info.customer }}
                </p>
              </div>
              <div class="infosingleproject">
                <p>
                  <i class="fal fa-trophy-alt"></i>
                  <span> نحوه تبلیغات و ضمانت محصولات پروژه: </span>
                  {{ model.info.advertise }}
                </p>
              </div>
              <div class="infosingleproject">
                <p>
                  <i class="fal fa-trophy-alt"></i>
                  <span> تعداد ایجاد کار و غیر مستقیم پروژه: </span>
                  {{ model.info.direct_job }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="activeMenu == 3">
      <div class="container">
        <div class="row"  >
          <div class="col-6">
            <div class="row" v-if="model.advisor != null">
              <div class="col-5">
                <div class="hexagon hexagon-with-border">
                  <div class="hexagon-shape">
                    <div class="hexagon-shape-inner">
                      <div class="hexagon-shape-inner-2"></div>
                    </div>
                  </div>
                  <div class="hexagon-shape content-panel">
                    <div class="hexagon-shape-inner">
                      <div class="hexagon-shape-inner-2">
                        <img
                          :src="model.advisor.avatar"
                          :alt="model.advisor.profile.full_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-7">
                <div class="text-right supportPerson">
                  <p v-if="model.advisor.profile.gender">جناب آقای</p>
                  <p v-else>سرکار خانم</p>
                  <h3>{{ model.advisor.profile.full_name }}</h3>
                  <!-- <div class="monysupport">
                    <p>مبلغ حمایت <span>12345679 تومان</span></p>
                    <p>نحوه حمیت <span>اعطای امتیاز بانک رسالت</span></p>
                  </div> -->
                </div>
              </div>
            </div>
            <p v-else>اطلاعاتی ثبت نشده است</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="activeMenu == 4">
      <div class="container py-5">
        <div class="step" v-for="item in model.logs" :key="item.id">
          <div>
            <div class="circle"><i class="fa fa-check"></i></div>
          </div>
          <div>
            <div class="title">{{ item.action }}</div>
            <p class="captionDate">{{ item.created_at }}</p>
            <p class="captionProject">
              {{ item.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="activeMenu == 5">
      <div class="lineRep"></div>
      <div class="container">
        <h2 class="py-5 w-100 text-center">گزارش های {{ model.info.title }}</h2>
        <div class="row py-3">
          <div class="col-md-4" v-for="item in report" :key="item.id">
            <div class="card border-0">
              <div style="max-height: 235px" class="overflow-hidden">
                <img
                  class="card-img-top w-100"
                  :src="item.cover_image_url"
                  :alt="item.title"
                />
              </div>

              <div class="card-body">
                <h5 class="card-title">{{ item.title }}</h5>
                <p class="card-text">
                  <small class="text-muted">{{ item.created_at }}</small>
                </p>
                <p class="card-text">
                  {{ item.desc }}
                </p>
                <!-- <div class="text-right moreReorts">
                  <router-link to="/">
                    مطالعه بیشتر <i class="fal fa-long-arrow-left"></i>
                  </router-link>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="activeMenu == 6">
      <div class="container">
        <div class="commentusers my-4" v-for="item in commnets" :key="item.id">
          <p class="nameuser">{{ item.user }}</p>
          <span>{{ item.created_at }}</span>
          <p class="textcomment">
            {{ item.body }}
          </p>
        </div>
        <div class="sendcomment py-4">
          <form>
            <div class="alert alert-warning" role="alert" v-if="message">
              {{ message }}
            </div>
            <div class="form-group py-3">
              <label for="exampleFormControlTextarea1">ارسال دیدگاه</label>
              <textarea
                placeholder="دیدگاه خودرا وارد نمایید..."
                class="form-control"
                v-model="body"
                rows="3"
              ></textarea>
            </div>
            <div
              class="form-check form-check-inline"
              style="margin-right: 23px"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="gavanin"
              />
              <label class="form-check-label py-3" for="inlineCheckbox1">
                <router-link to="/gavanin" style="color: #f44336">
                  قوانین و مقررات</router-link
                >
                ارسال دیدگاه در سایت را مطالعه کرده و آن را می پذیرم.
              </label>
            </div>
            <button
              class="btn btn-succses"
              type="button"
              v-on:click="sendComment()"
            >
              ثبت دیدگاه
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      supported: {
        price: 0,
        accept_anonymous_payment: true,
        anonymouse: false,
        donation: false,
      },
      selectImageUrl: "",
      selectVideoUrl: "",
      resalat: {
        price: 0,
        date: "",
        receipt: 0,
        source_account: "0",
      },
      isList: true,
      model: {
        id: 148,
        info: {
          title: "ایجاد مغازه سوپر مارکتی",
          desc: "اینجانب مغازه ای اجاره کرده ام و تازه ازدواج کرده ام و  برای خرید وسایل مغازه ام(سوپر مارکت) نیاز به وام 20میلیون تومانی دارم",
          loan: 20000000,
          expierDate: "1400-12-20",
          subject: "",
          job_type: "",
          start_time: "1398-9-25",
          end_time: "1399-11-30",
          state: "فارس",
          city: "کوار",
          address: "کوار.بلوارامام.روبروی شهرداری",
          location: "",
          total_expense: 48000000,
          installment_no: 12,
          expense_file: null,
          executive_features: null,
          direct_job: null,
          indirect_job: null,
          customer: null,
          advertise: null,
          garanty: null,
        },
        owner: {
          id: 164,
          name: "Sajjad",
          email: null,
          profile: {
            first_name: "سجاد",
            last_name: "کهن",
            full_name: "سجاد کهن",
            father_name: "علی اکبرکهن",
            card_id: "",
            national_id: "2281854681",
            education: "دیپلم",
            field_of_study: "علوم تجربی",
            gender: 1,
            householders: "on",
            bank_account_no: "0",
            bank_sheba: "0",
            age: 26,
            presenter: {
              name: "مصطفی زارعی",
              mobile: "09176118972",
            },
          },
          avatar: "",
        },
        advisor: {
          id: 23,
          name: "jm_hodhod",
          email: "jm.hodhod@gmail.com",
          profile: {
            first_name: "جلال",
            last_name: "محرابی",
            full_name: "جلال محرابی",
            father_name: "احمد",
            card_id: "",
            national_id: "1271020033",
            education: "کارشناسی",
            field_of_study: "حوزوی",
            gender: 1,
            householders: "on",
            bank_account_no: "0",
            bank_sheba: "0",
            age: 2,
            presenter: {
              name: "ادمین",
              mobile: "09105291517",
            },
          },
          avatar: "",
        },
        status: {
          id: "pay_wage",
          name: "پرداخت کارمزد",
        },
        comments: [],
        logs: [
          {
            id: 3937,
            action: "ایجاد پروژه",
            user: "مالک",
            message: "پروژه «ایجاد مغازه» ایجاد شد",
            created_at: "1399/6/16",
          },
          {
            id: 3938,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای   ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3939,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای استان  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3940,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای استان، شهر  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3941,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای استان، شهر، آدرس  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3942,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای استان، شهر، آدرس، مختصات جغرافیایی  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3943,
            action: "ویرایش پروژه",
            user: "مالک",
            message:
              "فلیدهای استان، شهر، آدرس، مختصات جغرافیایی، cases.area  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3944,
            action: "ویرایش پروژه",
            user: "مالک",
            message:
              "فلیدهای استان، شهر، آدرس، مختصات جغرافیایی، cases.area، cases.postal_code  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3945,
            action: "تعیین مشاور",
            user: "مالک",
            message: "جلال محرابی به عنوان مشاور پروژه تعیین شد.",
            created_at: "1399/6/16",
          },
          {
            id: 3946,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای مبلغ مورد نیاز  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3947,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای مبلغ مورد نیاز، تاریخ مهلت کمک  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3948,
            action: "ویرایش پروژه",
            user: "مالک",
            message: "فلیدهای مبلغ مورد نیاز، تاریخ مهلت کمک  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3949,
            action: "ویرایش پروژه",
            user: "مالک",
            message:
              "فلیدهای مبلغ مورد نیاز، تاریخ مهلت کمک، هزینه کل طرح  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3950,
            action: "ویرایش پروژه",
            user: "مالک",
            message:
              "فلیدهای مبلغ مورد نیاز، تاریخ مهلت کمک، هزینه کل طرح، تعداد اقساط  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3951,
            action: "ویرایش پروژه",
            user: "مالک",
            message:
              "فلیدهای مبلغ مورد نیاز، تاریخ مهلت کمک، هزینه کل طرح، تعداد اقساط، cases.installment_type  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3952,
            action: "ویرایش پروژه",
            user: "مالک",
            message:
              "فلیدهای مبلغ مورد نیاز، تاریخ مهلت کمک، هزینه کل طرح، تعداد اقساط، cases.installment_type، cases.installment_respite  ویرایش شد",
            created_at: "1399/6/16",
          },
          {
            id: 3953,
            action: "logs.change_status:accept_queue",
            user: "مالک",
            message: "پروژه منتظر تایید مشاور است",
            created_at: "1399/6/16",
          },
          {
            id: 3954,
            action: "در انتظار تایید",
            user: "مالک",
            message: "",
            created_at: "1399/6/16",
          },
          {
            id: 3993,
            action: "logs.change_status:not_supply",
            user: "سیستم",
            message:
              "پروژه در زمان مقرر موفق به تامین مالی نشد و به صورت خود کار به وضعیت «عدم تامین» تغییر یافت.",
            created_at: "1399/6/29",
          },
          {
            id: 3994,
            action: "ویرایش پروژه",
            user: "مشاور",
            message: "فلیدهای تاریخ مهلت کمک  ویرایش شد",
            created_at: "1399/6/29",
          },
          {
            id: 3995,
            action: "ویرایش پروژه",
            user: "مشاور",
            message: "فلیدهای تاریخ مهلت کمک  ویرایش شد",
            created_at: "1399/6/29",
          },
          {
            id: 3996,
            action: "ویرایش پروژه",
            user: "مشاور",
            message: "فلیدهای   ویرایش شد",
            created_at: "1399/6/29",
          },
          {
            id: 3997,
            action: "ویرایش پروژه",
            user: "مشاور",
            message: "فلیدهای تاریخ اتمام پروژه  ویرایش شد",
            created_at: "1399/6/29",
          },
          {
            id: 3998,
            action: "logs.change_status:supply_queue",
            user: "مشاور",
            message: "پروژه تایید شد و در صف تامین مالی قرار گرفت",
            created_at: "1399/6/29",
          },
          {
            id: 3999,
            action: "در انتظار تامین",
            user: "مشاور",
            message: "",
            created_at: "1399/6/29",
          },
          {
            id: 5158,
            action: "logs.change_status:not_supply",
            user: "سیستم",
            message: "پروژه نتوانست حامی مالی پیدا کند.",
            created_at: "1399/9/23",
          },
          {
            id: 5159,
            action: "logs.change_status:not_supply",
            user: "سیستم",
            message:
              "پروژه در زمان مقرر موفق به تامین مالی نشد و به صورت خود کار به وضعیت «عدم تامین» تغییر یافت.",
            created_at: "1399/9/23",
          },
          {
            id: 5370,
            action: "ویرایش پروژه",
            user: "مشاور",
            message: "فلیدهای تاریخ مهلت کمک  ویرایش شد",
            created_at: "1399/11/16",
          },
          {
            id: 5371,
            action: "ویرایش پروژه",
            user: "مشاور",
            message: "فلیدهای تاریخ مهلت کمک  ویرایش شد",
            created_at: "1399/11/16",
          },
          {
            id: 5372,
            action: "logs.change_status:supply_queue",
            user: "مشاور",
            message: "پروژه تایید شد و در صف تامین مالی قرار گرفت | ",
            created_at: "1399/11/16",
          },
          {
            id: 5426,
            action: "logs.change_status:pay_wage",
            user: "مدیر",
            message: "پرداخت کارمزد | پروژه تامین مالی شد.",
            created_at: "1399/12/8",
          },
          {
            id: 5542,
            action: "ویرایش پروژه",
            user: "مدیر",
            message: "فیلدهای عنوان پروژه  ویرایش شد",
            created_at: "1400/1/17",
          },
        ],
        media: {},
      },
      hami: [],
      commnets: [],
      report: [],
      check1: true,
      check2: false,
      activeMenu: 1,
      gavanin: false,
      error: null,
      message: null,
      body: null,
      messagePay: null,
    };
  },
  created() {
    this.$http.get("case/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          console.log(response.body.data.media.video);
          this.model = response.body.data;
          console.log(this.model.info);
          this.selectImageUrl = response.body.data.media.album[0].url;
          this.selectVideoUrl = response.body.data.media.video;
        }
      },
      (data) => {
        if (data.status == 200) {
          console.log(data.body.data);
          this.model = data.body.data;
        }
      }
    );
    this.$http.get(`case/${this.$route.params.id}/report`).then(
      (response) => {
        if (response.status == 200) {
          this.report = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.report = data.body.data;
        }
      }
    );
    this.$http.get("comment/case/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          this.commnets = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.commnets = data.body.data;
        }
      }
    );

    this.$http.get("case/" + this.$route.params.id + "/benefactor").then(
      (response) => {
        if (response.status == 200) {
          this.hami = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.hami = data.body.data;
        }
      }
    );
  },
  methods: {
    SendSupported: function () {
      this.messagePay = "";
      if (!this.$cookie.get("ApiToken")) {
        this.messagePay =
          "شما در حساب کاربری خود نیستید لطفا به حساب کاربری خود ورود کنید";
        return;
      }
      this.$http.post("pay/case/" + this.$route.params.id, this.supported).then(
        (response) => {
          if (response.status == 200) {
            console.log(response.body);
            this.messagePay = response.body.message;
          }
        },
        (data) => {
          console.log(data.body);
          if (data.status == 400) {
            this.messagePay = data.body.errors.overpayment;
          } else if (data.status == 402) {
            this.messagePay = data.body.message;
            window.location.href =
              "https://www.karvam.ir" + data.body.redirect_to_url;
          }
        }
      );
    },
    SendResalat: function () {
      this.messagePay = "";
      this.$http
        .post("pay/case/" + this.$route.params.id + "/resalat", this.resalat)
        .then(
          (response) => {
            if (response.status == 200) {
              console.log(response.body);
              // window.location.href = "https://www.karvam.ir"+response.body;
              this.messagePay = response.body.message;
              // this.model = response.body.data;
            }
          },
          (data) => {
            if (data.status == 200) {
              this.model = data.body.data;
            }
          }
        );
    },
    sendComment: function () {
      this.error = null;
      this.message = "درحال ثبت لطفا منتظر بمانید!";
      if (!this.gavanin) {
        this.error = "لطفا قوانین را مطالعه و تایید کنید";
        return;
      }
      this.$http
        .post("comment/case/" + this.$route.params.id, { body: this.body })
        .then(
          (response) => {
            if (response.status == 200 || response.status == 201) {
              this.message = "نظر شما با موفقیت ثبت شد";
              this.gavanin = false;
              this.body = null;
            } else {
              this.message = "متاسفانه نظر شما ثبت نشد";
            }
          },
          (data) => {
            if (data.status == 200 || data.status == 201) {
              this.message = "نظر شما با موفقیت ثبت شد";
              this.gavanin = false;
              this.body = null;
            } else {
              this.message = "متاسفانه نظر شما ثبت نشد";
            }
          }
        );
    },
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    ChangeMenu: function (item) {
      this.activeMenu = item;
    },
    chengeCheck1: function () {
      if (this.check1 === false) {
        this.check2 = true;
      } else {
        this.check2 = false;
      }
    },
    chengeCheck2: function () {
      if (this.check2 === false) {
        this.check1 = true;
      } else {
        this.check1 = false;
      }
    },
  },
  metaInfo() {
    return {
      title: this.model.info.title,
      meta: [
        {
          name: "description",
          content:
            "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
        },
        {
          property: "og:title",
          content: this.model.info.title,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 75%;
  }
}

.hexagon {
  position: relative;
  display: inline-block;
  width: 196px;
  height: 300px;
}
.hexagon .hexagon-shape {
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  display: inline-block;
  margin: 48px 0;
  width: 240px;
  height: 207px;
  transform: rotate(-30deg) skewX(30deg) scale(1);
  border-radius: 18px;
}
.hexagon .hexagon-shape *,
.hexagon .hexagon-shape *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}
.hexagon .hexagon-shape .hexagon-shape-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  opacity: 1;
}
.hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
.hexagon .hexagon-shape .hexagon-shape-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  background: #4d5dd4;
  content: "";
}

.hexagon .hexagon-content .content-sub {
  color: #9f9fa0;
}
.hexagon.hexagon-with-border .hexagon-shape.content-panel {
  transform: rotate(-30deg) skewX(30deg) scale(0.97);
}

.hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
.hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
  background: #eef9fe;
}
.hexagon.info .contentTitle {
  color: #54c6f3;
}
.hexagon-shape-inner-2 {
  img {
    transform: rotate(-61deg) skewX(30deg) scale(1.2);
  }
}

.supportPerson {
  padding-top: 95px;
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #afafaf;
    margin-bottom: 10px;
  }
  h3 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #303030;
  }
  .monysupport {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #303030;
      margin-bottom: 10px;
    }
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #3bcf93;
    }
  }
}

.informationProject {
  h5 {
    padding-top: 30px;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #3bcf93;
    padding-left: 5px;
    background-color: #fff;
    display: inline-block;
  }
  .lineinfo {
    width: 94%;
    height: 2px;
    background-color: #3bcf93;
    position: relative;
    bottom: 17px;
    z-index: -1;
    right: 0px;
  }
  .lineinfopro {
    width: 94%;
    height: 2px;
    background-color: #3bcf93;
    position: relative;
    bottom: 17px;
    z-index: -1;
    right: 24px;
  }
  .listInfoProject {
    padding: 0px;

    li {
      position: relative;
      overflow: hidden;
      width: 330px;
      list-style: none;
      padding: 4px 0px;

      &:after {
        font-size: 85%;
        content: "ـــــــــــــــــــــــــــــــــــــــــــــــــــــ";
        text-indent: -1px;
        display: block;
        position: absolute;
        right: 0px;
        bottom: 7px;
        z-index: -1;
        color: #e5e5e5;
      }
      i {
        padding-left: 5px;
        display: inline-block;
        background: #fff;
      }
      span {
        display: inline-block;
        background-color: #fff;
        padding-left: 5px;
        font-family: IRANSansWeb;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #303030;
      }
      .number {
        float: left;
        font-weight: bold;
        padding-right: 5px;
        font-family: IRANSansWeb;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #303030;
      }
    }
  }
}

.topprojectmanag {
  img {
    border-radius: 50%;
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    padding-right: 10px;
  }
  p {
    margin: 0px;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    padding-right: 10px;
  }
}
.infosingleproject {
  i {
    padding-left: 5px;
  }
  p {
    text-align: justify;
    padding-left: 26px;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #303030;
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #4d5dd4;
    }
  }
}
.img-project {
  .btnimg {
    background-color: #4d5dd4;
    color: #fff;
    padding: 24px 30px;
    position: absolute;
    bottom: 0px;
    right: 0;
  }
  .btnvideo {
    background-color: $themeColorGreen;
    color: #fff;
    padding: 24px 30px;
    position: absolute;
    bottom: 0px;
    right: 78px;
  }
}

.date-date {
  .date-calender-title {
    width: 26%;
  }
  .date-calender {
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.cardSingleProject {
  background-color: $themeColorGreen;
  color: #000;
  h2 {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 34px;
    color: #ffffff;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #fff;
  }

  .participationGift {
    background-color: rgba($color: #388A69, $alpha: 0.3);
    width: 100%;
    padding: 8px 40px;
    position: absolute;
    left: 0;
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #fff;
      padding-right: 10px;
    }
    .checking {
      display: table-cell;
      position: relative;
      left: 0px;
      bottom: 0px;

      cursor: pointer;
      font-size: 22px;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          background-color: #fff;
          &:after {
            display: block;
          }
        }
      }
      .checkmark {
        &:after {
          left: 9px;
          top: 3px;
          width: 6px;
          height: 14px;
          border: solid #3BCF93;
          border-width: 0px 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 5px;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  .giftMoney {
    margin-top: 8rem;
    .gift {
      button {
        background:rgba($color: #388a69, $alpha: 0.8);
        border: 1px solid rgba($color: #388a69, $alpha: 0.8);
        border-radius: 0px 5px 5px 0px;
        color: #fff;
        &:focus {
          box-shadow: none;
        }
      }
      input {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid #388a69;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 5px;
        padding: 0px 7px 0px 45px;
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #388a69;
        &:focus {
          box-shadow: none;
        }
      }
      .toman {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #388a69;
        position: absolute;
        z-index: 99;
        left: 10px;
        top: 10px;
      }
      .checking-gift-1 {
        .checking-gift {
          display: table-cell;
          position: relative;
          right: 24px;
          top: 20px;
          cursor: pointer;
          font-size: 22px;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark-gift {
              background-color: #fff;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark-gift {
          &:after {
            left: 6px;
            top: 1px;
            width: 6px;
            height: 14px;
            border: solid #3BCF93;
            border-width: 0px 2px 2px 0;
            transform: rotate(45deg);
          }
        }
        .checkmark-gift {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 5px;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
        .giftperson-1 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          text-align: right;
          color: #ffffff;
          position: relative;
          top: 18px;
          left: 84px;
        }
      }
      .checking-gift-2 {
        .checking-gift {
          display: table-cell;
          position: relative;
          right: 24px;
          top: 40px;
          cursor: pointer;
          font-size: 22px;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark-gift {
              background-color: #fff;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark-gift {
          &:after {
            left: 6px;
            top: 1px;
            width: 6px;
            height: 14px;
            border: solid #3BCF93;
            border-width: 0px 2px 2px 0;
            transform: rotate(45deg);
          }
        }
        .checkmark-gift {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 5px;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
        .giftperson-2 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          color: #ffffff;
          position: relative;
          top: 39px;
          left: 64px;
        }
        .paymentRegistration-1 {
          margin-top: 99px;

          .btn {
            font-family: IRANSansWeb;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            text-align: right;
            letter-spacing: 0.02em;
            background: #388a69;
            border-radius: 5px;
            color: #fff;
            padding: 11px 145px;
          }
        }
      }
    }
    .money {
      button {
        background: #388a69;
        border: 1px solid #388a69;
        border-radius: 0px 5px 5px 0px;
        color: #fff;
        &:focus {
          box-shadow: none;
        }
      }
      input {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid #388a69;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 5px;
        padding: 0px 7px 0px 10px;
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #388a69;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .paymentRegistration-2 {
      margin-top: 16px;
      .btn {
        font-family: IRANSansWeb;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: right;
        letter-spacing: 0.02em;
        background: #388a69;
        border-radius: 5px;
        color: #fff;
        padding: 11px 145px;
      }
    }
  }
  .paragraphProject {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 26px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
  .btnsuportproject {
    background-color: #388a69;
    border-radius: 5px;
    color: #fff;
    padding: 0 90px;
    margin-top: 50px;
  }
}
.pricesingle {
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #3bcf93;
  }
}

.listSingle {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 5px;
  width: 255px;
  .listSingle-Title {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #afafaf;
  }
  .listSingle-Topic {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #303030;
  }
}

.topnav {
  overflow: hidden;

  button {
    float: right;
    outline: none;
    display: block;
    color: #afafaf;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border: none;
    border-bottom: 3px solid #f1f1f1;
    background: transparent;
    &:hover {
      border-bottom: 3px solid #4d5dd4;
    }
  }
  .active {
    border-bottom: 3px solid #4d5dd4;
    color: #4d5dd4;
  }
}
.line {
  width: 100%;
  height: 3px;
  background-color: #f1f1f1;
  position: relative;
  bottom: 3px;
  z-index: -99999;
}
.step {
  position: relative;
  min-height: 1em;
  color: gray;
}
.step + .step {
  margin-top: 1.5em;
}
.step > div:first-child {
  position: static;
  height: 0;
}
.step > div:not(:first-child) {
  margin-right: 1.5em;
  padding-right: 1em;
}
.step.step-active {
  color: #3bcf93;
}
.step.step-active .circle {
  background-color: #3bcf93;
}

.circle {
  background: #3bcf93;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 12px #fff;
  i {
    padding-top: 5px;
  }
}

.step:after {
  content: " ";
  position: absolute;
  display: block;
  top: 20px;
  right: 12px;
  height: 100%;
  width: 2px;
  background-color: #3bcf93;
  z-index: -1;
}
.step:last-child .circle:after {
  display: none;
}

.title {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #3bcf93;
}
.captionDate {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #afafaf;
  margin: 0px;
  padding: 20px 0px 7px 0px;
}

.captionProject {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #303030;
  margin: 0px;
}

.waiting {
  .circle {
    background: #eceeff;
    border: 1px solid #4d5dd4;
    color: #4d5dd4;
  }
  .title {
    color: #4d5dd4;
  }
  &::after {
    background-color: #4d5dd4;
  }
}

.waitingSupply {
  .circle {
    background: #afafaf;
    color: #fff;
  }
  .title {
    color: #afafaf;
  }
  p {
    color: #afafaf;
  }
  &::after {
    background-color: #afafaf;
  }
}
.reject {
  .circle {
    background: #f44336;
    color: #fff;
  }
  .title {
    color: #f44336;
  }
  p {
    color: #f44336;
  }
  &::after {
    background-color: #f44336;
  }
}
.titrReorts {
  h1 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }

  .lastUpdate {
    p {
      padding-left: 10px;
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.02em;
    }
  }
}
.description {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }
}

.secDescription {
  background-image: url("../../assets/img/reports/Vector-1.png");
  background-repeat: no-repeat;
  background-position: 99% 9px;
  border-right: 3px solid #3bcf93;

  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 188.22%;
    letter-spacing: 0.02em;
    color: #303030;
    padding-right: 60px;
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 60px;
  }
}

.cardDescription {
  padding: 60px 0px 60px 0px;
}
.itemsDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;

    padding-bottom: 15px;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 13px;
    i {
      font-size: 10px;
    }
  }
}

.lineRep {
  width: 100%;
  height: 2px;
  background: #f1f1f1;
}

.lastReports {
  h2 {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 122.32%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3bcf93;
  }
  .card-title {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
    color: #212121;
  }
  .card-text {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140.1%;
    text-align: right;
    color: #616161;
  }
  .moreReorts {
    a {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: right;
      letter-spacing: 0.02em;

      color: #5552d3;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.commentusers {
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  .nameuser {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    margin-bottom: 5px;
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #afafaf;
  }

  .textcomment {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    padding-top: 20px;
  }
}

.morecomment {
  a {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #3bcf93;
  }
}

form {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  text-align: right;
  input,
  textarea {
    &:focus {
      background: rgba(59, 207, 147, 0.1);
      border: 1px solid #3bcf93;

      letter-spacing: 0.02em;
      color: #3bcf93;
    }
  }
  input ~ .checkmark {
    background-color: #ccc;
  }
  button {
    color: #fff;
    background-color: #3bcf93;
  }
}
.proDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }
}

.proItemDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;

    padding-bottom: 15px;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 13px;
    i {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .informationProject .listInfoProject li {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .cardSingleProject .giftMoney .gift .checking-gift-1 .giftperson-1,
  .cardSingleProject .giftMoney .gift .checking-gift-2 .giftperson-2 {
    left: auto;
  }
}
</style>