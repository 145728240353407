<template>
  <div
    class="menu-fixed"
    :class="show ? 'navbar-light' : 'navbar-tramsparent'"
  >
    <div class="container" ref="menu">
      <nav class="navbar navbar-expand-lg px-0">
        <router-link class="navbar-brand mx-0" to="/">
          <img class="logoimg" src="../../../assets/img/Logo.png" alt="logo" />
          <span>کاروام</span>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          v-on:click="OpenAndClose()"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse navmenu"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mr-auto text-right pl-0">
            <li class="nav-item text-left">
              <router-link to="/" class="nav-link"> صفحه اصلی </router-link>
            </li>
            <li class="nav-item text-left">
              <router-link to="/project" class="nav-link">
                پروژه ها
              </router-link>
            </li>
            <li class="nav-item text-left">
              <router-link to="/consultants" class="nav-link">
                معرفان
              </router-link>
            </li>
            <li class="nav-item text-left">
              <router-link to="/Supports" class="nav-link">
                تامین کنندگان
              </router-link>
            </li>
            <li class="nav-item text-left">
              <router-link to="/blog" class="nav-link">اخبار</router-link>
            </li>
            <li class="nav-item text-left">
              <router-link to="/about" class="nav-link">درباره ما</router-link>
            </li>
          </ul>
          <div class="" v-if="!$store.state.IsLogin">
            <router-link to="/login" class="btn btnvrod text-nowrap">
              ورود
            </router-link>
            <router-link to="/register" class="btn btnsabtnam text-nowrap">
              ثبت نام
            </router-link>
          </div>
          <div v-else class="text-light ml-3 text-nowrap">
            <button v-on:click="createProject()" class="btn btnsabtnam text-nowrap mx-1">
              ایجاد پروژه
            </button>
            <a href="" v-on:click="dashboardOpen" class="btn-dashboard px-2 mx-1 text-nowrap" >
                <div class="dashboard-icon"><i class="fal fa-user iconBtn"></i></div>
                <div class="dashboard-name mx-2">{{fullName}}</div>
                <div class="dashboard-icon ml-auto">
                  <i v-if="menu.open === false" class="far fa-chevron-left arrow"></i>
                  <i v-else class="far fa-chevron-down arrow"></i>
                </div>
              <ul class="has-menu-dashboard" v-show="menu.open">
                <li>
                  <router-link to="/profile/dashboard" class="btn-exit">
                    <div class="text-exit text-primary">ورود به پروفایل</div>
                    <div class="icon-exit ml-auto text-primary">
                      <i class="fas fa-sign-in-alt"></i>
                    </div>
                  </router-link>
                </li>
                <!-- <li>
                  <div class="">
                    <span class="">اعتبار : </span>
                    <span class="ml-auto">1250000 تومان</span>
                  </div>
                </li> -->
                <li>
                  <div class="btn-exit" v-on:click="SignOut()">
                    <div class="text-exit">خروج</div>
                    <router-link to="/" class="icon-exit ml-auto">
                      <i class="fal fa-sign-out-alt"></i>
                    </router-link>
                  </div>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    IsLogin: {
      get: function () {
        return this.$cookie.get("ApiToken") != null;
      },
    },
  },
  data() {
    return {
      menu: {
        open: false,
      },
      // IsLogin :false,
      show: false,
      scrollPosition: null,
      fullName: "",
    };
  },
  props: {
    IsScroll: Boolean,
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    $route() {
      this.fullName =  this.$cookie.get("FullName");
      var nav = document.getElementById("navbarSupportedContent");
      nav.className = "navbar-collapse navmenu collapse";
      
      this.updateScroll();
    },
  },
  methods: {
    OpenAndClose(){
      console.log(this.menu.open);      
    },
    createProject(){
      // /project/create
          this.$http.get("case/create").then(
      (response) => {
        console.log(response.body);
        if (response.status == 200) {
                    this.GoTo(0);
        }
      },
      (data) => {
        console.log(data.body.errors.case.id);
        if (data.status != 200) {
          this.GoTo(data.body.errors.case.id);
        }
      }
    );
    },
    GoTo(id){
      if(id == 0){
      this.$router.push({
        name: "create-project",
      });
      }
      else{
      this.$router.push({
        name: "create-project",
        params: { id: id },
      });
      }
    },
    dashboardOpen: function (event) {
      event.preventDefault();
      this.menu.open = this.menu.open === false;
    },
    SignOut: function () {
      this.$http.post("logout").then(
          (response) => {
            if (response.status == 200) {
              this.$cookie.delete("ApiToken");
              this.$cookie.delete("FullName");
              this.$store.commit('LogOut');
              this.$router.push({ name: "index" });
            }
          },
          (data) => {
            if (data.status == 200) {
              this.$cookie.delete("ApiToken");
              this.$cookie.delete("FullName");
              this.$store.commit('LogOut');
              this.$router.push({ name: "index" });
            }
          }
      );
    },
    IsUserAuthenticated() {
      return this.$cookie.get("ApiToken") == null;
    },
    updateScroll() {
      if (this.$route.name == "index") {
        if (window.scrollY > 150) {
          this.show = true;
        } else {
          this.show = false;
        }
      } else {
        this.show = true;
      }
    },
  },
  created() {
    this.updateScroll();
    if (this.$cookie.get("ApiToken")) {
      this.$store.commit("Login");
      this.fullName =  this.$cookie.get("FullName");
      return;
    }

    this.$http.post("user/check").then(
      (response) => {
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          
          this.$cookie.set("FullName", response.body.data.name, 3);
          this.$IsLogin = true;
          this.fullName = response.body.data.name;
        } else {
          this.$IsLogin = false;
          this.$cookie.delete("ApiToken");
          this.$cookie.delete("FullName");
        }
      },
      (data) => {
        if (data.status == 200 || data.status == 201 || data.status == 204) {
          this.$cookie.set("FullName", data.body.result, 3);
          this.$IsLogin = true;
          this.fullName = data.body.result;
        } else {
          this.$IsLogin = false;
          this.$cookie.delete("ApiToken");
          this.$cookie.delete("FullName");
        }
      }
    );
  },
};
</script>
<style lang="scss">
$themeColorGreen: #3bcf93;
.btn-dashboard {
  position: relative;
  .dashboard-name {
    display: inline-block;
    color: #3BCF93;
    font-size: 16px;
    font-weight: 700;
  }
  .dashboard-icon {
    display: inline-block;
    color: #3BCF93;
  }
  .has-menu-dashboard {
    position: absolute;
    list-style: none;
    padding: 0;
    left: 0;
    width: 100%;
    background: #F1F1F1;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    color: #616161;
  }

  .btn-exit {
    display: block;
    .text-exit {
      display: inline-block;
    }
    .icon-exit {
      float: left;
      color: #F44336;
    }
  }
}
@media (max-width: 991px) {
.navbar-tramsparent {
    backdrop-filter: saturate(100%) blur(10px);
  }
}
.navbar-tramsparent {
  background-color: transparent;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;

  .navbar-brand {
    .logoimg {
      width: 65px;
      height: 73px;
    }
    span {
      font-family: KalamehBold;
      font-weight: 900;
      font-size: 35px;
      letter-spacing: 0.02em;
      padding: 0px 12px;
      color: #ffffff;
    }
  }
  .navmenu {

    ul {
      li {
        padding: 8px;
        font-weight: 700;
        line-height: 1rem;
      }
    }
  }
  .btnsabtnam,
  .btnvrod {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0.02em;
    color: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0px 14px 0px 0px;
    width: 110px;
    height: 50px;
    &:hover {
      color: #fff;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .nav-item {
    width: 100%;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    .router-link-exact-active {
      color: #ffffff !important;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 100% !important;
        height: 3px;
        background-color: $themeColorGreen;
        transform: translateX(-50%);
        -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }
    .nav-link {
      white-space: nowrap;
      position: relative;
      color: #c4c4c4;
      &:hover {
        color: rgb(255, 255, 255) !important;
        font-weight: 700;
      }
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0%;
        height: 3px;
        background-color: $themeColorGreen;
        transform: translateX(-50%);
        -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    filter: brightness(0) invert(1);
}
.navbar-toggler{
  border: #fff 1px solid;
}
.navbar-light {
  .navbar-toggler-icon{
    filter:none !important;
  }
  background-color: #fff !important;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  .navbar-brand {
    .logoimg {
      width: 65px;
      height: 73px;
    }
    span {
      font-family: KalamehBold;
      font-weight: 900;
      font-size: 35px;
      letter-spacing: 0.02em;
      padding: 0px 12px;
      color: #303030;
    }
  }
  .navmenu {
    ul {
      li {
        padding: 8px;
        font-weight: 700;
      }
    }
  }
  .btnsabtnam,
  .btnvrod {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0.02em;
    color: #ffffff;
    background-color: #388a69;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0px 14px 0px 0px;
    width: 110px;
    height: 50px;
    &:hover {
      color: #fff;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .nav-item {
    width: 100%;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    .router-link-exact-active {
      color: #252525 !important;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 100% !important;
        height: 3px;
        background-color: $themeColorGreen;
        transform: translateX(-50%);
        -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }
    .nav-link {
      white-space: nowrap;
      position: relative;
      color: #afafaf;
      &:hover {
        color: #5e5e5e !important;
        font-weight: 700;
      }
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0%;
        height: 3px;
        background-color: $themeColorGreen;
        transform: translateX(-50%);
        -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .btn-dashboard {
    float: left;
  }
}

</style>
