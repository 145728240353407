<template>
  <div class="w-100">
    <div class="title">
      <button v-on:click="GoTo(6)" class="d-flex">
        <p class="cicle">6</p>
        <p class="title-step">تصویر</p>
      </button>
    </div>
    <div class="body-step">
      <div class="body-stepfour">
        <div>
          <div class="py-3">
            <label for="">تصاویر شاخص</label>
            <div class="profileImg">
              <div class="hexagon hexagon-with-border">
                <div class="hexagon-shape">
                  <div class="hexagon-shape-inner">
                    <div class="hexagon-shape-inner-2"></div>
                  </div>
                </div>
                <div class="hexagon-shape content-panel">
                  <div class="hexagon-shape-inner">
                    <div class="hexagon-shape-inner-2">
                      <img
                        v-if="avatar != null && avatar != ''"
                        :src="avatar"
                      />
                      <img v-else src="http://karvam.ir/noimage.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <form v-if="!profileImg.isChange">
                  <div class="wrapper">
                    <input type="file" id="file" v-on:change="onFileChange" />
                    <label for="file" class="btn-2">
                      <i class="fa fa-plus mx-1"></i>
                    </label>
                  </div>
                </form>
                <div class="wrapper-x" v-else>
                  <button
                    class="btn-2"
                    v-on:click="submitImg"
                    title="ارسال تصویر"
                  >
                    <i class="fas fa-file-import mx-1"></i>
                  </button>
                  <button
                    class="btn-1 my-2"
                    v-on:click="removeImage"
                    title="حذف عکس"
                  >
                    <i class="far fa-trash-alt mx-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="py-3">
            <label for="">تصاویر پروژه</label>
            <vue-dropzone
              :options="dropzoneOptions"
              id="dropzone"
              ref="album"
              :useCustomSlot="false"
              @vdropzone-removed-file="vremoved"
              @vdropzone-success="vsuccess"
              name="case_cover"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  فایل پیوست خود را بکشید و اینجا رها کنید یا

                  <span> انتخاب کنید </span>
                </h3>
                <p class="dropzone-custom-title">
                  اندازه فایل کمتر از 2 مگابایت باشد / فرمت های jpg , png , word
                  , zip , mp4 و...
                </p>
              </div>
            </vue-dropzone>
          </div>
          <div class="py-3">
            <label for="">ویدیو های پروژه</label>
            <vue-dropzone
              :options="videoOptions"
              id="dropzone1"
              ref="video"
              :useCustomSlot="false"
              @vdropzone-removed-file="vremoved"
              @vdropzone-success="vsuccess"
              name="case_cover"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  فایل پیوست خود را بکشید و اینجا رها کنید یا

                  <span> انتخاب کنید </span>
                </h3>
                <p class="dropzone-custom-title">
                  اندازه فایل کمتر از 2 مگابایت باشد / فرمت های jpg , png , word
                  , zip , mp4 و...
                </p>
              </div>
            </vue-dropzone>
          </div>
          <div class="text-right stepnext pt-4">
            <button
              class="btn next btn-delete mx-2 px-4 w-auto"
              v-on:click="Delete()"
            >
              حذف پروژه
            </button>
            <button v-on:click="submit()" class="btn next w-auto px-4">
              ثبت و ذخیره
            </button>
            <button class="btn back mx-2 px-2" v-on:click="GoTo(5)">
              گام قبل
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  props: {
    avatar: String,
    id: String,
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      profileImg: {
        backavatar: "",
        avatar: "http://karvam.ir/noimage.jpg",
        isChange: false,
      },
      dropzoneOptions: {
        url: `http://karvam.ir/api/case/${this.id}/album`,
        thumbnailWidth: 200,
        dictRemoveFile: "حذف تصویر",
        addRemoveLinks: true,
        headers: { Authorization: "Bearer " + this.$cookie.get("ApiToken") },
        dictDefaultMessage: "لطفا تصاویر مورد نظر خود را انتخاب کنید",
      },
      videoOptions: {
        url: `http://karvam.ir/api/case/${this.id}/video`,
        thumbnailWidth: 200,
        dictRemoveFile: "حذف ویدیو",
        addRemoveLinks: true,
        headers: { Authorization: "Bearer " + this.$cookie.get("ApiToken") },
        dictDefaultMessage: "لطفا ویدیو مورد نظر خود را انتخاب کنید",
      },
    };
  },
  created() {
    // console.log(this.$refs.album);
    //   this.$refs.album.options.url = `http://karvam.ir/api/case/${this.id}/album`;
    //   this.$refs.video.options.url = `http://karvam.ir/api/case/${this.id}/video`;
  },
  methods: {
    submit() {
      this.$emit(
        "Message",
        "روژه شما با موفقیت تکمیل شد، لطفا منتظر تأیید آن توسط کارشناس باشید"
      );
    },
    Delete() {
      this.$fire({
        title: "آیا از حذف پروژه مطمئن هستید؟",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "حذف کن",
        cancelButtonText: `لغو حذف`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.value) {
          this.$http.delete(`case/${this.id}`).then(
            (response) => {
              if (response.status == 200) {
                this.$emit("Message", "حذف با موفقیت انجام شد");
              }
            },
            (data) => {
              console.log(data);
            }
          );
        } else {
          this.$emit("ErrorMessage", "حذف انجام نشد!");
        }
      });
      // this.confirm("آیا از حذف پروژه مطمئن هستید؟",null,"error",)
    },
    GoTo(index) {
      this.$emit("GoTo", index);
    },
    vsuccess(file, response) {
      var image = {
        id: response.data.id,
        image: file.dataURL,
      };
      this.images.push(image);
    },
    vremoved(file) {
      const found = this.images.find((item) => item.image == file.dataURL);
      this.$http.post("media/" + found.id).then(
        (response) => {
          if (response.status == 200) {
            console.log(response.body);
          }
        },
        (data) => {
          if (data.status == 200) {
            console.log(data.body);
          }
        }
      );
    },
    removeImage: function () {
      this.profileImg.isChange = false;
      this.profileImg.avatar = this.profileImg.backavatar;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
      this.submitImg();
    },
    submitImg() {
      let fd = new FormData();
      fd.append("file", this.file);
      var vm = this;
      this.$http.post(`case/${this.id}/image`, fd).then(
        (response) => {
          if (response.status == 200) {
            vm.avatar = response.body.cover_url;
          }
        },
        (data) => {
          console.log(data);
        }
      );
    },
  },
};
</script>

<style>
.btn-delete {
  background-color: #f44336 !important;
  color: white;
}
</style>