<template>
  <div  class="rtl">
    <vue-header v-bind:IsScroll="$route.name == 'index'"></vue-header>
    <transition enter-active-class="animated fade">
      <router-view></router-view>
    </transition>
    <vue-footer></vue-footer>
  </div>
</template>
<script>
import vueHeader from './Pages/Shared/Header.vue'
import vueFooter from './Pages/Shared/Footer.vue'

export default {
  name: "App",
  components:{
    'vue-header':vueHeader,
    'vue-footer':vueFooter
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: IRANSansWeb;
  src: url("./assets/font/IranSansWeb/IRANSansWeb_Light.ttf");
}

@font-face {
  font-family: KalamehRegular;
  src: url("./assets/font/kalameh/KalamehWeb/woff/KalamehWeb_Regular.woff");
}

@font-face {
  font-family: KalamehBold;
  src: url("./assets/font/kalameh/KalamehWeb/woff/KalamehWeb_Black.woff");
}

$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#388a69),
    to(#388a69),
    color-stop(0.6, $themeColorGreen)
  );
}
body {
  margin: 0px;
  padding: 0px;
  direction: rtl;
  overflow-x: hidden;
  font-family: IRANSansWeb;
}
.fix-menu{
  margin-top: 90px;
}
.titrSlider {
  width: auto;
  position: absolute;
  top: 140px;
  text-align: right;

  h1 {
    font-family: KalamehBold;
    font-size: 80px;
    font-weight: 900;
    line-height: 89px;
    letter-spacing: 0.02em;

    span {
      color: $themeColorGreen;
    }
  }

  h2 {
    font-family: KalamehRegular;
    font-size: 38px;
    font-style: normal;
    font-weight: normal;
    margin: 20px 0px;
    line-height: 50px;
    letter-spacing: 0.02em;
  }

  .btnIde {
    padding: 12px 24px;
    background-color: $themeColorGreen;
    border-radius: 0px;
    color: #fff;
    margin: 20px 0px;

    &:hover {
      background-color: #388a69;
      color: #fff;
    }
  }
}
</style>

