<template>
  <div>
    <div class="container alaki">
      <div class="row p-5">
          <p class="font-weight-bold m-5 p-5 text-center w-100">صفحه مورد نظر یافت نشد</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  metaInfo() {
    return {
      title: this.data.title,
      meta: [
        {
          name: "description",
          content: this.data.summery,
        },
        {
          property: "og:title",
          content: this.data.title,
        },
        {
          property: "og:site_name",
          content: "Karvam.ir",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
  methods: {

  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
p{
    font-size:18px;
}
</style>