<template>
  <div class="container py-5">
    <div class="row">
      <div class="col-md-7 cardAbout">
        <div class="titleAbout">
          <h2>درباره ما</h2>
        </div>
        <h3>کاروام چه کار می کند؟</h3>
        <p class="text-justify">
          {{item.descriptions}}
        </p>
        <div class="moreAbout pt-4 pr-3">
          <p>
            <router-link to="/about">
              مطالعه بیشتر
              <i class="fas fa-long-arrow-alt-left"></i>
            </router-link>
          </p>
        </div>
      </div>
      <div class="col-md-5">
        <div>
          <img
            src="../../../assets/img/about/Group35.png"
            style="max-width: 100%"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
        return {
          item:{
            descriptions: "",
          }
        }
    },
    created(){
      this.$http.get('about')
        .then(response => 
        {
          if(response.status == 200){
            this.item = response.body;
          }
        }, 
        data => {
          if(data.status == 200){
            this.item = data.body;
          }
        }
      );
    },
};
</script>

<style lang="scss">
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.cardAbout {
  .titleAbout {
    background-image: url("../../../assets/img/about/about-1.png");
    background-repeat: no-repeat;
    width: 200px;
    margin: 50px 0px 0px 0px;
    h2 {
      font-family: KalamehBold;
      color: $themeColorGreen;

      margin: 0px;
    }
  }
  h3 {
    font-family: IRANSansWeb;
    color: $themeColorDark;
    font-weight: 900;
    padding: 15px 0px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
  }

  .moreAbout {
    text-align: left;
    a {
      font-family: IRANSansWeb;
      color: $themeColorGreen;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: right;
      letter-spacing: 0.02em;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>




