<template>
  <div class="alaki">
    <div class="container">
      <div class="formlogin">
        <div class="text-center">
          <img src="../../assets/img/Logo.png" alt="" />
          <p class="titr-login">ورود به کاروام</p>
        </div>
        <form @submit="submit">
          <div
            class="alert alert-danger"
            role="alert"
            v-if="messageError != null"
          >
            {{ messageError }}
          </div>
          <div class="py-2">
            <label for="">شماره همراه یا نام کاربری</label>
            <br />
            <input
              type="text"
              v-on:change="onChangePhone()"
              class="tell isvall"
              id="number"
              v-model="model.mobile"
            />
            <div v-for="(item, index) in errorMessage.mobile" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-2">
            <label for="">رمز عبور</label>
            <br />
            <div class="input-group">
              <input
                v-model="model.password"
                v-bind:type="[showPassword ? 'text' : 'password']"
                class="form-control"
                placeholder=""
              />
              <div class="input-group-append">
                <span
                  class="input-group-text"
                  @click="showPassword = !showPassword"
                >
                  <i
                    class="fal"
                    :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
            <div v-for="(item, index) in errorMessage.password" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>

          <button type="submit" class="submitligin btn my-3">ورود</button>
          <div class="text-center">
            <p class="register-login">
              تاکنون ثبت نام نکرده اید؟!
              <router-link to="/register" class="btn">ثبت نام</router-link>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      messageError: null,
      model: {
        mobile: "",
        password: "",
        persistent: true,
      },
      errorMessage: {
        mobile: [],
        password: [],
      },
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      var error = false;
      this.messageError = null;
      this.errorMessage = {
        mobile: [],
        password: [],
      };
      if (!this.model.mobile) {
        this.errorMessage.mobile.push(
          "پر کردن تلفن همراه یا نام کاربری اجباری است"
        );
        error = true;
      }

      if (!this.model.password) {
        this.errorMessage.password.push("پر کردن پسورد اجباری است");
        error = true;
      }

      if (!error) {
        this.$http.post("login", this.model).then(
          (response) => {
            if (response.status == 200 && response.body) {
              this.$cookie.set("ApiToken", response.body.token, 3);
              this.$cookie.set("FullName", response.body.user.name, 3);
              this.$store.commit("Login");
              this.$router.replace({ name: "profile" });
            }
          },
          (data) => {
            if (data.status == 400 && data.body) {
              this.$IsLogin = false;
              if (data.body.errors.name)
                this.errorMessage.name = data.body.errors.name;
              if (data.body.errors.mobile)
                this.errorMessage.mobile = data.body.errors.mobile;
              if (data.body.errors.email)
                this.errorMessage.email = data.body.errors.email;
              if (data.body.errors.password)
                this.errorMessage.password = data.body.errors.password;
              if (data.body.errors.c_password)
                this.errorMessage.c_password = data.body.errors.c_password;
              window.scrollTo(0, 0);
            } else if (data.status == 200 && data.body) {
              this.$store.commit("Login");
              this.$cookie.set("ApiToken", data.body.token, 3);
              this.$cookie.set("FullName", data.body.user.name, 3);
              this.$router.replace({ name: "profile" });
            } else if (data.status == 401) {
              this.messageError = "نام کاربری یا رمز عبور شما اشتباه است";
            }
          }
        );
      }
    },
    onChangePhone: function () {
      this.errorMessage.mobile = [];

      if (!this.model.mobile) {
        this.errorMessage.mobile.push("پر کردن تلفن همراه اجباری است");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alaki {
  margin: 150px 0;
}

.formlogin {
  width: 400px;
  max-width: 100%;
  height: 540px;

  background: #f6f6f6;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 0px auto 0px auto;
  padding: 30px;
  .titr-login {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #303030;
  }
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #303030;
  }
  .tell {
    width: 340px;
    max-width: 100%;
    height: 50px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid #afafaf;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #303030;
  }

  .notvall {
    border: 1px solid #f44336 !important;
  }
  .notvall-1 {
    border: 1px solid #f44336 !important;
    border-width: 1px 1px 1px 0px !important;
  }
  .notvall-2 {
    border: 1px solid #f44336 !important;
    border-width: 1px 0px 1px 1px !important;
  }
  .message-error {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #f44336;
    padding: 0px 0px 0px 0px;
    // visibility: hidden;
  }

  .isvall {
    background-image: url("../../assets/img/login/Vector.png");
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: 15px auto;
    border: 1px solid #3bcf93 !important;
  }
  .submitligin {
    background: #3bcf93;
    border: 1px solid #3bcf93;
    box-sizing: border-box;
    border-radius: 5px;
    width: 340px;
    max-width: 100%;
    height: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
  }
  .register-login {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #303030;
    .btn {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #4d5dd4;
      padding: 0px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .input-group-text {
    background: #fff;
    border: 1px solid #afafaf;
    border-width: 1px 0px 1px 1px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    i {
      font-size: 12px;
      color: #afafaf;
    }
  }
  .form-control {
    height: 50px;
    border: 1px solid #afafaf;
    border-width: 1px 1px 1px 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #303030;
    &:focus {
      box-shadow: none;
    }
  }
}
</style>