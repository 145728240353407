<template>
  <section class="slidersection py-5">
    <div class="container">
      <div class="titleslider">
        <h2>پروژه‌های برتر</h2>
        <div class="text-center">
          <router-link to="/project" class="btn">
            مشاهده همه
            <i class="fas fa-long-arrow-alt-left"></i>
          </router-link>
        </div>
      </div>
      <div class="pt-4 base">
        <hooper :settings="hooperSettings">
          <slide v-for="item in filteredItems" :key="item.Id">
            <div class="card listCard border-1 rounded-0 rtl">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <span class="Deadline">
                    <span>{{ item.info.end_time }}</span>
                    : تاریخ تامین
                  </span>
                  <img
                    :src="item.images.cover"
                    class="card-img-top rounded-0 w-100 h-100"
                    :alt="item.info.title"
                    v-if="item && item.images && item.images.cover"
                  />
                  <img
                    alt=""
                    class="card-img-top rounded-0 w-100 h-100"
                    src="http://karvam.ir/noimage.jpg"
                    v-else
                  />
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="card-body pb-0 text-left">
                    <div class="mb-2 d-flex">
                      <p class="btn px-4 d-inline-block">
                        {{ item.info.master_subject }}
                      </p>
                      <div class="pr-3 ml-auto snip">
                        <i class="fal fa-map-marker-alt mr-1"></i>
                        {{ item.info.state }} / {{ item.info.city }}
                      </div>
                    </div>
                    <h2 class="text-weight my-3">
                      {{ item.info.title }}
                    </h2>
                    <div class="manager">
                      <div class="hexagon hexagon-with-border">
                        <div class="hexagon-shape">
                          <div class="hexagon-shape-inner">
                            <div class="hexagon-shape-inner-2"></div>
                          </div>
                        </div>
                        <div class="hexagon-shape content-panel">
                          <div class="hexagon-shape-inner">
                            <div class="hexagon-shape-inner-2">
                              <img
                                :src="item.owner.avatar"
                                :alt="item.owner.profile.full_name"
                                v-if="item && item.owner && item.owner.avatar"
                              />
                              <img src="http://karvam.ir/noimage.jpg" v-else />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="consultantName">
                        <h6>مالک پروژه</h6>
                        <h5
                          v-if="
                            item &&
                            item.owner.profile &&
                            item.owner.profile.full_name
                          "
                        >
                          {{ item.owner.profile.full_name }}
                        </h5>
                      </div>
                    </div>
                    <p class="mt-5">
                      {{ item.info.desc }}
                    </p>

                    <div class="">
                      <div class="d-flex justify-content-between price pt-1">
                        <span>
                          تامین شده:
                          {{ formatPrice(item.finance.collected) }} تومان
                        </span>
                        <span>
                          {{
                            $Utility.GetPercent(item.finance.collected,item.info.loan)
                          }}%</span
                        >
                      </div>
                    </div>
                    <div
                      class="progress rounded-0 flex-row-reverse"
                      style="height: 10px"
                    >
                      <div
                        :style="{
                          width:
                            $Utility.GetPercent(item.finance.collected,item.info.loan) + '%',
                        }"
                        class="
                          progress-bar
                          progress-bar-striped
                          progress-bar-animated
                        "
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p class="moneyNeeded mt-1">
                      مبلغ مورد نیاز:
                      <span style="color: #3bcf93">
                        {{
                          formatPrice(item.finance.loan_need - item.finance.collected)
                        }}
                        تومان
                      </span>
                    </p>
                    <div class="mb-0 text-right userBtn">
                      <router-link
                        :to="'/project/single/' + item.id"
                        class="btn d-inline-block"
                        >مشاهده و حمایت
                      </router-link>
                    </div>
                    <div>
                      <div class="hexagon hexagon-with-border">
                        <div class="hexagon-shape">
                          <div class="hexagon-shape-inner">
                            <div class="hexagon-shape-inner-2"></div>
                          </div>
                        </div>
                        <div class="hexagon-shape content-panel">
                          <div class="hexagon-shape-inner">
                            <div class="hexagon-shape-inner-2">
                              <img
                                :src="item.advisor.avatar"
                                :alt="item.advisor.profile.full_name"
                                v-if="
                                  item && item.advisor && item.advisor.avatar
                                "
                              />
                              <img
                                src="http://karvam.ir/noimage.jpg"
                                v-else
                                alt="logo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="consultantName">
                        <h6>معرف (امین)</h6>
                        <h5
                          v-if="
                            item &&
                            item.advisor &&
                            item.advisor.profile &&
                            item.advisor.profile.full_name
                          "
                        >
                          {{ item.advisor.profile.full_name }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>
  </section>
</template>

<script>
// import { VueAgile } from "vue-agile";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  data() {
    return {
      list: [
        {
          id: 104,
          info: {
            title: "کشاورزی",
            desc: "خرید دینام عمودی 60  جهت کشاورزی وزراعت",
            loan: 20000000,
            expierDate: "1400-1-15",
            subject: "",
            job_type: "",
            start_time: "1399-1-1",
            end_time: "1400-6-31",
            state: "کرمان",
            city: "رودبار",
            address: "رودبارجنوب روستای ناصراباد",
            location: "",
            total_expense: 25000000,
            installment_no: 20,
            expense_file: null,
            executive_features: null,
            direct_job: null,
            indirect_job: null,
            customer: null,
            advertise: null,
            garanty: null,
          },
          owner: {
            id: 95,
            name: "6069639987",
            email: null,
            profile: {
              first_name: "اسلام",
              last_name: "بهزادی پور",
              full_name: "اسلام بهزادی پور",
              father_name: "عیسی",
              card_id: "",
              national_id: "6069639987",
              education: "کارشناسی ارشد",
              field_of_study: "زراعت",
              gender: 1,
              householders: "on",
              bank_account_no: "0",
              bank_sheba: "0",
              age: 37,
              presenter: {
                name: "",
                mobile: "",
              },
            },
            avatar: "",
          },
          advisor: {
            id: 4,
            name: "dqoqnoos",
            email: "dqoqnoos@gmail.com",
            profile: {
              first_name: "داود",
              last_name: "صالحی",
              full_name: "داود صالحی",
              father_name: "کریم",
              card_id: "",
              national_id: "6159660209",
              education: "",
              field_of_study: "",
              gender: 1,
              householders: "off",
              bank_account_no: "0",
              bank_sheba: "0",
              age: 44,
              presenter: {
                name: "",
                mobile: "",
              },
            },
            avatar: "",
          },
          status: {
            id: "supply_queue",
            name: "در انتظار تامین",
          },
          images: {
            cover: "",
          },
        },
        {
          id: 174,
          info: {
            title: "کارگاه خیاطی",
            desc: "ایجاد کارگاه خیاطی سرویس خواب برای زنان سرپرست خانواده",
            loan: 20000000,
            expierDate: "1400-10-5",
            subject: "",
            job_type: "",
            start_time: "1399-8-1",
            end_time: "1399-9-1",
            state: "تهران",
            city: "تهران",
            address: "محله بنی هاشم .",
            location: "",
            total_expense: 100000000,
            installment_no: 12,
            expense_file: null,
            executive_features: null,
            direct_job: null,
            indirect_job: null,
            customer: null,
            advertise: null,
            garanty: null,
          },
          owner: {
            id: 189,
            name: "سیده هاجر متولی امامی",
            email: null,
            profile: {
              first_name: "سیده هاجر",
              last_name: "امامی",
              full_name: "سیده هاجر امامی",
              father_name: "سید مهدی",
              card_id: "",
              national_id: "0015678571",
              education: "کارشناسی ارشد",
              field_of_study: "مطالعات زنان",
              gender: 0,
              householders: "on",
              bank_account_no: "1083277301",
              bank_sheba: "280700001000118327730001",
              age: 0,
              presenter: {
                name: "مهندس آشتیانی",
                mobile: "09103120058",
              },
            },
            avatar: "",
          },
          advisor: {
            id: 4,
            name: "dqoqnoos",
            email: "dqoqnoos@gmail.com",
            profile: {
              first_name: "داود",
              last_name: "صالحی",
              full_name: "داود صالحی",
              father_name: "کریم",
              card_id: "",
              national_id: "6159660209",
              education: "",
              field_of_study: "",
              gender: 1,
              householders: "off",
              bank_account_no: "0",
              bank_sheba: "0",
              age: 44,
              presenter: {
                name: "",
                mobile: "",
              },
            },
            avatar: "",
          },
          status: {
            id: "supply_queue",
            name: "در انتظار تامین",
          },
          images: {
            cover: "",
          },
        },
        {
          id: 175,
          info: {
            title: "کارگاه خیاطی",
            desc: "ایجاد کارگاه خیاطی سرویس خواب برای زنان سرپرست خانواده",
            loan: 20000000,
            expierDate: "1400-10-5",
            subject: "",
            job_type: "",
            start_time: "1399-8-1",
            end_time: "1399-9-1",
            state: "تهران",
            city: "تهران",
            address: "محله بنی هاشم .",
            location: "",
            total_expense: 100000000,
            installment_no: 12,
            expense_file: null,
            executive_features: null,
            direct_job: null,
            indirect_job: null,
            customer: null,
            advertise: null,
            garanty: null,
          },
          owner: {
            id: 189,
            name: "سیده هاجر متولی امامی",
            email: null,
            profile: {
              first_name: "سیده هاجر",
              last_name: "امامی",
              full_name: "سیده هاجر امامی",
              father_name: "سید مهدی",
              card_id: "",
              national_id: "0015678571",
              education: "کارشناسی ارشد",
              field_of_study: "مطالعات زنان",
              gender: 0,
              householders: "on",
              bank_account_no: "1083277301",
              bank_sheba: "280700001000118327730001",
              age: 0,
              presenter: {
                name: "مهندس آشتیانی",
                mobile: "09103120058",
              },
            },
            avatar: "",
          },
          advisor: {
            id: 4,
            name: "dqoqnoos",
            email: "dqoqnoos@gmail.com",
            profile: {
              first_name: "داود",
              last_name: "صالحی",
              full_name: "داود صالحی",
              father_name: "کریم",
              card_id: "",
              national_id: "6159660209",
              education: "",
              field_of_study: "",
              gender: 1,
              householders: "off",
              bank_account_no: "0",
              bank_sheba: "0",
              age: 44,
              presenter: {
                name: "",
                mobile: "",
              },
            },
            avatar: "",
          },
          status: {
            id: "supply_queue",
            name: "در انتظار تامین",
          },
          images: {
            cover: "",
          },
        },
      ],
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        rtl: true,
        autoPlay: false,
        playSpeed: 2000,
      },
    };
  },
  created() {
    this.$http.get("topcase").then(
      (response) => {
        if (response.status == 200) {
          this.list = response.body.data;
          console.log(response.body.data[0].info.end_time);
        }
      },
      (data) => {
        if (data.status == 200) {
          this.list = data.body.data;
        }
      }
    );
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    filteredItems() {
      return this.list.filter((item) => {
        return item.images.cover != null;
      });
    },
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
};
</script>

<style lang="scss" scope>
.hooper-navigation.is-rtl .hooper-prev {
  left: auto;
  right: -80px;
}
.hooper-navigation.is-rtl .hooper-prev svg {
  transform: scale(2.3);
}
.hooper-navigation.is-rtl .hooper-next {
  right: auto;
  left: -80px;
}
.hooper-navigation.is-rtl .hooper-next svg {
  transform: scale(2.3);
}
.hooper {
  height: auto !important;
}
.base {
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  direction: ltr;
}

.agile__list {
  height: 100%;
}

.agile {
  width: 100%;
}

.agile__actions {
  position: absolute;
  top: 50%;
  width: 100%;
}

.agile__nav-button--next {
  right: 0;
  margin-right: -5%;
}

.agile__nav-button--prev {
  left: 0;
  margin-left: -5%;
}

.agile__nav-button {
  position: absolute;
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}

.agile__nav-button:hover {
  color: #888;
}

.agile__dot {
  margin: 0 10px;
}

.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.slide {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100vh;
  background-position: center;
  background-size: cover;
  justify-content: center;
}

.slidersection {
  background-color: #f7f7f7;

  .titleslider {
    background-image: url("../../../assets/img/sliderproject/Vector5656.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 5px 0px;
    margin-top: 9px;

    h2 {
      font-family: KalamehBold;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.02em;
      color: #303030;
      text-align: center;

      &::before {
        content: url(../../../assets/img/support/Rectangle.png);
        position: relative;
        left: 10px;
      }

      &::after {
        content: url(../../../assets/img/support/Rectangle.png);
        position: relative;
        right: 10px;
      }
    }

    .btn {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #3bcf93;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .listCard {
    .Deadline {
      direction: ltr;
      position: absolute;
      bottom: 20px;
      left: 39px;
      background-color: rgba(77, 93, 212, 0.6);
      border-radius: 5px;
      color: #fff;
      padding: 8px 15px;
      font-weight: 500;
      font-size: 12px;
    }

    .card-body {
      .btn {
        font-weight: 500;
        font-size: 12px;
        text-align: right;
        color: #fff;
        background: #388a69;
        border-radius: 5px;
      }

      .snip {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #afafaf;
      }

      h2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #2e2e2e;
      }

      .manager {
        .hexagon {
          position: relative;
          display: inline-block;
        }

        .hexagon .hexagon-shape {
          position: absolute;
          right: -89px;
          bottom: -115px;
          overflow: hidden;
          display: inline-block;
          width: 240px;
          height: 207px;
          transform: rotate(-30deg) skewX(30deg) scale(0.3);
          border-radius: 20px;
        }

        .hexagon .hexagon-shape *,
        .hexagon .hexagon-shape *:before {
          display: block;
          overflow: hidden;
          width: inherit;
          height: inherit;
          border-radius: inherit;
        }

        .hexagon .hexagon-shape .hexagon-shape-inner {
          transform: skewX(-30deg) rotate(60deg) skewX(30deg);
          opacity: 1;
        }

        .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
        .hexagon .hexagon-shape .hexagon-shape-inner-2 {
          transform: skewX(-30deg) rotate(60deg) skewX(30deg);
          background: #3bcf93;
          content: "";
        }

        .hexagon .hexagon-content .content-sub {
          color: #9f9fa0;
        }

        .hexagon.hexagon-with-border .hexagon-shape.content-panel {
          transform: rotate(-30deg) skewX(30deg) scale(0.28);
        }

        .hexagon
          .hexagon-shape.content-panel:first-child
          .hexagon-shape-inner:before,
        .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
          background: #eef9fe;
        }

        .hexagon.info .contentTitle {
          color: #54c6f3;
        }

        .hexagon-shape-inner-2 {
          img {
            transform: rotate(-61deg) skewX(30deg) scale(1);
          }
        }

        .consultantName {
          position: absolute;
          top: 116px;
          right: 111px;

          h6 {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: #303030;
          }

          h5 {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.02em;
            color: #303030;
          }
        }
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: #5e5e5e;
      }

      .price {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        color: #afafaf;
      }

      .moneyNeeded {
        font-weight: bold;
        font-size: 14px;
        line-height: 23px;
        color: #303030;
      }

      .hexagon {
        position: relative;
        display: inline-block;
      }

      .hexagon .hexagon-shape {
        position: absolute;
        right: -89px;
        bottom: -62px;
        overflow: hidden;
        display: inline-block;
        width: 240px;
        height: 207px;
        transform: rotate(-30deg) skewX(30deg) scale(0.3);
        border-radius: 20px;
      }

      .hexagon .hexagon-shape *,
      .hexagon .hexagon-shape *:before {
        display: block;
        overflow: hidden;
        width: inherit;
        height: inherit;
        border-radius: inherit;
      }

      .hexagon .hexagon-shape .hexagon-shape-inner {
        transform: skewX(-30deg) rotate(60deg) skewX(30deg);
        opacity: 1;
      }

      .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
      .hexagon .hexagon-shape .hexagon-shape-inner-2 {
        transform: skewX(-30deg) rotate(60deg) skewX(30deg);
        background: #4d5dd4;
        content: "";
      }

      .hexagon .hexagon-content .content-sub {
        color: #9f9fa0;
      }

      .hexagon.hexagon-with-border .hexagon-shape.content-panel {
        transform: rotate(-30deg) skewX(30deg) scale(0.28);
      }

      .hexagon
        .hexagon-shape.content-panel:first-child
        .hexagon-shape-inner:before,
      .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
        background: #eef9fe;
      }

      .hexagon.info .contentTitle {
        color: #54c6f3;
      }

      .hexagon-shape-inner-2 {
        img {
          transform: rotate(-61deg) skewX(30deg) scale(1);
        }
      }

      .consultantName {
        position: absolute;
        bottom: 21px;
        right: 111px;

        h6 {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #303030;
        }

        h5 {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.02em;
          color: #303030;
        }
      }

      .userBtn {
        margin-top: 15px;

        .btn {
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.02em;
          color: #fff;
          background-color: #3bcf93;
          border-radius: 5px;
          padding: 12px 22px;

          &:hover {
            background-color: #388a69;
          }
        }
      }
    }
  }
}

@media (max-width: 991.99px) {
  .slidersection .listCard .card-body .consultantName {
    position: sticky;
  }
}
</style>