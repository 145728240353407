<template>
  <section class="py-5">
    <app-newsmail id="newsmail"></app-newsmail>
    <div class="container">
      <div class="titlesbrand mb-5">
        <h2>همراهان کاروام</h2>
      </div>
      <hooper :settings="hooperSettings">
        <slide v-for="item in list" :key="item.id" class="text-center">
          <img :src="item.img_url" class="w-50" :alt="item.name" />
        </slide>
      </hooper>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import newsmail from "../Components/Newsmail";
export default {
  data() {
    return {
      list: [],
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        rtl: true,
        autoPlay: true,
        playSpeed: 2000,
        breakpoints: {
          480: {
            itemsToShow: 2,
          },
          800: {
            itemsToShow: 4,
          },
          1000: {
            itemsToShow: 5,
          },
        },
      },
      isLeft: true,
      isRight: false,
    };
  },
  components: {
    "app-newsmail": newsmail,
    Hooper,
    Slide,
  },
  created() {
    this.$http.get("companions").then(
      (response) => {
        if (response.status == 200) {
          this.list = response.body.data;
          console.log(this.list);
        }
      },
      (data) => {
        if (data.status == 200) {
          this.list = data.body.data;
        }
      }
    );
  },
  methods: {
    accessBtn() {
      if (this.$refs.scrollelement.scrollLeft < 0) {
        this.isRight = true;
      } else {
        this.isRight = false;
      }
      var element = this.$el.querySelector(".list-brand");
      var maxScrollLeft =
        parseFloat(element.scrollWidth) - parseFloat(element.clientWidth);
      if (this.$refs.scrollelement.scrollLeft > -(maxScrollLeft - 1)) {
        this.isLeft = true;
      } else {
        this.isLeft = false;
      }
    },
    left: function () {
      var container = this.$el.querySelector(".list-brand");
      container.scrollLeft += -100;
    },
    right: function () {
      var container = this.$el.querySelector(".list-brand");
      container.scrollLeft += 100;
    },
  },
  mounted() {
    // this.$refs.scrollelement.focus();
  },
  watch: {
    "$refs.scrollelement.scrollLeft"() {
      console.log("sdsdsd");
    },
  },
};
</script>

<style lang="scss">
.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}
.thumbnails .agile__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.thumbnails .agile__nav-button--prev {
  left: -45px;
}
.thumbnails .agile__nav-button--next {
  right: -45px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 450px;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

* {
  scroll-behavior: smooth;
}
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none;
}
.relative {
  position: relative;
}
button {
  &:focus {
    outline: none;
  }
}
.btn-left {
  position: absolute;
  left: -50px;
  top: 30%;
  border: none;
  background: none;
  font-size: 2em;
  color: #939393;
  outline: 0;
}
.btn-right {
  position: absolute;
  right: -50px;
  top: 30%;
  border: none;
  background: none;
  font-size: 2em;
  color: #939393;
  outline: 0;
}
.brand-item {
  display: inline-block;
  width: 130px;
  margin-right: 100px;
  &:first-child {
    margin-right: 0px;
  }
}
.titlesbrand {
  background-image: url("../../../assets/img/Brand/Vector484.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 25px 0px;

  h2 {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #303030;
    text-align: center;

    &::before {
      content: url(../../../assets/img/support/Rectangle.png);
      position: relative;
      left: 10px;
    }
    &::after {
      content: url(../../../assets/img/support/Rectangle.png);
      position: relative;
      right: 10px;
    }
  }
  .btn {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #3bcf93;
    &:focus {
      box-shadow: none;
    }
  }
}

@media only screen and (max-width: 580px) {
  .list-brand {
    > div {
      width: 105px;
      display: inline-block;
    }
  }
}
</style>