<template>
  <div class="fix-menu">
    <app-header></app-header>
    <div class="container">
      <div class="showAll text-center py-5">
        <div class="relative bg-transparent">
          <button class="btn-right" v-on:click="right()" v-if="isRight">
            <i class="fas fa-chevron-right"></i>
          </button>
          <div
            ref="scrollelement"
            @scroll="accessBtn"
            class="overflow-auto text-nowrap no-scroll scrollelement"
          >
            <button
              v-on:click="selectCategory(item.id)"
              class="headerGrouping"
              v-for="item in category"
              :key="item.key"
            >
              <div class="active" v-if="activeId == item.id"></div>
              <img :src="item.icon_url" :alt="item.name" />
              <h4>{{ item.name }}</h4>
            </button>
          </div>
          <button class="btn-left" v-on:click="left()" v-if="isLeft">
            <i class="fas fa-chevron-left"></i>
          </button>
        </div>

        <div class="collapsmore">
          <div class="pos-f-t">
            <div
              class="collapse"
              :class="show ? 'show' : ''"
              id="navbarToggleExternalContent"
            >
              <div class="bg-white p-3">
                <div
                  class="otherTopics d-inline-block mx-2"
                  v-for="item in subCategory"
                  :key="item.id"
                >
                  <button
                    type="button"
                    class="btn"
                    v-on:click="selectSubCategory(item.id)"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>
            <nav class="navbar navbar-dark bg-white">
              <transition name="fade">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarToggleExternalContent"
                  aria-controls="navbarToggleExternalContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="" v-if="!show"
                    >مشاهده زیر مجموعه ها <i class="fas fa-chevron-down"></i
                  ></span>
                  <span class="" v-else
                    >بستن زیر مجموعه ها <i class="fas fa-chevron-up"></i
                  ></span>
                </button>
              </transition>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row text-left listProject">
        <div class="">
          <button
            class="btnList d-inline"
            v-on:click="isList = true"
            v-bind:class="{ active: isList }"
          >
            <i class="fas fa-th gallery"></i>
          </button>
          <button
            class="btnList d-inline"
            v-on:click="isList = false"
            v-bind:class="{ active: !isList }"
          >
            <i class="fas fa-th-list list"></i>
          </button>
        </div>
        <div class="col-md">
          <div class="row">
            <div class="form-group col-md col-6">
              <select
                class="form-control bg-light text-secondary"
                @change="provinceChange($event)"
                v-model="filter.province"
              >
                <option value="0">استان ها</option>
                <option
                  :value="item.id"
                  v-for="item in province"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md col-6">
              <select
                class="form-control bg-light text-secondary"
                @change="cityChange($event)"
                v-model="filter.city"
              >
                <option value="0">همه شهرها</option>
                <option :value="item.id" v-for="item in city" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md col-6">
              <select
                class="form-control bg-light text-secondary"
                @change="statusChange($event)"
                v-model="filter.status"
              >
                <option value="0">وضعیت پروژه</option>
                <option :value="item.id" v-for="item in status" :key="item.id">
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="form-group col-md col-6">
              <div class="search">
                <input
                  type="text"
                  class="searchTerm"
                  v-model="filter.search"
                  placeholder="جست و جو ..."
                />
                <button
                  type="button"
                  v-on:click="UpdatePage()"
                  class="searchButton"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="alert alert-primary w-100 my-4 text-center"
      role="alert"
      v-if="data.length == 0"
    >
      هیچ موردی یافت نشد
    </div>
    <div class="w-100 p-5 text-center" v-if="isLoading">
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
      <p>درحال بارگذاری</p>
    </div>
    <div class="container text-center mb-3" v-if="isList">
      <div class="row">
        <div class="col-lg-4 col-md-6 pt-4" v-for="item in data" :key="item.id">
          <projectItem :item="item" :link="'/project/single/' + item.id"></projectItem>
        </div>
      </div>
    </div>
    <div class="container text-center mb-3" v-else>
      <div class="pt-4" v-for="item in data" :key="item.id">
        <div class="card listCard border-1 rounded-0">
          <div class="row">
            <div class="col-md-6">
              <span class="Deadline"
                >مهلت تامین {{ item.info.expierDate }}</span
              >
              <img
                :src="item.media.cover"
                class="card-img-top rounded-0 w-100 h-100"
                :alt="item.info.title"
                v-if="item && item.info && item.info.title"
              />
              <img src="http://karvam.ir/noimage.jpg" v-else />
            </div>
            <div class="col-md-6">
              <div class="card-body pb-0 text-left">
                <div class="mb-2 d-flex">
                  <router-link class="btn px-4 d-inline-block">
                    {{ item.info.state }} - {{ item.info.city }}
                  </router-link>
                  <div class="px-1 ml-auto snip">
                    <i class="fal fa-map-marker-alt mr-1"></i>
                    {{ item.info.address }}
                  </div>
                </div>
                <h2 class="text-weight my-3">{{ item.info.title }}</h2>
                <h3
                  class="my-1"
                  v-if="
                    item && item.owner.profile && item.owner.profile.full_name
                  "
                >
                  توسط : {{ item.owner.profile.full_name }}
                </h3>
                <p class="my-3" style="min-height: 50px; overflow: hidden">
                  {{ item.info.desc }}
                </p>

                <div class="">
                  <div class="d-flex justify-content-between price pt-3">
                    <span
                      >تامین شده: {{ formatPrice(item.finance.collected) }} تومان</span
                    >
                    <span
                      >{{
                       $Utility.GetPercent(item.finance.collected,item.finance.loan_need)
                      }}%</span
                    >
                  </div>
                </div>
                <div
                  class="progress rounded-0 flex-row-reverse"
                  style="height: 10px"
                >
                  <div
                    :style="{
                      width:
                        $Utility.GetPercent(item.finance.collected,item.finance.loan_need) + '%',
                    }"
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="moneyNeeded mt-2">
                  مبلغ مورد نیاز:
                  <span style="color: #3bcf93"
                    >{{
                      formatPrice(item.finance.loan_need - item.finance.collected)
                    }}
                    تومان</span
                  >
                </p>
                <div class="mb-0 text-right userBtn">
                  <router-link
                    :to="'/project/single/' + item.id"
                    class="btn d-inline-block"
                  >
                    مشاهده و حمایت
                  </router-link>
                </div>
                <div>
                  <div class="hexagon hexagon-with-border">
                    <div class="hexagon-shape">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2"></div>
                      </div>
                    </div>
                    <div class="hexagon-shape content-panel">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2">
                          <img
                            :src="item.advisor.avatar"
                            :alt="item.advisor.profile.full_name"
                            v-if="item && item.advisor && item.advisor.avatar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="consultantName">
                    <h6>معرف (امین)</h6>
                    <h5
                      v-if="
                        item &&
                        item.advisor &&
                        item.advisor.profile &&
                        item.advisor.profile.full_name
                      "
                    >
                      {{ item.advisor.profile.full_name }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 text-center" v-if="page.last_page > 1">
      <div class="pagination py-5">
        <a
          v-if="page.current_page > 1"
          :href="'/project?' + GetQueryString(page.current_page - 1)"
          >&raquo;</a
        >
        <a
          v-for="index in page.last_page"
          :key="index"
          :href="'/project?' + GetQueryString(index)"
          :class="page.current_page == index ? 'active' : ''"
          >{{ index }}</a
        >
        <a
          v-if="page.current_page < page.last_page"
          :href="'/project?' + GetQueryString(page.current_page + 1)"
          >&laquo;</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import header from "./Components/Header";
import projectItem from "../Shared/Componensts/projectItem.vue";

export default {
  data() {
    return {
      isLeft: true,
      isRight: false,
      isList: true,
      show: false,
      data: {},
      isLoading: false,
      filter: {
        category: 0,
        status: 0,
        search: null,
        city: 0,
        province: 0,
      },
      page: {
        current_page: 0,
        from: 0,
        last_page: 0,
      },
      activeId: 0,
      category: [],
      subCategory: [],
      province: [],
      city: [],
      status: [],
    };
  },
  methods: {
    accessBtn() {
      if (this.$refs.scrollelement.scrollLeft < 0) {
        this.isRight = true;
      } else {
        this.isRight = false;
      }
      var element = this.$el.querySelector(".scrollelement");
      var maxScrollLeft =
        parseFloat(element.scrollWidth) - parseFloat(element.clientWidth);
      if (this.$refs.scrollelement.scrollLeft > -(maxScrollLeft - 1)) {
        this.isLeft = true;
      } else {
        this.isLeft = false;
      }
    },
    left: function () {
      var container = this.$el.querySelector(".scrollelement");
      container.scrollLeft += -100;
    },
    right: function () {
      var container = this.$el.querySelector(".scrollelement");
      container.scrollLeft += 100;
    },
    provinceChange: function (event) {
      this.filter.city = 0;
      this.city = [];
      this.filter.province = parseInt(event.target.value);
      this.$http.get("enum/province/" + event.target.value).then(
        (response) => {
          if (response.status == 200) {
            this.city = response.body.counties;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.city = data.body.counties;
          }
        }
      );
      this.UpdatePage();
    },
    cityChange: function (event) {
      this.filter.city = event.target.value;
      this.UpdatePage();
    },
    statusChange: function (event) {
      this.filter.status = event.target.value;
      this.UpdatePage();
    },
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectCategory: function (id) {
      this.activeId = id;
      var model = this.category.filter((item) => item.id == id);
      this.subCategory = model[0].subject;
      this.show = true;
      this.filter.category = id;
      return;
    },
    selectSubCategory: function (id) {
      this.filter.category = id;
      this.UpdatePage();
      return;
    },
    GetQueryString(page = 1) {
      var query = "";
      if (page) {
        query += "page=" + page;
      }
      if (this.filter.category) {
        query += "&category=" + this.filter.category;
      }
      if (this.filter.province) {
        query += "&province=" + this.filter.province;
      }
      if (this.filter.city) {
        query += "&city=" + this.filter.city;
      }
      if (this.filter.search) {
        query += "&search=" + this.filter.search;
      }
      if (this.filter.status) {
        query += "&status=" + this.filter.status;
      }
      return query;
    },
    UpdatePage: function () {
      this.$http.get("case?" + this.GetQueryString()).then(
        (response) => {
          if (response.status == 200) {
            this.data = response.body.data;
            this.page = response.body.meta;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.data = data.body.data;
            this.page = data.body.meta;
          }
        }
      );
    },
  },
  mounted() {
    this.$refs.scrollelement.focus();
  },
  watch: {
    "$refs.scrollelement.scrollLeft"() {},
  },
  created() {
    this.isLoading = true;
    if (this.$route.query.category) {
      this.filter.category = this.$route.query.category;
      this.selectCategory(this.filter.category);
    }
    if (this.$route.query.province) {
      this.filter.province = this.$route.query.province;
      this.$http.get("enum/province/" + this.filter.province).then(
        (response) => {
          if (response.status == 200) {
            this.city = response.body.counties;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.city = data.body.counties;
          }
        }
      );
    }
    if (this.$route.query.city) {
      this.filter.city = this.$route.query.city;
    }
    if (this.$route.query.search) {
      this.filter.search = this.$route.query.search;
    }
    if (this.$route.query.status) {
      this.filter.status = this.$route.query.status;
    }

    this.$http.get("enum/category/case").then(
      (response) => {
        if (response.status == 200) {
          this.category = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.category = data.body;
        }
      }
    );
    this.$http.get("case?" + this.GetQueryString(this.$route.query.page)).then(
      (response) => {
        if (response.status == 200) {
          this.data = response.body.data;
          this.page = response.body.meta;
          this.isLoading = false;
          console.log(this.data[0].info);
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.data;
          this.page = data.body.meta;
        }
      }
    );
    this.$http.get("enum/province").then(
      (response) => {
        if (response.status == 200) {
          this.province = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.province = data.body;
        }
      }
    );
    this.$http.get("enum/case/status").then(
      (response) => {
        if (response.status == 200) {
          this.status = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.status = data.body;
        }
      }
    );
  },
  components: {
    "app-header": header,
     projectItem,
  },
  metaInfo() {
    return {
      title: this.data.title,
      meta: [
        {
          name: "description",
          content:
            "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
        },
        {
          property: "og:title",
          content: "Epiloge - Build your network in your field of interest",
        },
        {
          property: "og:site_name",
          content: "Epiloge",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
* {
  scroll-behavior: smooth;
}
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none;
}
.relative {
  position: relative;
}
button {
  &:focus {
    outline: none;
  }
}
.btn-left {
  position: absolute;
  left: -50px;
  top: 35%;
  border: none;
  background: none;
  font-size: 2em;
  color: #939393;
  outline: 0;
}
.btn-right {
  position: absolute;
  right: -50px;
  top: 35%;
  border: none;
  background: none;
  font-size: 2em;
  color: #939393;
  outline: 0;
}
.sk-cube-grid {
  width: 90px;
  height: 90px;
  margin: 10px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $themeColorGreen;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.bg-sabz {
  background-color: $themeColorGreen;
}
.text-sabz {
  color: $themeColorGreen;
}
.bg-custom-gray {
  background-color: $themeColorLight;
}
.text-custom-gray {
  color: $themeColorLight;
}
.text-bold {
  font-weight: bold;
}
.relative {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(59, 207, 147, 0.1);
  height: 100%;
}
.showAll {
  text-align: center;
  .headerGrouping {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    padding: 10px 0px;
    margin: 15px;
    outline: none;
    width: 140px;
    height: 140px;
    &:hover {
      text-decoration: none;
    }

    .active {
      width: 15px;
      height: 15px;
      background-color: $themeColorGreen;
      position: absolute;
      top: 0px;
    }

    img {
      width: 51px;
      height: 50px;
    }
    h4 {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #303030;
      padding-top: 25px;
    }
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: $themeColorGreen;
    padding-top: 20px;
    a {
      color: $themeColorGreen;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.collapsmore {
  .navbar {
    justify-content: center;
  }
  .navbar-toggler {
    border-color: 0;
    &:focus {
      outline: none;
    }
  }
  .otherTopics {
    .btn {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #303030;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 15px 40px;
      margin: 10px 0px;
      &:hover {
        border: 1px solid #4d5dd4;
        color: #4d5dd4;
      }
      &:focus {
        background-color: rgba(77, 93, 212, 0.15);
        box-shadow: none;
        border: 1px solid #4d5dd4;
        color: #4d5dd4;
      }
    }
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    color: #3bcf93;
  }
}
.listProject {
  .btnList {
    outline: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    margin: 0 6px;
    font-size: 22px;
    padding-top: 8px;
    color: #afafaf;
    transform: rotate(180deg);
  }
  .active {
    color: #3bcf93;
  }
}

.search {
  display: flex;
  .searchTerm {
    width: 100%;
    border: 1px solid #3bcf93;
    border-left: none;
    background: rgba(59, 207, 147, 0.1);
    padding-right: 20px;
    height: 37px;
    border-radius: 0px 5px 5px 0px;
    outline: none;
    color: #9dbfaf;
    &::placeholder {
      color: #3bcf93;
      font-weight: 500;
    }
    &:focus {
      color: #3bcf93;
      font-weight: 600;
    }
  }

  .searchButton {
    width: 40px;
    height: 37px;
    border: 1px solid #3bcf93;
    border-right: none;
    background: rgba(59, 207, 147, 0.1);
    text-align: center;
    color: #3bcf93;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
    padding-top: 6px;
    &:focus {
      outline: none;
    }
  }
}


.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #3bcf93;
  color: white;
  border: 1px solid #3bcf93;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
@media (max-width: 991.99px) {
  .listCard .card-body .consultantName {
    position: sticky;
  }
}
</style>
