<template>
  <div>
    <div class="scroll-sec">
      <ul>
        <li v-bind:class="{ active: this.liMenu === 'slider' }">
          <router-link to="#slider" v-on:click="changeClass('slider')" v-smooth-scroll>
            اسلایدر
            </router-link>
        </li>
        <li v-bind:class="{ active: this.liMenu === 'karname' }">
          <router-link to="#karname" v-on:click="changeClass('karname')" v-smooth-scroll>
            کارنامه ما
            </router-link>
        </li>
        <li v-bind:class="{ active: this.liMenu === 'project' }">
          <router-link to="#project" v-on:click="changeClass('project')" v-smooth-scroll>
            پروژه های ما
            </router-link>
        </li>
        <li v-bind:class="{ active: this.liMenu === 'about' }">
          <router-link to="#about" v-on:click="changeClass('about')" v-smooth-scroll>
            درباره ما
            </router-link>
        </li>
        <li v-bind:class="{ active: this.liMenu === 'support' }">
          <router-link to="#support" v-on:click="changeClass('support')" v-smooth-scroll>
            خدمات ما
            </router-link>
        </li>
        <li v-bind:class="{ active: this.liMenu === 'news' }">
          <router-link to="#news" v-on:click="changeClass('news')" v-smooth-scroll
          >اخبار ما
            </router-link>
        </li>
        <li v-bind:class="{ active: this.liMenu === 'brand' }">
          <router-link to="#brand" v-on:click="changeClass('brand')" v-smooth-scroll>
            همراهان ما
            </router-link>
        </li>
      </ul>
    </div>
    <app-banner class="part" id="slider"></app-banner>
    <app-rank class="part fix-menu" id="karname"></app-rank>
    
    <app-sliderproject class="part fix-menu" id="project"></app-sliderproject>
    <app-about class="part fix-menu" id="about"></app-about>
    <app-support class="part fix-menu" id="support"></app-support>
    <app-news class="part fix-menu" id="news"></app-news>

    <app-brand class="part fix-menu" id="brand"></app-brand>
  </div>
</template>

<script>
import banner from "./Components/Banner";
// import topproject from "./Components/Topproject";
// import slider from "./Components/Slider";
import sliderproject from "./Components/Sliderproject";
import about from "./Components/About";
import support from "./Components/Support";
import news from "./Components/News";
import rank from "./Components/Rank";
import brand from "./Components/Brand";


export default {
  data() {
    return {
      liMenu: "slider",
      scrollPosition: null,
      sectionObserver: null,
    };
  },
  components: {
    "app-banner": banner,
    "app-sliderproject": sliderproject,
    "app-about": about,
    "app-support": support,
    "app-news": news,
    "app-rank": rank,
    "app-brand": brand,


  },
  mounted() {
    this.observeSections();
  },
  methods: {
    changeClass: function (params) {
      this.liMenu = params;
    },
    observeSections() {
      try {
        this.sectionObserver.disconnect();
      } catch (error) {
        console.log(error);
      }

      const options = {
        rootMargin: "0px 0px",
        threshold: 0,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll(".part");
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });

    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          // Push sectionId to router here
          this.liMenu =sectionId;
          this.$router.push({ name: this.$route.name, hash: `#${sectionId}` });
        }
      }
    },
  },
  metaInfo() {
    return {
      title: "کاروام",
      meta: [
        {
          name: "description",
          content: "جلب مشارکت‌های عمومی برای راه‌اندازی مشاغل خرد",
        },
        {
          property: "og:title",
          content: "کاروام - جلب مشارکت‌های عمومی برای راه‌اندازی مشاغل خرد",
        },
        {
          property: "og:site_name",
          content: "کاروام",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
html {
  scroll-behavior: smooth;
}
.part:not(:first-child){
  min-height: calc(100vh);
}
.fix-menu{
  padding-top:100px !important;
}
.scroll-sec {
  position: fixed;
  top: 50%;
  left: 0px;
  z-index: 99;
  color: white;
  direction: ltr;

  li {
    list-style: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(56, 138, 105, 0.5);
    margin: 10px;
    text-align: center;
    padding: 5px 0px;
    transition: all 0.2s;
    &.active {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      background: #3BCF93;
      border-radius: 20px;
      a {
        display: none;
        text-decoration: none;
      }
    }
    &:hover {
      width: 100px;
      height: 30px;
      background: rgba(56, 138, 105, 0.5);
      border-radius: 20px;

      a {
        display: block;
        text-decoration: none;
      }
    }

    a {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #ffffff;
      display: none;
    }
  }

  .active {
    width: 16px;
    height: 16px;
    background: #3bcf93;
    margin-left: 8px;
  }
}
</style>