<template>
  <div>
    <div class="container contprofile py-5">
      <div class="row">
        <menu-profile></menu-profile>
        <div class="col-md-6 col-lg-8">
          <div class="alert alert-success" v-if="messageSuccess != null">
            {{ messageSuccess }}
          </div>
          <div class="alert alert-danger" v-if="messageError != null">
            {{ messageError }}
          </div>
          <h6 class="userTitle">اطلاعات شخصی</h6>
          <form action="#" class="userForm">
            <div class="form-row">
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="نام"
                  v-model="model.first_name"
                />
                <div v-for="(item, index) in errors.first_name" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="نام خانوادگی"
                  v-model="model.last_name"
                />
                <div v-for="(item, index) in errors.last_name" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <!-- <input
                  type="text"
                  class="form-control"
                  placeholder="1400/01/01"
                  v-model="model.birthdate"
                /> -->
                <date-picker
                  clearable
                  placeholder="1400/01/01"
                  v-model="model.birthdate"
                />
                <div v-for="(item, index) in errors.birthdate" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="نام کاربری"
                  v-model="model.name"
                />
                <div v-for="(item, index) in errors.name" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>

              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="کد ملی"
                  v-model="model.national_id"
                />
                <div v-for="(item, index) in errors.national_id" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>

              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="شماره شناسنامه"
                  v-model="model.card_id"
                />
                <div v-for="(item, index) in errors.card_id" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="نام پدر"
                  v-model="model.father_name"
                />
                <div v-for="(item, index) in errors.father_name" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <select class="custom-select" v-model="model.householders">
                  <option value="on">سرپرست خانوار هستم</option>
                  <option value="off">سرپرست خانوار نیستم</option>
                </select>
                <div v-for="(item, index) in errors.householders" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="تلفن همراه"
                  v-model="model.mobile"
                />
                <div v-for="(item, index) in errors.mobile" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>

              <div class="col-lg-6 mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="پست الکترونیکی"
                  v-model="model.email"
                />
                <div v-for="(item, index) in errors.email" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>

              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="مدرک تحصیلی"
                  v-model="model.education"
                />
                <div v-for="(item, index) in errors.education" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="رشته تحصیلی"
                  v-model="model.field_of_study"
                />
                <div
                  v-for="(item, index) in errors.field_of_study"
                  :key="index"
                >
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="کد پستی"
                  v-model="model.postalcode"
                />
                <div v-for="(item, index) in errors.postalcode" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="تلفن ثابت"
                  v-model="model.phone"
                />
                <div v-for="(item, index) in errors.phone" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="mb-3 col-lg-6">
                <div class="row">
                  <div class="col-6 gender">
                    <label class="userLable">جنسیت</label>
                  </div>
                  <div class="col-lg-6 col-md-12 col-6 d-flex">
                    <div class="form-row my-auto">
                      <div class="col-6">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="model.gender"
                          value="1"
                        />
                        <label class="form-check-label" for="male"> آقا </label>
                      </div>
                      <div class="col-6">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="model.gender"
                          value="0"
                        />
                        <label class="form-check-label" for="female">
                          خانم
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-100"
                    v-for="(item, index) in errors.gender"
                    :key="index"
                  >
                    <span class="message-error">{{ item }}</span>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-3">
                <textarea
                  class="form-control"
                  v-model="model.address"
                  rows="4"
                  placeholder="نشانی دقیق"
                ></textarea>
                <div v-for="(item, index) in errors.address" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
            </div>
            <h6 class="userTitle">اطلاعات معرف</h6>
            <div class="form-row">
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="نام"
                  v-model="model.presenter_name"
                />
                <div
                  v-for="(item, index) in errors.presenter_name"
                  :key="index"
                >
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="تلفن همراه"
                  v-model="model.presenter_mobile"
                />
                <div
                  v-for="(item, index) in errors.presenter_mobile"
                  :key="index"
                >
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
            </div>
            <h6 class="userTitle">اطلاعات حساب</h6>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="شماره کارت"
                  v-model="model.bank_card_no"
                />
                <div v-for="(item, index) in errors.bank_card_no" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="شماره حساب"
                  v-model="model.bank_account_no"
                />
                <div
                  v-for="(item, index) in errors.bank_account_no"
                  :key="index"
                >
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="شماره شبا"
                  v-model="model.bank_sheba"
                />
                <div v-for="(item, index) in errors.bank_sheba" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
            </div>
            <h6 class="userTitle">تغییر رمز عبور</h6>
            <div class="form-row">
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="رمز عبور جدید"
                  v-model="model.password"
                />
                <div v-for="(item, index) in errors.password" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="تکرار رمز عبور جدید"
                  v-model="model.c_password"
                />
                <div v-for="(item, index) in errors.c_password" :key="index">
                  <span class="message-error">{{ item }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="userBtn">
                <button type="button" class="userBtnSend" v-on:click="submit()">
                  ثبت تغییرات
                </button>
                <router-link to="/profile/Dashboard">
                  بازگشت به پروفایل
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu from "../Shared/MenuProfile";

export default {
  components: {
    "menu-profile": menu,
  },
  data() {
    return {
      model: {
        id: 0,
        name: "",
        mobile: "",
        email: "",
        password: "",
        c_password: "",
        first_name: "",
        last_name: "",
        father_name: "",
        card_id: "",
        national_id: "",
        education: "",
        field_of_study: "",
        phone: "",
        postalcode: "",
        address: "",
        gender: 0,
        householders: "off",
        birthdate: "",
        presenter_name: "",
        presenter_mobile: "",
        bank_card_no: "",
        bank_account_no: "",
        bank_sheba: "",
      },
      errors: {
        name: [],
        mobile: [],
        email: [],
        password: [],
        c_password: [],
        first_name: [],
        last_name: [],
        father_name: [],
        card_id: [],
        national_id: [],
        education: [],
        field_of_study: [],
        phone: [],
        postalcode: [],
        address: [],
        gender: [],
        householders: [],
        birthdate: [],
        presenter_name: [],
        presenter_mobile: [],
        bank_card_no: [],
        bank_account_no: [],
        bank_sheba: [],
      },
      messageError: null,
      messageSuccess: null,
    };
  },
  created() {
    this.$http.get("user/info").then(
      (response) => {
        if (response.status == 200) {
          var models = response.body.data;
          this.model.id = models.id;
          this.model.last_name = models.profile.last_name;
          this.model.name = models.name;
          this.model.mobile = models.profile.mobile;
          this.model.email = models.email;
          this.model.password = "";
          this.model.c_password = "";
          this.model.first_name = models.profile.first_name;
          this.model.father_name = models.profile.father_name;
          this.model.card_id = models.profile.card_id;
          this.model.national_id = models.profile.national_id;
          this.model.education = models.profile.education;
          this.model.field_of_study = models.profile.field_of_study;
          this.model.phone = models.profile.phone;
          this.model.postalcode = models.profile.postalcode;
          this.model.address = models.profile.address;
          this.model.gender = models.profile.gender;
          this.model.householders = models.profile.householders;
          this.model.birthdate = models.profile.birthdate;
          this.model.presenter_name = models.profile.presenter.name;
          this.model.presenter_mobile = models.profile.presenter.mobile;
          this.model.bank_card_no = models.profile.bank_card_no;
          this.model.bank_account_no = models.profile.bank_account_no;
          this.model.bank_sheba = models.profile.bank_sheba;
        }

        // this.model.email = `${this.rand(7)}@asf.as`;
        // this.model.father_name = "asdasf";
        // this.model.card_id = "651";
        // this.model.national_id = "0924152214";
        // this.model.education = "asf";
        // this.model.field_of_study = "asdf";
        // this.model.postalcode = "654";
        // this.model.phone = "65655";

        // this.model.address = "54asf";
        // this.model.gender = 1;
        // this.model.householders = "on";
        // this.model.presenter_name = "asfasdgf";
        // this.model.presenter_mobile = "09381552845";
        // this.model.bank_card_no = "1111111111111111";
        // this.model.bank_account_no = "1";
        // this.model.bank_sheba = "111111111111111111111111";
      },
      (data) => {
        if (data.status == 200) {
          console.log(data);
          var models = data.body.data;
          this.model.id = models.id;
          this.model.last_name = models.profile.last_name;
          this.model.name = models.name;
          this.model.mobile = models.profile.mobile;
          this.model.email = models.email;
          this.model.password = "";
          this.model.c_password = "";
          this.model.first_name = models.profile.first_name;
          this.model.father_name = models.profile.father_name;
          this.model.card_id = models.profile.card_id;
          this.model.national_id = models.profile.national_id;
          this.model.education = models.profile.education;
          this.model.field_of_study = models.profile.field_of_study;
          this.model.phone = models.profile.phone;
          this.model.postalcode = models.profile.postalcode;
          this.model.address = models.profile.address;
          this.model.gender = models.profile.gender;
          this.model.householders = models.profile.householders;
          this.model.birthdate = models.profile.birthdate;
          this.model.presenter_name = models.profile.presenter.name;
          this.model.presenter_mobile = models.profile.presenter.mobile;
          this.model.bank_card_no = models.bank_card_no;
          this.model.bank_account_no = models.bank_account_no;
          this.model.bank_sheba = models.bank_sheba;
        }
      }
    );
  },
  methods: {
    pushMethod: function (models) {
      this.model.last_name = models.profile.last_name;
      this.model.name = models.name;
      this.model.mobile = "";
      this.model.email = models.email;
      this.model.password = "";
      this.model.c_password = "";
      this.model.first_name = models.profile.first_name;
      this.model.father_name = models.profile.father_name;
      this.model.card_id = models.profile.card_id;
      this.model.national_id = models.profile.national_id;
      this.model.education = models.profile.education;
      this.model.field_of_study = models.profile.field_of_study;
      this.model.phone = "";
      this.model.postalcode = "";
      this.model.address = "";
      this.model.gender = models.profile.gender;
      this.model.householders = models.profile.householders;
      this.model.birthdate = "";
      this.model.presenter_name = models.profile.presenter.name;
      this.model.presenter_mobile = models.profile.presenter.mobile;
    },
    submit: function () {
      this.messageSuccess = null;
      this.messageError = null;
      this.errors.name = [];
      this.errors.mobile = [];
      this.errors.email = [];
      this.errors.password = [];
      this.errors.c_password = [];
      this.errors.first_name = [];
      this.errors.last_name = [];
      this.errors.father_name = [];
      this.errors.card_id = [];
      this.errors.national_id = [];
      this.errors.education = [];
      this.errors.field_of_study = [];
      this.errors.phone = [];
      this.errors.postalcode = [];
      this.errors.address = [];
      this.errors.gender = [];
      this.errors.householders = [];
      this.errors.birthdate = [];
      this.errors.presenter_name = [];
      this.errors.presenter_mobile = [];
      this.errors.bank_card_no = [];
      this.errors.bank_account_no = [];
      this.errors.bank_sheba = [];

      this.$http.post("user/" + this.model.id, this.model).then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            window.scrollTo(0, 0);
            this.messageSuccess = "ویرایش پروفایل با موفقیت انجام شد";
          } else {
            window.scrollTo(0, 0);
            this.messageError = "خطا در ویرایش پروفایل";
            this.errors = response.data.errors;
          }
        },
        (data) => {
          console.log(data);
          if (data.status == 200) {
            window.scrollTo(0, 0);
            this.messageSuccess = "ویرایش پروفایل با موفقیت انجام شد";
          } else {
            window.scrollTo(0, 0);
            this.messageError = "خطا در ویرایش پروفایل";
            this.errors = data.data.errors;
          }
        }
      );
    },
    rand: function (length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
.rtl .custom-select,
[dir="rtl"] .custom-select {
  padding: 0.375rem 1.75rem 0.375rem 1.75rem;
}
.vpd-input-group .vpd-clear-btn {
  line-height: 47px !important;
}
.vpd-input-group {
  height: 100% !important;
}

.form-control {
  margin: 0 !important;
}
.custom-select {
  margin: 0 !important;
}

.message-error {
  color: #f44336;
  font-size: 13px;
}
.contprofile {
  margin-top: 130px;
}

.userTitle {
  font-weight: bold;
  margin: 25px auto;
}

.form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit;
}

.form-check-label {
  padding-right: 2.5rem;
}

.userForm {
  .form-row {
    margin-bottom: 25px;
  }
  input[type="text"],
  input[type="number"],
  input[type="email"],
  select,
  textarea {
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: #f1f1f1;

    &:hover {
      border: 2px solid #4d5dd4;
      border-radius: 0;
    }

    &::placeholder {
      color: #9e9e9e;
      font-weight: 400;
    }
  }
  input[type="radio"] {
    // height: 70px;
  }
  textarea {
    height: 100px;
  }
  .userLable {
    color: #9e9e9e;
    background-color: #f1f1f1;
    padding: 15px 40px;
    border-radius: 5px;
  }
  .userBtn {
    float: left;
  }
  .userBtnSend {
    color: #fff;
    background-color: #3bcf93;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-left: 30px;
  }
  .userBtnCancel {
    color: #f44336;
    font-weight: 700;
    text-decoration: none;
    border-bottom: 1px solid #f44336;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .gender {
    display: none;
  }
}
</style>