<template>
  <div class="fix-menu">
    <app-header></app-header>

    <div class="container pt-5">
      <div class="row">
        <div class="col-lg-8 col-md-8 d-flex align-items-center">
          <div class="form-group w-25 m-0 mr-3">
            <select
              class="form-control bg-light text-secondary"
              @change="provinceChange($event)"
              v-model="filter.province"
            >
              <option value="0">استان ها</option>
              <option :value="item.id" v-for="item in province" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="form-group w-25 m-0">
            <select
              class="form-control bg-light text-secondary"
              @change="cityChange($event)"
              v-model="filter.city"
            >
              <option value="0">همه شهرها</option>
              <option :value="item.id" v-for="item in city" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="wrap ml-auto">
            <div class="search">
              <input
                type="text"
                class="searchTerm"
                v-model="filter.search"
                placeholder="جست و جو ..."
              />
              <button
                type="button"
                v-on:click="UpdatePage()"
                class="searchButton"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-3">
      <div
        class="alert alert-primary w-100 my-4 text-center"
        role="alert"
        v-if="data.length == 0"
      >
        هیچ موردی یافت نشد
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-sm-6"
          v-for="item in data"
          :key="item.id"
        >
          <div class="card mt-3">
            <div class="image-user">
              <div class="hexagon hexagon-with-border">
                <div class="hexagon-shape">
                  <div class="hexagon-shape-inner">
                    <div class="hexagon-shape-inner-2"></div>
                  </div>
                </div>
                <div class="hexagon-shape content-panel">
                  <div class="hexagon-shape-inner">
                    <div class="hexagon-shape-inner-2">
                      <img
                        :src="item.avatar"
                        v-if="item.avatar"
                        :alt="item.profile.full_name"
                      />
                      <img src="http://karvam.ir/noimage.jpg" alt="" else />
                    </div>
                  </div>
                </div>
              </div>
              <div class="personal-info">
                <h5 v-bind:title="item.profile.full_name">
                  {{ textRestrictions(item.profile.full_name, 15) }}
                </h5>
                <div class="rank">
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-off.png" alt="off" />
                </div>
                <img
                  class="medal"
                  src="../../assets/img/star/medal.png"
                  alt=""
                />
              </div>
            </div>

            <div class="card-body">
              <div class="py-3">
                <div class="d-flex justify-content-between">
                  <span style="color: #afafaf">میزان حمایت:</span>
                  <span class="bold">
                    {{ formatPrice(item.statistics.benefet.amount) }}
                    تومان
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span style="color: #afafaf">تعداد پروژه:</span>
                  <span class="bold"
                    >{{ item.statistics.benefet.qty }} مورد</span
                  >
                </div>
              </div>
              <div class="text-center py-3">
                <button
                  v-on:click="openModal(item.id)"
                  class="btn"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  مشاهده پروژه ها
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="w-100" v-if="data == null || this.data.length == 0">
              <div class="alert alert-primary" role="alert">
                هیچ نتیجه ای یافت نشد
              </div>
            </div>
            <div class="w-100" v-else>
              <div
                class="bg-gray container-fluid py-2 rounded-light header-table"
              >
                <div class="row">
                  <div class="col-3 text-center border-left py-2">
                    تاریخ واریز
                  </div>
                  <div class="col-5 text-center border-left py-2">شرح</div>
                  <div class="col-4 text-center border-left py-2">مبلغ</div>
                </div>
              </div>
              <div v-for="item in listData" :key="item.id" class="container-fluid mt-3 rounded-light border py-4">
                <div class="row">
                  <div class="col-3 text-center text-justify">{{ item.date }}</div>
                  <div class="col-5 text-center text-justify">{{ item.case_name }}</div>
                  <div class="col-4 text-center text-justify">{{ item.amount }} تومان</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container text-center" v-if="page.last_page > 1">
      <div class="pagination py-5">
        <div class="pagination py-5">
          <a
            v-if="page.current_page > 1"
            :href="'/Supports?' + GetQueryString(page.current_page - 1)"
          >
            &raquo;
          </a>
          <a
            v-for="index in page.last_page"
            :key="index"
            :href="'/Supports?' + GetQueryString(index)"
            :class="page.current_page == index ? 'active' : ''"
          >
            {{ index }}
          </a>
          <a
            v-if="page.current_page < page.last_page"
            :href="'/Supports?' + GetQueryString(page.current_page + 1)"
          >
            &laquo;
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import header from "./Components/Header";

export default {
  data() {
    return {
      data: {},
      listData: [],
      filter: {
        search: null,
        city: 0,
        province: 0,
      },
      page: {
        current_page: 0,
        from: 0,
        last_page: 0,
      },
      province: [],
      city: [],
    };
  },
  methods: {
    openModal(id) {
      this.listData = [];
      this.$http.get("supporters/" + id).then(
        (response) => {
          console.log(response.body);
          if (response.status == 200) {
            this.listData = response.body.data;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.listData = data.body.data;
          }
        }
      );
    },
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    textRestrictions(text, value) {
      let length = text.length;
      if (length >= value) {
        return text.substr(0, value) + "...";
      } else {
        return text;
      }
    },
    GetQueryString(page = 1) {
      var query = "";
      if (page) {
        query += "page=" + page;
      }
      return query;
    },
    UpdatePage: function () {
      this.$http.get("/supporters?" + this.GetQueryString()).then(
        (response) => {
          if (response.status == 200) {
            this.data = response.body.data;
            this.page = response.body.meta;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.data = data.body.data;
            this.page = data.body.meta;
          }
        }
      );
    },
  },
  created() {
    this.$http.get("supporters").then(
      (response) => {
        if (response.status == 200) {
          console.log(response.body.data);
          this.data = response.body.data;
          // this.page = response.body.meta;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.data;
          // this.page = data.body.meta;
        }
      }
    );
  },
  components: {
    "app-header": header,
  },
  metaInfo() {
    return {
      title: "عنوان",
      meta: [
        {
          name: "description",
          content:
            "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
        },
        {
          property: "og:title",
          content: "Epiloge - Build your network in your field of interest",
        },
        {
          property: "og:site_name",
          content: "Epiloge",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.bg-sabz {
  background-color: $themeColorGreen;
}
.text-sabz {
  color: $themeColorGreen;
}
.bg-custom-gray {
  background-color: $themeColorLight;
}
.text-custom-gray {
  color: $themeColorLight;
}
.text-bold {
  font-weight: 700;
}

.search {
  display: flex;
  .searchTerm {
    width: 100%;
    border: 1px solid #3bcf93;
    border-left: none;
    background: rgba(59, 207, 147, 0.1);
    padding-right: 20px;
    height: 37px;
    border-radius: 0px 5px 5px 0px;
    outline: none;
    color: #9dbfaf;
    &::placeholder {
      color: #3bcf93;
      font-weight: 500;
    }
    &:focus {
      color: #3bcf93;
      font-weight: 600;
    }
  }

  .searchButton {
    width: 40px;
    height: 37px;
    border: 1px solid #3bcf93;
    border-right: none;
    background: rgba(59, 207, 147, 0.1);
    text-align: center;
    color: #3bcf93;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
    padding-top: 6px;
    &:focus {
      outline: none;
    }
  }
}
.image-user {
  display: flex;
  flex-flow: wrap;
  position: relative;
  width: 100%;
  .hexagon {
    width: 50%;
  }
  .personal-info {
    position: absolute;
    width: 50%;
    top: 54px;
    left: 55px;
    h5 {
      font-weight: 700;
    }
    .medal {
      position: absolute;
      top: -25px;
      left: -34px;
      width: 25px;
      height: 25px;
    }
  }
}

.hexagon {
  position: relative;
  display: inline-block;
  width: auto;
  height: 150px;
}
.hexagon .hexagon-shape {
  position: absolute;
  right: -36px;
  overflow: hidden;
  display: inline-block;
  margin: -20px 0px;
  width: 200px;
  height: 207px;
  transform: rotate(-30deg) skewX(30deg) scale(0.5);
  border-radius: 20px;
}
.hexagon .hexagon-shape *,
.hexagon .hexagon-shape *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}
.hexagon .hexagon-shape .hexagon-shape-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  opacity: 1;
}
.hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
.hexagon .hexagon-shape .hexagon-shape-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  background: #4d5dd4;
  content: "";
}

.hexagon .hexagon-content .content-sub {
  color: #9f9fa0;
}
.hexagon.hexagon-with-border .hexagon-shape.content-panel {
  transform: rotate(-30deg) skewX(30deg) scale(0.48);
}

.hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
.hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
  background: #eef9fe;
}
.hexagon.info .contentTitle {
  color: #54c6f3;
}
.hexagon-shape-inner-2 {
  img {
    transform: rotate(-61deg) skewX(30deg) scale(1.2);
  }
}

.card {
  background: #ffffff;
  border-radius: 10px;
  .card-body {
    .card-title {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #303030;
    }
    .checked {
      color: #ffc820;
    }
    .unchecked {
      color: #afafaf;
    }
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: #303030;
    }
    .btn {
      border: 1px solid #3bcf93;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #3bcf93;
      padding: 10px 40px;
      &:hover {
        color: #fff;
        background-color: #3bcf93;
      }
    }
  }
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #3bcf93;
  color: white;
  border: 1px solid #3bcf93;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
.bg-gray {
  background-color: #f1f1f1;
}
.rounded-light {
  border-radius: 10px;
}
.header-table {
  div {
    border-color: #dee4e8;
    color: #afafaf;
    font-size: 14px;
    font-weight: 700;
  }
}
.bold {
  font-weight: 700;
}
</style>
