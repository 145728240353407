<template>
  <div>
    <div class="container contprofile py-5">
      <div class="row">
        <menu-profile></menu-profile>
        <div class="col-md-6 col-lg-8">
          <grouping></grouping>
          <!--          <apexchart-->
          <!--            class="w-100"-->
          <!--            type="bar"-->
          <!--            :options="chartOptions"-->
          <!--            :series="series"-->
          <!--          ></apexchart>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import grouping from "./Components/Grouping";
import menu from "../Shared/MenuProfile";

export default {
  components: {
    grouping: grouping,
    "menu-profile": menu,
  },
};
</script>

<style lang="scss" scoped>
.contprofile {
  margin-top: 130px;
}
</style>