<template>
  <div>
    <div class="container alaki">
      <div class="row">
        <div class="container py-3" v-if="list != null">
          <div class="caption">
            <div class="car row">
              <div class="col-md-5">
                <img
                  :src="list[0].pictureURL"
                  class="w-100"
                  :alt="list[0].title"
                />
              </div>
              <div class="col-md-7 text-news">
                <div class="caro">
                  <h5>{{ list[0].title }}</h5>

                  <div class="text-right float-l">
                    <router-link :to="'/blog/single/' + list[0].id" class="btn">
                      مشاهده مطلب <i class="fal fa-long-arrow-left"></i
                    ></router-link>
                  </div>
                  <div class="text-left">
                    <i class="far fa-eye m-2"></i>
                    <span>{{ list[0].views }}</span>
                    <i class="far fa-clock m-2"></i>
                    <span>{{ list[0].created_at }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="container py-5">
      <div class="lastReports py-3">
        <div class="titlesbrand mb-5">
          <h2>جدید ترین اخبار</h2>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-6" v-for="item in list" :key="item.id">
              <div class="card">
                <div class="card-img-top">
                  <img
                    class="w-100 h-100 img-fluid"
                    :src="item.pictureURL"
                    :alt="item.title"
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title">{{ item.title }}</h5>
                  <p class="card-text">
                    <small class="text-muted">{{ item.created_at }}</small>
                  </p>
                  <p class="card-text txt-summery" v-html="item.summery"></p>
                  <div class="text-right moreReorts">
                    <router-link :to="'/blog/single/' + item.id">
                      مطالعه بیشتر <i class="fal fa-long-arrow-left"> </i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 text-center" v-if="page.last_page > 1">
      <div class="pagination py-5">
        <router-link
          v-if="page.current_page > 1"
          :to="'/project?' + GetQueryString(page.current_page - 1)"
          >&raquo;</router-link
        >
        <router-link
          v-for="index in page.last_page"
          :key="index"
          :to="'/project?' + GetQueryString(index)"
          :class="page.current_page == index ? 'active' : ''"
          >{{ index }}</router-link
        >
        <router-link
          v-if="page.current_page < page.last_page"
          :to="'/project?' + GetQueryString(page.current_page + 1)"
          >&laquo;</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: "",
          title: "",
          summery: "",
          enabled: "",
          views: "",
          created_at: "",
          pictureURL: "",
        },
      ],
      page: {
        current_page: 0,
        from: 0,
        last_page: 0,
      },
    };
  },
  created() {
    this.$http.get("news").then(
      (response) => {
        if (response.status == 200) {
          this.list = response.body.data;
          this.page = response.body.meta;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.list = data.body.data;
          this.page = data.body.meta;
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.alaki {
  padding-top: 100px;
}
.blog-Grouping {
  background-color: #f6f6f6;
  border-radius: 10px;
  .search {
    display: flex;
    .searchTerm {
      width: 85%;
      border: 1px solid #e5e5e5;
      border-left: none;
      background: #e5e5e5;
      padding-right: 20px;
      height: 40px;
      border-radius: 0px 5px 5px 0px;
      outline: none;
      color: #afafaf;
      &::placeholder {
        color: #afafaf;
        font-weight: 500;
      }
      &:focus {
        color: #919191;
        font-weight: 600;
      }
    }

    .searchButton {
      width: 15%;
      height: 40px;
      border: 1px solid #e5e5e5;
      border-right: none;
      background: #e5e5e5;
      text-align: center;
      color: #afafaf;
      border-radius: 5px 0px 0px 5px;
      cursor: pointer;
      padding-top: 6px;
      &:focus {
        outline: none;
      }
    }
  }
  .categoryArticles {
    border-bottom: 1px solid #4d5dd4;
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      margin: 0;
      padding: 19px 5px 12px 5px;
      color: #4d5dd4;
    }
  }
  .card {
    border-bottom: 1px solid #e5e5e5 !important;
    button {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #5e5e5e;
    }
    .card-body {
      background-color: #f6f6f6;
      h5.card-title {
        font-size: 16px;
        line-height: 27px;
        min-height: 55px;
      }
      p {
        margin: 0;
        .btn {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 122.32%;
          letter-spacing: 0.02em;
          color: #afafaf;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .card-header {
      background-color: #f6f6f6;
      padding: 6px 0px 7px 0px;
      .btn {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .collapsible-link {
    width: 100%;
    position: relative;
    text-align: right;
    &::before {
      content: url("../../assets/img/blog/arrow1.png");
      position: absolute;
      top: 50%;
      left: 0.8rem;
      transform: translateY(-50%);
      display: block;
      font-size: 1.1rem;
    }
    &[aria-expanded="true"] {
      &::before {
        content: url("../../assets/img/blog/arrow2.png");
      }
    }
  }
}
.caption {
  .car {
    .text-news {
      align-items: center;
      justify-content: right;
      display: flex;
      .text-left {
        float: right;
        span {
          color: #afafaf;
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
        i {
          color: #afafaf;
        }
      }
      .text-right {
        float: left;
      }
    }
    .caro {
      right: 3%;
      left: 3%;
      padding: 10px;
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.7;
        text-align: right;
        color: #000;
        margin-bottom: 40px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        text-align: right;
        color: #000;
      }
      .btn {
        font-weight: bold;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.02em;
        color: #3bcf93;
        border: 1px solid #3bcf93;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
.lastReports {
  .titlesbrand {
    background-image: url("../../assets/img/blog/Vector.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px 0px;
    h2 {
      font-family: KalamehBold;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.02em;
      color: #303030;
      text-align: center;
      &::before {
        content: url(../../assets/img/support/Rectangle.png);
        position: relative;
        left: 10px;
      }
      &::after {
        content: url(../../assets/img/support/Rectangle.png);
        position: relative;
        right: 10px;
      }
    }
  }
  .card-img-top {
    min-height: 190px;
    max-height: 200px;
    overflow: hidden;
  }
  .card {
    margin-top: 15px;
    &:hover {
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
      i {
        -webkit-animation: arrowAnimation 0.8s infinite backwards;
        animation: arrowAnimation 0.8s infinite backwards;
      }
    }
  }
  .card-title {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #212121;
    min-height: 60px;
  }
  .card-text {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140.1%;
    text-align: right;
    color: #616161;
    .text-muted {
      font-size: 21px;
    }
  }
  .txt-summery {
    max-height: 95px;
    overflow: hidden;
  }
  .moreReorts {
    a {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #5552d3;
      &:hover {
        text-decoration: none;
      }
      @-webkit-keyframes arrowAnimation {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-8px);
        }
        100% {
          transform: translateX(0);
        }
      }
      @keyframes arrowAnimation {
        0% {
          transform: translateX(0);
        }
        50% {
          transform: translateX(-8px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }
}
.moshahedebishtar {
  text-align: center;
  .btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #3bcf93;
    padding: 13px 14px;
    background: rgba(59, 207, 147, 0.15);
    border-radius: 10px;
  }
}
</style>
