<template>
  <div>
    <div class="container">
      <div class="formregister">
        <div class="text-center">
          <img src="../../assets/img/Logo.png" alt="" />
          <p class="titr-register">عضویت در کاروام</p>
        </div>
        <form @submit="submit">
                  <div class="py-1">
            <label for="">نام </label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.first_name"
              :class="errorMessage.first_name.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.first_name" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">نام خانوادگی</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.last_name"
              :class="errorMessage.last_name.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.last_name" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">نام کاربری</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.name"
              :class="errorMessage.name.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.name" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">شماره همراه</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.mobile"
              :class="errorMessage.mobile.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.mobile" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <!-- <div class="py-1">
            <label for="">آدرس ایمیل</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.email"
              style="direction:ltr"
              :class="errorMessage.email.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.email" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div> -->
          <div class="py-1">
            <label for="">رمز عبور</label>
            <br />
            <div class="input-group">
              <input
                v-model="model.password"
                v-bind:type="[showPassword ? 'text' : 'password']"
                class="form-control"
                placeholder=""
              />
              <div class="input-group-append">
                <span
                  class="input-group-text"
                  @click="showPassword = !showPassword"
                >
                  <i
                    class="fal"
                    :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
            <div v-for="(item, index) in errorMessage.password" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-2">
            <label for="">تکرار رمز عبور</label>
            <br />
            <div class="input-group">
              <input
                v-model="model.c_password"
                v-bind:type="[showConfirmPassword ? 'text' : 'password']"
                class="form-control"
                placeholder=""
              />
              <div class="input-group-append">
                <span
                  class="input-group-text"
                  @click="showConfirmPassword = !showConfirmPassword"
                >
                  <i
                    class="fal"
                    :class="[showConfirmPassword ? 'fa-eye' : 'fa-eye-slash']"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
            <div v-for="(item, index) in errorMessage.c_password" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
  
          <!-- <div class="py-1">
            <label for="">نام پدر</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.father_name"
              :class="errorMessage.father_name.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.father_name" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">شماره شناسنامه</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.card_id"
              :class="errorMessage.card_id.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.card_id" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">شماره ملی</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.national_id"
              :class="errorMessage.national_id.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.national_id" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">مدرک تحصیلی</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.education"
              :class="errorMessage.education.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.education" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">رشته تحصیلی</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.field_of_study"
              :class="errorMessage.field_of_study.length ? 'notvall' : 'isvall'"
            />
            <div
              v-for="(item, index) in errorMessage.field_of_study"
              :key="index"
            >
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">شماره تلفن</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.phone"
              :class="errorMessage.phone.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.phone" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">کد پستی</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.postalcode"
              :class="errorMessage.postalcode.length ? 'notvall' : 'isvall'"
            />
            <div v-for="(item, index) in errorMessage.postalcode" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">آدرس</label>
            <br />
            <textarea
              type="text"
              class="tell"
              v-model="model.address"
              :class="errorMessage.address.length ? 'notvall' : 'isvall'"
            >
            </textarea>
            <div v-for="(item, index) in errorMessage.address" :key="index">
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <div class="form-check">
              <label for="">جنسیت</label>
              <input
                class="form-check-input"
                type="radio"
                name="gridRadios"
                id="gridRadios1"
                value="مرد"
                style="right: 70px"
                checked
                v-model="model.gender"
                :class="errorMessage.gender.length ? 'notvall' : 'isvall'"
              />
              <label class="form-check-label" for="gridRadios1"> مرد </label>

              <input
                class="form-check-input"
                type="radio"
                name="gridRadios"
                id="gridRadios2"
                value="زن"
                v-model="model.gender"
                style="right: 128px"
              />
              <label class="form-check-label" for="gridRadios2"> زن </label>
              <label for="">سرپرست خانواده</label>
              <input
                class="form-check-input"
                style="margin-right: 5px"
                type="checkbox"
                value=""
                id="defaultCheck1"
                v-model="model.householders"
              />
            </div>
          </div>
          <div class="py-1">
            <label for="">نام معرف</label>
            <br />
            <input
              type="text"
              class="tell"
              v-model="model.presenter_name"
              :class="errorMessage.presenter_name.length ? 'notvall' : 'isvall'"
            />
            <div
              v-for="(item, index) in errorMessage.presenter_name"
              :key="index"
            >
              <span class="message-error">{{ item }}</span>
            </div>
          </div>
          <div class="py-1">
            <label for="">شماره همراه معرف</label>
            <br />
            <input
              type="text"
      
              class="tell"
              v-model="model.presenter_mobile"
              :class="
                errorMessage.presenter_mobile.length ? 'notvall' : 'isvall'
              "
            />
            <div
              v-for="(item, index) in errorMessage.presenter_mobile"
              :key="index"
            >
              <span class="message-error">{{ item }}</span>
            </div>
          </div> -->
          <button type="submit" class="submitligin btn my-3">ثبت عضویت</button>
          <div class="text-center">
            <p class="register-login">
              قبلا ثبت نام کرده اید؟! <router-link to="/login" class="btn">ورود</router-link>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      model: {
        name: "",
        mobile: "",
        // email: "",
        password: "",
        c_password: "",
        first_name: "",
        last_name: "",
        // father_name: "",
        // card_id: "",
        // national_id: "",
        // education: "",
        // field_of_study: "",
        // phone: "",
        // postalcode: "",
        // address: "",
        // gender: 0,
        // householders: 1,
        // birthdate: "",
        // presenter_name: "",
        // presenter_mobile: "",
      },
      errorMessage: {
        name: [],
        mobile: [],
        email: [],
        password: [],
        c_password: [],
        first_name: [],
        last_name: [],
        father_name: [],
        card_id: [],
        national_id: [],
        education: [],
        field_of_study: [],
        phone: [],
        postalcode: [],
        address: [],
        gender: [],
        householders: [],
        birthdate: [],
        presenter_name: [],
        presenter_mobile: [],
      },
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      this.errorMessage = {
        name: [],
        mobile: [],
        email: [],
        password: [],
        c_password: [],
        first_name: [],
        last_name: [],
        father_name: [],
        card_id: [],
        national_id: [],
        education: [],
        field_of_study: [],
        phone: [],
        postalcode: [],
        address: [],
        gender: [],
        householders: [],
        birthdate: [],
        presenter_name: [],
        presenter_mobile: [],
      };
     

        this.$http.post("register", this.model).then(
          (response) => {
            console.log(response);
            if (response.status == 200 && response.body) {
              this.$cookie.set('ApiToken', response.body.token, 3);
              this.$cookie.set("FullName", this.model.name, 3);
              this.$store.commit('Login')
              this.$router.push({ name: "index" });
            }
          },
          (data) => {
                    console.log(data);
            if (data.status == 400 && data.body) {
              if (data.body.errors.name)
                this.errorMessage.name = data.body.errors.name;
              if (data.body.errors.mobile)
                this.errorMessage.mobile = data.body.errors.mobile;
              if (data.body.errors.email)
                this.errorMessage.email = data.body.errors.email;
              if (data.body.errors.password)
                this.errorMessage.password = data.body.errors.password;
              if (data.body.errors.c_password)
                this.errorMessage.c_password = data.body.errors.c_password;
              window.scrollTo(0,0); 
            } else if (data.status == 200 && data.body) {
              this.$cookie.set('ApiToken', data.body.token, 3);
              this.$cookie.set("FullName", this.model.name, 3);
              this.$store.commit('Login')
              this.$router.push({ name: "index" });
            }
          }
        );
    },
    onChangeUser: function () {
      this.errorMessage.name = [];

      if (!this.model.name) {
        this.errorMessage.name.push("پر کردن نام کاربری اجباری است");
      }
    },
    onChangePhone: function () {
      this.errorMessage.mobile = [];

      if (!this.model.mobile) {
        this.errorMessage.mobile.push("پر کردن تلفن همراه اجباری است");
      }

      if (!this.$Regex.CheckPhone(this.model.mobile)) {
        this.errorMessage.mobile.push("تلفن وارد شده معتبر نیست");
      }
    },
    onChangeEmail: function () {
      this.errorMessage.email = [];
      if (!this.model.email) {
        this.errorMessage.email.push("پر کردن ایمیل اجباری است");
      }
      if (!this.$Regex.CheckEmail(this.model.email)) {
        this.errorMessage.email.push("ایمیل وارد شده معتبر نیست");
      }
    },
    onChangeCard_id: function () {
      this.errorMessage.card_id = [];

      if (!this.model.card_id) {
        this.errorMessage.card_id.push("پر کردن تلفن همراه اجباری است");
      }

      if (!this.$Regex.CheckCard_id(this.model.card_id)) {
        this.errorMessage.card_id.push("تلفن وارد شده معتبر نیست");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formregister {
  margin: 100px auto 100px auto;
  width: 400px;
  max-width: 100%;
  background: #f6f6f6;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 30px;
  .titr-register {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #303030;
  }
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #303030;
  }
  .username {
    width: 340px;
    height: 50px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid #afafaf;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #303030;
  }
  .tell {
    width: 340px;
    max-width: 100%;
    height: 50px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid #afafaf;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #303030;
  }

  .input-group-text {
    background: #fff;
    border: 1px solid #afafaf;
    border-width: 1px 0px 1px 1px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    i {
      font-size: 12px;
      color: #afafaf;
    }
  }
  .form-control {
    height: 50px;
    border: 1px solid #afafaf;
    border-width: 1px 1px 1px 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #303030;
    &:focus {
      box-shadow: none;
    }
  }
  .submitligin {
    background: #3bcf93;
    border: 1px solid #3bcf93;
    box-sizing: border-box;
    border-radius: 5px;
    width: 340px;
    max-width: 100%;
    height: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
  }
  .register-login {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    margin: 0;
    text-align: center;
    color: #303030;
    .btn {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #4d5dd4;
      padding: 0px;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .isvall {
    // background-image: url("../../assets/img/login/Vector.png");
    // background-repeat: no-repeat;
    // background-position: left calc(0.375em + 0.1875rem) center;
    background-size: 15px auto;
    border: 1px solid #3bcf93 !important;
  }
  .notvall {
    border: 1px solid #f44336 !important;
  }
  .notvall-1 {
    border: 1px solid #f44336 !important;
    border-width: 1px 1px 1px 0px !important;
  }
  .notvall-2 {
    border: 1px solid #f44336 !important;
    border-width: 1px 0px 1px 1px !important;
  }
  .message-error {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #f44336;
    padding: 0px 0px 0px 0px;
    // visibility: hidden;
  }
}
</style>
