<template>
  <div>
    <div class="container contprofile py-5">
      <div class="row">
        <menu-profile></menu-profile>
        <div class="col-md-6 col-lg-8">
          <div class="pt-3">
            <div class="card border-1 rounded-0">
              <div class="row px-3">
                <div class="col-lg-7 col-md-12 col-sm-12 img-project p-0">
                  <img
                    :src="model.images.cover"
                    class="w-100 h-100"
                    :alt="model.info.title"
                    v-if="
                      model != null &&
                      model.images != null &&
                      model.images.cover
                    "
                  />
                </div>
                <div
                  class="
                    col-lg-5 col-md-12 col-sm-12
                    cardSingleProject
                    text-center
                  "
                >
                  <div class="card-body pt-4">
                    <h2>{{ model.info.title }}</h2>
                    <!-- <p class="py-2">{{ model.owner.profile.full_name }}</p> -->
                    <div
                      class="alert alert-secondary"
                      v-if="messagePay"
                      role="alert"
                    >
                      {{ messagePay }}
                    </div>
                    <div class="giftMoney">
                      <div v-if="check1" class="gift">
                        <div class="checking-gift-2 topTitleProject">
                          <ul class="listInfoProject">
                            <li>
                              <i class="fal fa-trophy-alt"></i>

                              <span style="">موضوع فرعی : </span>
                              <span class="number">{{model.info.subject}}</span>
                            </li>
                            <li>
                              <i class="fal fa-trophy-alt"></i>

                              <span style="">رده شغلی : </span
                              ><span class="number">{{model.info.job_type}}</span>
                            </li>
                          </ul>
                          <router-link :to="`/project/create/${model.id}`" class="btn btn-block rounded-lg mt-1">
                            اصلاح و تکمیل اطلاعات پروژه
                          </router-link>
                        </div>
                      </div>
                      <div v-else-if="check2" class="money">
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <button class="btn" type="button">
                              شماره سپرده مبدا
                            </button>
                          </div>
                          <input
                            type="text"
                            v-model="resalat.source_account"
                            class="form-control"
                          />
                        </div>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <button class="btn" type="button">
                              تاریخ انتقال
                            </button>
                          </div>
                          <date-picker
                            clearable
                            class=""
                            v-model="resalat.date"
                          />
                          <!-- <input type="text"  /> -->
                        </div>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <button class="btn" type="button">
                              مبلغ انتقال
                            </button>
                          </div>
                          <input
                            type="text"
                            v-model="resalat.price"
                            class="form-control"
                          />
                        </div>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <button class="btn" type="button">کد رهگیری</button>
                          </div>
                          <input
                            type="text"
                            v-model="resalat.receipt"
                            class="form-control"
                          />
                        </div>
                        <button
                          v-on:click="SendResalat()"
                          class="btn btn-block rounded-lg"
                        >
                          پرداخت و ثبت
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div
              class="progress mt-3 rounded-0 flex-row-reverse"
              :style="{ height: '10px' }"
            >
            
              <div
                :style="{
                  width:
                  $Utility.GetPercent(model.finance.collected, model.finance.loan_need)
                     + '%',
                }"
                class="progress-bar progress-bar-striped"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>

            <div class="row mt-2 pricesingle">
              <div class="col-sm">
                <p class="m-0">
                  تامین شده: {{ formatPrice(model.finance.collected) }} تومان
                </p>
              </div>
              <div class="col-sm text-right">
                <p class="m-0">
                  {{
                    $Utility.GetPercent(model.finance.collected, model.finance.loan_need)
                  }}%
                </p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="topnav">
              <button
                :class="activeMenu == 1 ? 'active' : ''"
                v-on:click="ChangeMenu(1)"
              >
                شرح پروژه
              </button>
              <button
                :class="activeMenu == 2 ? 'active' : ''"
                v-on:click="ChangeMenu(2)"
              >
                اطلاعات کامل پروژه
              </button>
              <button
                :class="activeMenu == 3 ? 'active' : ''"
                v-on:click="ChangeMenu(3)"
              >
                حامیان پروژه
              </button>
              <button
                :class="activeMenu == 4 ? 'active' : ''"
                v-on:click="ChangeMenu(4)"
              >
                تاریخچه وضعیت
              </button>
              <button
                :class="activeMenu == 5 ? 'active' : ''"
                v-on:click="ChangeMenu(5)"
              >
                گزارشات
              </button>
              <button
                :class="activeMenu == 6 ? 'active' : ''"
                v-on:click="ChangeMenu(6)"
              >
                نظرات کاربران
              </button>
            </div>
          </div>

          <div class="container-fluid p-0">
            <div class="line"></div>
          </div>

          <div class="mb-3" v-if="activeMenu == 1">
            <div class="container">
              <div class="informationProject">
                <div class="row my-3 px-2" v-html="model.info.desc"></div>
              </div>
            </div>
          </div>
          <div class="mb-3" v-if="activeMenu == 2">
            <div class="container">
              <div class="informationProject">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <div>
                        <h5>شناسه و تاریخ</h5>
                        <div class="lineinfo"></div>
                      </div>

                      <ul class="listInfoProject">
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span style="">شناسه پروژه</span
                          ><span class="number">{{ model.id }}</span>
                        </li>
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span style="">تاریخ ایجاد</span
                          ><span class="number">{{ model.info.created }}</span>
                        </li>
                        <li style="">
                          <i class="fal fa-trophy-alt"></i>

                          <span style="">زمان شروع</span
                          ><span class="number">{{
                            model.info.start_date
                          }}</span>
                        </li>
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span>زمان خاتمه</span
                          ><span class="number">{{ model.info.end_date }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h5>مشخصات مالک</h5>
                      <div class="lineinfo"></div>
                    </div>
                    <div>
                      <div class="col-12 d-flex p-0 topprojectmanag py-2">
                        <img
                          width="60px"
                          :src="model.owner.avatar"
                          v-if="model.owner && model.owner.avatar"
                        />
                        <img
                          width="60px"
                          src="http://karvam.ir/noimage.jpg"
                          v-else
                        />
                        <div v-if="model.owner != null && model.owner.profile != null">
                          <span v-if="model.owner.profile.gender"
                            >جناب آقای</span
                          >
                          <span v-else>سرکار خانم</span>
                          <p>{{ model.owner.profile.full_name }}</p>
                        </div>
                      </div>
                      <ul class="listInfoProject">
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span>سن</span
                          ><span class="number"
                            >{{ model.owner.profile.age }} سال</span
                          >
                        </li>
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span> تحصیلات</span
                          ><span class="number">{{
                            model.owner.profile.education
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h5>مشخصات معرف (امین)</h5>
                      <div class="lineinfo"></div>
                    </div>
                    <div class="col-12 d-flex p-0 topprojectmanag py-2">
                      <img
                        width="60px"
                        :src="model.advisor.avatar"
                        v-if="model.advisor != null && model.advisor.avatar != null"
                      />
                      <img width="60px" src="http://karvam.ir/noimage.jpg" v-else/>
                      <div v-if="model.advisor != null && model.advisor.profile != null">
                        <span v-if="model.advisor.profile != null && model.advisor.profile.gender"
                          >جناب آقای</span
                        >
                        <span v-else>سرکار خانم</span>
                        <p>{{ model.advisor.profile.full_name }}</p>
                      </div>
                    </div>
                    <div>
                      <ul class="listInfoProject">
                        <li v-if=" model.advisor != null && model.advisor.profile != null">
                          <i class="fal fa-trophy-alt"></i>

                          <span> تحصیلات</span
                          ><span class="number">{{
                            model.advisor.profile.education
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h5>موضوع و رده شغلی پروژه</h5>
                      <div class="lineinfo"></div>
                    </div>
                    <div>
                      <ul class="listInfoProject">
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span>موضوع اصلی </span
                          ><span class="number">{{
                            model.info.master_subject
                          }}</span>
                        </li>
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span style="">موضوع فرعی</span
                          ><span class="number">{{ model.info.subject }}</span>
                        </li>
                        <li style="">
                          <i class="fal fa-trophy-alt"></i>

                          <span style=""> رده شغلی</span
                          ><span class="number">{{ model.info.job_type }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h5>اطلاعات جغرافیایی طرح</h5>
                      <div class="lineinfo"></div>
                    </div>
                    <div>
                      <ul class="listInfoProject">
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span> استان محل اجرا </span
                          ><span class="number">{{ model.info.state }}</span>
                        </li>
                        <li>
                          <i class="fal fa-trophy-alt"></i>
                          <span style=""> شهر محل اجرا</span
                          ><span class="number">{{ model.info.city }}</span>
                        </li>
                        <li style="">
                          <i class="fal fa-trophy-alt"></i>

                          <span style="">منطقه اجرایی پروژه </span
                          ><span class="number">{{ model.info.area }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h5>اطلاعات جغرافیایی طرح</h5>
                      <div class="lineinfo"></div>
                    </div>
                    <div>
                      <ul class="listInfoProject">
                        <li>
                          <i class="fal fa-trophy-alt"></i>
                          <span>آدرس دقیق محل اجرا </span
                          ><span class="number">{{ model.info.address }}</span>
                        </li>
                        <li style="">
                          <i class="fal fa-trophy-alt"></i>

                          <span style=""> کدپستی</span
                          ><span class="number">{{
                            model.info.postalcode
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h5>اطلاعات مالی پروژه</h5>
                      <div class="lineinfo"></div>
                    </div>
                    <div>
                      <ul class="listInfoProject">
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span> هزینه کامل اجرا: </span
                          ><span class="number"
                            >{{ model.info.total_expence }} تومان</span
                          >
                        </li>
                        <li>
                          <i class="fal fa-trophy-alt"></i>

                          <span style="">وام مورد نیاز: </span
                          ><span class="number"
                            >{{ model.info.loan }} تومان</span
                          >
                        </li>
                        <li style="">
                          <i class="fal fa-trophy-alt"></i>

                          <span style=""> تعداد اقساط برگشتی: </span
                          ><span class="number"
                            >{{ model.info.installment_no }} قسط</span
                          >
                        </li>
                        <!--
                          <li>
                            <i class="fal fa-trophy-alt"></i>

                            <span> مدت تنفس بازپرداخت اقساط: </span>
                            <span class="number">6 ماه</span>
                          </li>
                          <li>
                            <i class="fal fa-trophy-alt"></i>

                            <span>داکیومنت هزینه های پروژه:</span>
                            <span class="number">دانلود</span>
                          </li> -->
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h5>اطلاعات تکمیلی</h5>
                      <div class="lineinfopro"></div>
                    </div>
                    <div class="infosingleproject">
                      <p>
                        <i class="fal fa-trophy-alt"></i>
                        <span>مزیت رقابتی پروژه:</span>
                        {{ model.info.executive_features }}
                      </p>
                    </div>
                    <div class="infosingleproject">
                      <p>
                        <i class="far fa-users"></i>
                        <span>مشتریان پروژه چه کسانی هستند؟</span>
                        {{ model.info.customer }}
                      </p>
                    </div>
                    <div class="infosingleproject">
                      <p>
                        <i class="fal fa-trophy-alt"></i>
                        <span> نحوه تبلیغات و ضمانت محصولات پروژه: </span>
                        {{ model.info.advertise }}
                      </p>
                    </div>
                    <div class="infosingleproject">
                      <p>
                        <i class="fal fa-trophy-alt"></i>
                        <span> تعداد ایجاد کار و غیر مستقیم پروژه: </span>
                        {{ model.info.direct_job }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3" v-if="activeMenu == 3">
            <div class="container">
              <div class="row">
                <div class="col-6">
                  <div class="row" v-if="model.advisor != null">
                    <div class="col-md-5">
                      <div class="hexagon hexagon-with-border">
                        <div class="hexagon-shape">
                          <div class="hexagon-shape-inner">
                            <div class="hexagon-shape-inner-2"></div>
                          </div>
                        </div>
                        <div class="hexagon-shape content-panel">
                          <div class="hexagon-shape-inner">
                            <div class="hexagon-shape-inner-2">
                              <img v-if="model.advisor != null"
                                :src="model.advisor.avatar"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="text-right supportPerson" v-if="model.advisor != null && model.advisor.profile != null">
                        <p v-if="model.advisor.profile.gender">جناب آقای</p>
                        <p v-else>سرکار خانم</p>
                        <h3>{{ model.advisor.profile.full_name }}</h3>
                        <!-- <div class="monysupport">
                            <p>مبلغ حمایت <span>12345679 تومان</span></p>
                            <p>نحوه حمیت <span>اعطای امتیاز بانک رسالت</span></p>
                          </div> -->
                      </div>
                    </div>
                  </div>
                  <p v-else>اطلاعاتی ثبت نشده است</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3" v-if="activeMenu == 4">
            <div class="container py-5">
              <div class="step" v-for="item in model.logs" :key="item.id">
                <div>
                  <div class="circle"><i class="fa fa-check"></i></div>
                </div>
                <div>
                  <div class="title">{{ item.action }}</div>
                  <p class="captionDate">{{ item.created_at }}</p>
                  <p class="captionProject">
                    {{ item.message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3" v-if="activeMenu == 5">
            <div class="lineRep"></div>
            <div class="container">
              <h2 class="py-5 w-100 text-center">
                گزارش های {{ model.info.title }}
              </h2>
              <div class="row py-3">
                <div class="col-md-4" v-for="item in report" :key="item.id">
                  <div class="card border-0">
                    <div style="max-height: 235px" class="overflow-hidden">
                      <img
                        class="card-img-top w-100"
                        :src="item.cover_image_url"
                        :alt="item.title"
                      />
                    </div>

                    <div class="card-body">
                      <h5 class="card-title">{{ item.title }}</h5>
                      <p class="card-text">
                        <small class="text-muted">{{ item.created_at }}</small>
                      </p>
                      <p class="card-text">
                        {{ item.desc }}
                      </p>
                      <!-- <div class="text-right moreReorts">
                          <router-link to="/">
                            مطالعه بیشتر <i class="fal fa-long-arrow-left"></i>
                            </router-link>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3" v-if="activeMenu == 6">
            <div class="container">
              <div
                class="commentusers my-4"
                v-for="item in commnets"
                :key="item.id"
              >
                <p class="nameuser">{{ item.user }}</p>
                <span>{{ item.created_at }}</span>
                <p class="textcomment">
                  {{ item.body }}
                </p>
              </div>
              <div class="sendcomment py-4">
                <form>
                  <div class="alert alert-warning" role="alert" v-if="message">
                    {{ message }}
                  </div>
                  <div class="form-group py-3">
                    <label for="exampleFormControlTextarea1"
                      >ارسال دیدگاه</label
                    >
                    <textarea
                      placeholder="دیدگاه خودرا وارد نمایید..."
                      class="form-control"
                      v-model="body"
                      rows="3"
                    ></textarea>
                  </div>
                  <div
                    class="form-check form-check-inline"
                    style="margin-right: 23px"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="gavanin"
                    />
                    <label class="form-check-label py-3" for="inlineCheckbox1">
                      <router-link to="/gavanin" style="color: #f44336">
                        قوانین و مقررات</router-link
                      >
                      ارسال دیدگاه در سایت را مطالعه کرده و آن را می پذیرم.
                    </label>
                  </div>
                  <button
                    class="btn btn-succses"
                    type="button"
                    style="float: left"
                    v-on:click="sendComment()"
                  >
                    ثبت دیدگاه
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu from "../Shared/MenuProfile";

export default {
  components: {
    "menu-profile": menu,
  },
  data() {
    return {
      supported: {
      },
      resalat: {
      },
      isList: true,
      model: {
        
      },
      hami: [],
      commnets: [],
      report: [],
      check1: true,
      check2: false,
      activeMenu: 1,
      gavanin: false,
      error: null,
      message: null,
      body: null,
      messagePay: null,
    };
  },
  created() {
    this.$http.get("case/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          console.log(response.body.data);
          this.model = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.model = data.body.data;
        }
      }
    );
    this.$http.get(`case/${this.$route.params.id}/report`).then(
      (response) => {
        if (response.status == 200) {
          this.report = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.report = data.body.data;
        }
      }
    );
    this.$http.get("comment/case/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          this.commnets = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.commnets = data.body.data;
        }
      }
    );

    this.$http.get("case/" + this.$route.params.id + "/benefactor").then(
      (response) => {
        if (response.status == 200) {
          this.hami = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.hami = data.body.data;
        }
      }
    );
  },
  methods: {
    SendSupported: function () {
      if (!this.$cookie.get("ApiToken")) {
        this.messagePay =
          "شما در حساب کاربری خود نیستید لطفا به حساب کاربری خود ورود کنید";
        return;
      }
      this.$http.post("pay/case/" + this.$route.params.id, this.supported).then(
        (response) => {
          if (response.status == 200) {
            this.model = response.body.data;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.model = data.body.data;
          }
        }
      );
    },
    SendResalat: function () {
      this.$http
        .post("pay/case/" + this.$route.params.id + "/resalat", this.resalat)
        .then(
          (response) => {
            if (response.status == 200) {
              this.model = response.body.data;
            }
          },
          (data) => {
            if (data.status == 200) {
              this.model = data.body.data;
            }
          }
        );
    },
    sendComment: function () {
      this.error = null;
      this.message = "درحال ثبت لطفا منتظر بمانید!";
      if (!this.gavanin) {
        this.error = "لطفا قوانین را مطالعه و تایید کنید";
        return;
      }
      this.$http
        .post("comment/case/" + this.$route.params.id, { body: this.body })
        .then(
          (response) => {
            if (response.status == 200 || response.status == 201) {
              this.message = "نظر شما با موفقیت ثبت شد";
              this.gavanin = false;
              this.body = null;
            } else {
              this.message = "متاسفانه نظر شما ثبت نشد";
            }
          },
          (data) => {
            if (data.status == 200 || data.status == 201) {
              this.message = "نظر شما با موفقیت ثبت شد";
              this.gavanin = false;
              this.body = null;
            } else {
              this.message = "متاسفانه نظر شما ثبت نشد";
            }
          }
        );
    },
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    ChangeMenu: function (item) {
      this.activeMenu = item;
    },
    chengeCheck1: function () {
      if (this.check1 === false) {
        this.check2 = true;
      } else {
        this.check2 = false;
      }
    },
    chengeCheck2: function () {
      if (this.check2 === false) {
        this.check1 = true;
      } else {
        this.check1 = false;
      }
    },
  },
  metaInfo() {
    return {
      title: this.model.info.title,
      meta: [
        {
          name: "description",
          content:
            "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
        },
        {
          property: "og:title",
          content: "Epiloge - Build your network in your field of interest",
        },
        {
          property: "og:site_name",
          content: "Epiloge",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.contprofile {
  margin-top: 130px;
}
ul li {
  list-style: none;
}
.title-info {
  width: 100%;
  font-weight: bold;
  margin: 25px auto;
}
.box-project-info {
  margin-top: 10px;
  ul {
    li {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding: 5px 0;
      &:after {
        font-size: 85%;
        content: "ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ";
        text-indent: -1px;
        display: block;
        position: absolute;
        right: 0;
        bottom: 7px;
        z-index: -1;
        color: #e5e5e5;
      }
      span {
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        text-align: right;
        padding: 0 5px;
        background-color: #fff;
        line-height: 23px;
        letter-spacing: 0.02em;
      }
      .title {
        font-weight: 800;
        color: #3bcf93;
      }
      .info {
        float: left;
        font-weight: 600;
      }
    }
  }
  .btn-project-info {
    text-align: center;
    a {
      text-decoration: none;
      padding: 10px 25px;
      background: #3bcf93;
      border-radius: 5px;
      color: #fff;
      &:hover {
        border: 1px solid #4d5dd4;
      }
    }
  }
}
.profileImg {
  .hexagon {
    position: relative;
    display: inline-block;
    width: auto;
    height: 115px;
  }
  .hexagon .hexagon-shape {
    position: absolute;
    right: -75px;
    bottom: -60px;
    overflow: hidden;
    display: inline-block;
    width: 240px;
    height: 207px;
    transform: rotate(-30deg) skewX(30deg) scale(0.5);
    border-radius: 20px;
  }
  .hexagon .hexagon-shape *,
  .hexagon .hexagon-shape *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
  .hexagon .hexagon-shape .hexagon-shape-inner {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    opacity: 1;
  }
  .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
  .hexagon .hexagon-shape .hexagon-shape-inner-2 {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: #4d5dd4;
    content: "";
  }

  .hexagon .hexagon-content .content-sub {
    color: #9f9fa0;
  }
  .hexagon.hexagon-with-border .hexagon-shape.content-panel {
    transform: rotate(-30deg) skewX(30deg) scale(0.48);
  }

  .hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
  .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
    background: #eef9fe;
  }
  .hexagon.info .contentTitle {
    color: #54c6f3;
  }
  .hexagon-shape-inner-2 {
    img {
      transform: rotate(-61deg) skewX(30deg) scale(1.2);
    }
  }
}
.profileData {
  label {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    color: #afafaf;
  }
  .nameSupporter {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
  }
  ul {
    padding-right: 0;
    li {
      span {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
      }
      .info {
        letter-spacing: 0.02em;
        color: #3bcf93;
        padding-right: 4px;
      }
    }
  }
}
.box-project-date {
  .step {
    position: relative;
    min-height: 1em;
    color: gray;
  }
  .step + .step {
    margin-top: 1.5em;
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-right: 1.5em;
    padding-right: 1em;
  }
  .step.step-active {
    color: #3bcf93;
  }
  .step.step-active .circle {
    background-color: #3bcf93;
  }

  .circle {
    background: #3bcf93;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 12px #fff;
    i {
      padding-top: 5px;
    }
  }

  .circle-waiting {
    background: #4d5dd4;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 12px #fff;
  }

  .circle-off {
    background: #afafaf;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 12px #fff;
  }
  .circle-error {
    background: #f44336;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 12px #fff;
  }

  .step:after {
    content: " ";
    position: absolute;
    display: block;
    top: 20px;
    right: 12px;
    height: 100%;
    width: 2px;
    background-color: #3bcf93;
    z-index: -1;
  }
  .step-waiting:after {
    content: " ";
    position: absolute;
    display: block;
    top: 20px;
    right: 12px;
    height: 100%;
    width: 2px;
    background-color: #4d5dd4;
    z-index: -1;
  }

  .step-off:after {
    content: " ";
    position: absolute;
    display: block;
    top: 20px;
    right: 12px;
    height: 100%;
    width: 2px;
    background-color: #afafaf;
    z-index: -1;
  }
  .step-error:after {
    content: " ";
    position: absolute;
    display: block;
    top: 20px;
    right: 12px;
    height: 100%;
    width: 2px;
    background-color: #f44336;
    z-index: -1;
  }
  .step:last-child .circle:after {
    display: none;
  }
  .title {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #3bcf93;
    &.waiting {
      color: #4d5dd4;
    }
    &.off {
      color: #afafaf;
    }
    &.error {
      color: #f44336;
    }
  }
  .captionDate {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #afafaf;
    margin: 0;
    padding: 20px 0 7px 0;
  }

  .captionProject {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    margin: 0;
  }
}
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;

.hexagon {
  //position: relative;
  display: inline-block;
  width: 196px;
  height: 300px;
}
.hexagon .hexagon-shape {
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  display: inline-block;
  margin: 48px 0;
  width: 240px;
  height: 207px;
  transform: rotate(-30deg) skewX(30deg) scale(1);
  border-radius: 18px;
}
.hexagon .hexagon-shape *,
.hexagon .hexagon-shape *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}
.hexagon .hexagon-shape .hexagon-shape-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  opacity: 1;
}
.hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
.hexagon .hexagon-shape .hexagon-shape-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  background: #4d5dd4;
  content: "";
}

.hexagon .hexagon-content .content-sub {
  color: #9f9fa0;
}
.hexagon.hexagon-with-border .hexagon-shape.content-panel {
  transform: rotate(-30deg) skewX(30deg) scale(0.97);
}

.hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
.hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
  background: #eef9fe;
}
.hexagon.info .contentTitle {
  color: #54c6f3;
}
.hexagon-shape-inner-2 {
  img {
    transform: rotate(-61deg) skewX(30deg) scale(1.2);
  }
}

.supportPerson {
  padding-top: 95px;
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #afafaf;
    margin-bottom: 10px;
  }
  h3 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #303030;
  }
  .monysupport {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #303030;
      margin-bottom: 10px;
    }
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #3bcf93;
    }
  }
}

.informationProject {
  h5 {
    padding-top: 30px;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #3bcf93;
    padding-left: 5px;
    background-color: #fff;
    display: inline-block;
  }
  .lineinfo {
    width: 94%;
    height: 2px;
    background-color: #3bcf93;
    position: relative;
    bottom: 17px;
    z-index: -1;
    right: 0px;
  }
  .lineinfopro {
    width: 94%;
    height: 2px;
    background-color: #3bcf93;
    position: relative;
    bottom: 17px;
    z-index: -1;
    right: 24px;
  }
  .listInfoProject {
    padding: 0px;

    li {
      position: relative;
      overflow: hidden;
      width: 330px;
      list-style: none;
      padding: 4px 0px;

      &:after {
        font-size: 85%;
        content: "ـــــــــــــــــــــــــــــــــــــــــــــــــــــ";
        text-indent: -1px;
        display: block;
        position: absolute;
        right: 0px;
        bottom: 7px;
        z-index: -1;
        color: #e5e5e5;
      }
      i {
        padding-left: 5px;
        display: inline-block;
        background: #fff;
      }
      span {
        display: inline-block;
        background-color: #fff;
        padding-left: 5px;
        font-family: IRANSansWeb;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #303030;
      }
      .number {
        float: left;
        font-weight: bold;
        padding-right: 5px;
        font-family: IRANSansWeb;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #303030;
      }
    }
  }
}

.topprojectmanag {
  img {
    border-radius: 50%;
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    padding-right: 10px;
  }
  p {
    margin: 0px;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    padding-right: 10px;
  }
}
.infosingleproject {
  i {
    padding-left: 5px;
  }
  p {
    text-align: justify;
    padding-left: 26px;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #303030;
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #4d5dd4;
    }
  }
}
.img-project {
  .btnimg {
    background-color: #4d5dd4;
    color: #fff;
    padding: 24px 30px;
    position: absolute;
    bottom: 0px;
    right: 0;
  }
  .btnvideo {
    background-color: $themeColorGreen;
    color: #fff;
    padding: 24px 30px;
    position: absolute;
    bottom: 0px;
    right: 78px;
  }
}

.topTitleProject {
  .listInfoProject {
    padding: 0px;

    li {
      position: relative;
      overflow: hidden;
      width: 250px;
      list-style: none;
      padding: 4px 0px;

      &:after {
        font-size: 85%;
        content: "ـــــــــــــــــــــــــــــــــــــــــــــــــ";
        text-indent: -1px;
        display: block;
        position: absolute;
        right: 0px;
        bottom: 7px;
        z-index: -1;
        color: #e5e5e5;
      }
      i {
        background-color: #3bcf93;
        padding-left: 5px;
        float: right;
        display: inline-block;
      }
      span {
        display: inline-block;
        float: right;
        padding-left: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        line-height: 23px;
        text-align: right;
        letter-spacing: 0.02em;
        background-color: #3bcf93;
      }
      .number {
        float: left;
        padding-right: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #ffffff;
        background-color: #3bcf93;
      }
    }
  }
}

.cardSingleProject {
  background-color: $themeColorGreen;
  color: #fff;
  z-index: 10;
  h2 {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 34px;
    color: #ffffff;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 23px;
    color: #fff;
  }

  .participationGift {
    background-color: #388a69;
    width: 100%;
    padding: 8px 40px;
    position: absolute;
    left: 0;
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: #fff;
      padding-right: 10px;
    }
    .checking {
      display: table-cell;
      position: relative;
      left: 0px;
      bottom: 0px;

      cursor: pointer;
      font-size: 22px;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          background-color: #fff;
          &:after {
            display: block;
          }
        }
      }
      .checkmark {
        &:after {
          left: 9px;
          top: 3px;
          width: 6px;
          height: 14px;
          border: solid #388a69;
          border-width: 0px 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 5px;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  .giftMoney {
    margin-top: 8rem;
    .gift {
      a {
        background: rgba(56, 138, 105, 0.8);
        border: 1px solid #388a69;
        color: #fff;
        font-weight: 700;
        padding: 10px 18px;
        &:focus {
          box-shadow: none;
        }
      }
      input {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid #388a69;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 5px;
        padding: 0px 7px 0px 45px;
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #388a69;
        &:focus {
          box-shadow: none;
        }
      }
      .toman {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #388a69;
        position: absolute;
        z-index: 99;
        left: 10px;
        top: 10px;
      }
      .checking-gift-1 {
        .checking-gift {
          display: table-cell;
          position: relative;
          right: 24px;
          top: 20px;
          cursor: pointer;
          font-size: 22px;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark-gift {
              background-color: #fff;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark-gift {
          &:after {
            left: 9px;
            top: 3px;
            width: 6px;
            height: 14px;
            border: solid #388a69;
            border-width: 0px 2px 2px 0;
            transform: rotate(45deg);
          }
        }
        .checkmark-gift {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 5px;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
        .giftperson-1 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          text-align: right;
          color: #ffffff;
          position: relative;
          top: 18px;
          left: 84px;
        }
      }
      .checking-gift-2 {
        .checking-gift {
          display: table-cell;
          position: relative;
          right: 24px;
          top: 40px;
          cursor: pointer;
          font-size: 22px;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark-gift {
              background-color: #fff;
              &:after {
                display: block;
              }
            }
          }
        }
        .checkmark-gift {
          &:after {
            left: 9px;
            top: 3px;
            width: 6px;
            height: 14px;
            border: solid #388a69;
            border-width: 0px 2px 2px 0;
            transform: rotate(45deg);
          }
        }
        .checkmark-gift {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: transparent;
          border: 1px solid #fff;
          border-radius: 5px;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
        .giftperson-2 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          color: #ffffff;
          position: relative;
          top: 39px;
          left: 64px;
        }
        .paymentRegistration-1 {
          margin-top: 99px;

          .btn {
            font-family: IRANSansWeb;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            text-align: right;
            letter-spacing: 0.02em;
            background: #388a69;
            border-radius: 5px;
            color: #fff;
            padding: 11px 145px;
          }
        }
      }
    }
    .money {
      button {
        background: rgba(56, 138, 105, 0.8);
        border: 1px solid #388a69;
        border-radius: 0px 5px 5px 0px;
        color: #fff;
        &:focus {
          box-shadow: none;
        }
      }
      input {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid #388a69;
        box-sizing: border-box;
        border-radius: 5px 0px 0px 5px;
        padding: 0px 7px 0px 10px;
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #388a69;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .paymentRegistration-2 {
      margin-top: 16px;
      .btn {
        font-family: IRANSansWeb;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: right;
        letter-spacing: 0.02em;
        background: #388a69;
        border-radius: 5px;
        color: #fff;
        padding: 11px 145px;
      }
    }
  }
  .paragraphProject {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 26px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #ffffff;
  }
  .btnsuportproject {
    background-color: #388a69;
    border-radius: 5px;
    color: #fff;
    padding: 0 90px;
    margin-top: 50px;
  }
}
.pricesingle {
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #3bcf93;
  }
}

.listSingle {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 5px;
  width: 255px;
  .listSingle-Title {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #afafaf;
  }
  .listSingle-Topic {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #303030;
  }
}

.topnav {
  overflow: hidden;

  button {
    float: right;
    outline: none;
    display: block;
    color: #afafaf;
    text-align: center;
    padding: 14px 12px;
    text-decoration: none;
    font-size: 17px;
    border: none;
    border-bottom: 3px solid #f1f1f1;
    background: transparent;
    &:hover {
      border-bottom: 3px solid #4d5dd4;
    }
  }
  .active {
    border-bottom: 3px solid #4d5dd4;
    color: #4d5dd4;
  }
}
.line {
  width: 100%;
  height: 3px;
  background-color: #f1f1f1;
  position: relative;
  bottom: 3px;
  z-index: -99999;
}
.step {
  position: relative;
  min-height: 1em;
  color: gray;
}
.step + .step {
  margin-top: 1.5em;
}
.step > div:first-child {
  position: static;
  height: 0;
}
.step > div:not(:first-child) {
  margin-right: 1.5em;
  padding-right: 1em;
}
.step.step-active {
  color: #3bcf93;
}
.step.step-active .circle {
  background-color: #3bcf93;
}

.circle {
  background: #3bcf93;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 12px #fff;
  i {
    padding-top: 5px;
  }
}

.step:after {
  content: " ";
  position: absolute;
  display: block;
  top: 20px;
  right: 12px;
  height: 100%;
  width: 2px;
  background-color: #3bcf93;
  z-index: -1;
}
.step:last-child .circle:after {
  display: none;
}

.title {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #3bcf93;
}
.captionDate {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #afafaf;
  margin: 0px;
  padding: 20px 0px 7px 0px;
}

.captionProject {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #303030;
  margin: 0px;
}

.waiting {
  .circle {
    background: #eceeff;
    border: 1px solid #4d5dd4;
    color: #4d5dd4;
  }
  .title {
    color: #4d5dd4;
  }
  &::after {
    background-color: #4d5dd4;
  }
}

.waitingSupply {
  .circle {
    background: #afafaf;
    color: #fff;
  }
  .title {
    color: #afafaf;
  }
  p {
    color: #afafaf;
  }
  &::after {
    background-color: #afafaf;
  }
}
.reject {
  .circle {
    background: #f44336;
    color: #fff;
  }
  .title {
    color: #f44336;
  }
  p {
    color: #f44336;
  }
  &::after {
    background-color: #f44336;
  }
}
.titrReorts {
  h1 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }

  .lastUpdate {
    p {
      padding-left: 10px;
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.02em;
    }
  }
}
.description {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }
}

.secDescription {
  background-image: url("../../../assets/img/reports/Vector-1.png");
  background-repeat: no-repeat;
  background-position: 99% 9px;
  border-right: 3px solid #3bcf93;

  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 188.22%;
    letter-spacing: 0.02em;
    color: #303030;
    padding-right: 60px;
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 60px;
  }
}

.cardDescription {
  padding: 60px 0px 60px 0px;
}
.itemsDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;

    padding-bottom: 15px;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 13px;
    i {
      font-size: 10px;
    }
  }
}

.lineRep {
  width: 100%;
  height: 2px;
  background: #f1f1f1;
}

.lastReports {
  h2 {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 122.32%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3bcf93;
  }
  .card-title {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-align: right;
    color: #212121;
  }
  .card-text {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140.1%;
    text-align: right;
    color: #616161;
  }
  .moreReorts {
    a {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: right;
      letter-spacing: 0.02em;

      color: #5552d3;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.commentusers {
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  .nameuser {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    margin-bottom: 5px;
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #afafaf;
  }

  .textcomment {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
    padding-top: 20px;
  }
}

.morecomment {
  a {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #3bcf93;
  }
}

form {
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  text-align: right;
  input,
  textarea {
    &:focus {
      background: rgba(59, 207, 147, 0.1);
      border: 1px solid #3bcf93;

      letter-spacing: 0.02em;
      color: #3bcf93;
    }
  }
  input ~ .checkmark {
    background-color: #ccc;
  }
  button {
    color: #fff;
    background-color: #3bcf93;
  }
}
.proDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }
}

.proItemDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;

    padding-bottom: 15px;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 13px;
    i {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .informationProject .listInfoProject li {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .cardSingleProject .giftMoney .gift .checking-gift-1 .giftperson-1,
  .cardSingleProject .giftMoney .gift .checking-gift-2 .giftperson-2 {
    left: auto;
  }
}
</style>