<template>
  <div>
    <app-header></app-header>
    <div class="container py-5">
      <div class="alert alert-success" v-if="message" role="alert">
        <div v-html="message"></div>
      </div>
      <div class="alert alert-danger" v-if="errorMessage" role="alert">
        <div v-html="errorMessage"></div>
      </div>
      <div class="step" id="step-1" :class="step == 1 ? '' : 'stepdeactiv'">
        <step1
          v-on:Message="Message"
          v-on:license_id="license_id"
          v-on:ErrorMessage="ErrorMessage"
          v-on:GoTo="GoTo"
        ></step1>
      </div>
      <div class="step" id="step-2" :class="step == 2 ? '' : 'stepdeactiv'">
        <step2
          :id="id"
          v-on:setId="getId"
          v-on:editUrl="editUrl"
          v-on:Message="Message"
          v-on:ErrorMessage="ErrorMessage"
          v-on:GoTo="GoTo"
          :model="model.step2"
        ></step2>
      </div>
      <div class="step" id="step-3" :class="step == 3 ? '' : 'stepdeactiv'">
        <step3
          :province="province"
          :city="city"
          :id="id"
          :model="model.step3"
          v-on:Message="Message"
          v-on:ErrorMessage="ErrorMessage"
          v-on:GoTo="GoTo"
        ></step3>
      </div>
      <div class="step" id="step-4" :class="step == 4 ? '' : 'stepdeactiv'">
        <step4
          :id="id"
          :model="model.step4"
          v-on:Message="Message"
          v-on:ErrorMessage="ErrorMessage"
          v-on:GoTo="GoTo"
        ></step4>
      </div>
      <div class="step" id="step-5" :class="step == 5 ? '' : 'stepdeactiv'">
        <step5
          :id="id"
          :model="model.step5"
          v-on:Message="Message"
          v-on:ErrorMessage="ErrorMessage"
          v-on:GoTo="GoTo"
        ></step5>
      </div>
      <div class="step" id="step-6" :class="step == 6 ? '' : 'stepdeactiv'">
        <step6
          :avatar="model.step6.avatar"
          :id="id"
          v-on:Message="Message"
          v-on:ErrorMessage="ErrorMessage"
          v-on:GoTo="GoTo"
        ></step6>
      </div>
    </div>
  </div>
</template>
<script>
import header from "./Components/Header";

import step1 from "./Components/step1.vue";
import step2 from "./Components/step2.vue";
import step3 from "./Components/step3.vue";
import step4 from "./Components/step4.vue";
import step5 from "./Components/step5.vue";
import step6 from "./Components/step6.vue";
export default {
  components: {
    "app-header": header,
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
  },
  data() {
    return {
      isEdit: false,
      sectionObserver: null,
      province: [],
      city: [],

      message: null,
      errorMessage: null,
      step: 1,
      isSubmit: false,
      id: null,
      model: {
        state: null,
        city: null,
        step2: {
          license_id: "",
          title: "",
          start_time: "",
          end_time: "",
          desc: "",
          _method: "post",
        },
        step3: {
          _method: "post",
          state: 0,
          city: 0,
          area: "",
          postal_code: null,
          address: "",
        },
        step4: {
          advisor_id: 0,
        },
        step5: {
          installment_no: 0,
          total_expense: 0,
          loan: 0,

          expierDate: "",
          installment_type: 0,
        },
        step6:{
          avatar:null
        },
        subject: 0,
        job_type: 0,
        installment_respite: 0,
        direct_job: 0,
        indirect_job: 0,
        executive_features: "",

        _method: "post",
      },
    };
  },
  created() {
    var index = location.hash.substr(location.hash.length-1,1);
    if(parseInt(index) > 0 && parseInt(index) < 7){
      this.step = parseInt(index);
    }
    this.$http.get("enum/province").then(
      (response) => {
        if (response.status == 200) {
          this.province = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.province = data.body;
        }
      }
    );
    this.$http.get("case/create").then(
      (response) => {
        if (response.status != 200) {
          this.ErrorMessage(response.body.message);
        }
      },
      (data) => {
        if (data.status != 200) {
          this.ErrorMessage(data.body.message);
        }
      }
    );

    if (this.$route.params.id != null) {
      this.isEdit = true;
      this.id = this.$route.params.id;

      this.$http.get("case/" + this.$route.params.id).then(
        (response) => {
          if (response.status == 200) {
            if (response.body.data.advisor != null) {
              this.model.step4.advisor_id = response.body.data.advisor.id;
            }
            if(response.body.data.images != null && response.body.data.images.cover != null){
              this.model.step6.avatar = response.body.data.images.cover;
            }
            var model = response.body.data.info;
            this.model.step2.title = model.title;
            this.model.step2.desc = model.desc;
            this.model.step2.start_time = model.start_time;
            this.model.step2.end_time = model.end_time;
            this.model.step3.area = model.area;
            this.model.step3.postal_code = model.postal_code;
            this.model.step3.address = model.address;
            this.model.step5.installment_no = model.installment_no;
            this.model.step5.total_expense = model.total_expense;
            this.model.step5.expierDate = model.expierDate;
            this.model.step5.loan = model.loan;
            this.model.step5.installment_type = model.installment_type;
            // this.model.state = model.state;
            // this.model.subject = model.subject;
            // this.model.job_type = model.job_type;
            // this.model.installment_respite = model.installment_respite;
            // this.model.direct_job = model.direct_job;
            // this.model.indirect_job = model.indirect_job;
            // this.model.executive_features = model.executive_features;
            this.model.step3.state = this.province.find(
              (item) => item.name == model.state
            ).id;
            if (this.model.step3.state != null) {
              this.$http.get("enum/province/" + this.model.step3.state).then(
                (response) => {
                  if (response.status == 200) {
                    this.city = response.body.cities;
                    this.model.step3.city = this.city.find(
                      (item) => item.name == model.city
                    ).id;
                  }
                },
                (data) => {
                  if (data.status == 200) {
                    this.city = data.body.cities;
                    this.model.step3.city = this.city.find(
                      (item) => item.name == model.city
                    ).id;
                  }
                }
              );
            }
          }
        },
        (data) => {
          console.log(data);
        }
      );
    }
  },
  mounted() {
    this.observeSections();
  },
  methods: {
    Message(text) {
      this.$alert(text,null,"success");
    },
    ErrorMessage(text) {
      console.log(text);
      this.$alert(text,null,"error");
    },
    license_id(id) {
      this.model.step2.license_id = id;
    },
    IsSubmit(b) {
      console.log(b);
      this.IsSubmit = b;
    },
    editUrl(id){
      this.$router.push({
        name: "create-project",
        params: { id: id },
      });
    },
    getId(id) {
      this.id = id;
    },
    observeSections() {
      try {
        this.sectionObserver.disconnect();
      } catch (error) {
        console.log(error);
      }

      const options = {
        rootMargin: "0px",
        threshold: 1,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll(".step");
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          // const sectionId = entry.target.id;
          // // Push sectionId to router here
          // this.$router.push({ name: this.$route.name, hash: `#${sectionId}` });
        }
      }
    },

    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.profileImg.backavatar = vm.profileImg.avatar;
        vm.profileImg.avatar = e.target.result;
        vm.profileImg.isChange = true;
      };
      reader.readAsDataURL(file);
    },

    failed: function (_file, _message, xhr) {
      console.log(xhr.response);
      const message = JSON.parse(xhr.response).message;
      console.log(message);
      const elements = document.querySelectorAll(".dz-error-message span");
      const lastElement = elements[elements.length - 1];
      lastElement.textContent = message;
    },
    GoTo: function (step) {
      if (this.$route.params.id != null) {
        this.step = step;
        // this.$router.push({ name: this.$route.name, hash: `#step-${step}` });
      } else if (this.isSubmit) {
        this.step = step;
        // this.$router.push({ name: this.$route.name, hash: `#step-${step}` });
      } else if (step < 3) {
        this.step = step;
        // this.$router.push({ name: this.$route.name, hash: `#step-${step}` });
      }
    },

    provinceChange: function (event) {
      this.city = [];
      this.$http.get("enum/province/" + event.target.value).then(
        (response) => {
          if (response.status == 200) {
            this.city = response.body.counties;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.city = data.body.counties;
          }
        }
      );
    },
    prevStep: function () {
      this.step--;
    },
    nextStep: function () {
      this.step++;
    },
    submit: function () {
      this.model.license_id = this.license.id;
      this.message = null;
      this.errorMessage = null;
      if (this.$route.params.id != null) {
        this.nextStep();
        this.isSubmit = true;
        this.edit();
      } else {
        this.$http.post("case", this.model).then(
          (response) => {
            if (response.status == 200) {
              window.scrollTo(0, 0);
              this.message = response.body.message;
              this.id = response.body.data.id;
              this.$refs.album.dropzone.options.url = `http://karvam.ir/api/case/${response.body.data.id}/album`;
              this.$refs.video.dropzone.options.url = `http://karvam.ir/api/case/${response.body.data.id}/video`;
              // this.dropzoneOptions.url = `http://karvam.ir/api/case/${response.body.data.id}/album`;
              // this.videoOptions.url = `http://karvam.ir/api/case/${response.body.data.id}/video`;
              this.isSubmit = true;
              this.nextStep();
              console.log(this.videoOptions);
              this.$router.push({
                name: this.$route.name,
                hash: `#step-3`,
                params: { id: response.body.data.id },
              });
              // this.$router.replace({
              //   path: `/project/create/${response.body.data.id}`,
              // });
            } else {
              this.errorMessage = response.data.message;
            }
          },
          (data) => {
            if (data.status == 200) {
              window.scrollTo(0, 0);
              this.message = data.body.message;
            } else {
              window.scrollTo(0, 0);
              this.errorMessage = data.data.message;
            }
          }
        );
      }
    },

    edit: function () {
      this.model.license_id = this.license.id;
      this.message = null;
      this.errorMessage = null;
      this.model._method = "put";
      this.$http.post(`case/${this.id}`, this.model).then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.message = response.body.message;
            this.nextStep();
          } else {
            this.errorMessage = response.data.message;
          }
        },
        (data) => {
          if (data.status == 200) {
            window.scrollTo(0, 0);
            this.message = data.body.message;
          } else {
            window.scrollTo(0, 0);
            this.errorMessage = data.data.message;
          }
        }
      );
    },
  },
};
</script>
<style lang="scss">
html {
  scroll-behavior: smooth;
}
.profileImg {
  .hexagon {
    position: relative;
    display: inline-block;
    width: auto;
    height: 115px;
  }

  .hexagon .hexagon-shape {
    position: absolute;
    right: 28px;
    bottom: -40px;
    overflow: hidden;
    display: inline-block;
    width: 240px;
    height: 207px;
    transform: rotate(-30deg) skewX(30deg) scale(0.5);
    border-radius: 20px;
  }

  .hexagon .hexagon-shape *,
  .hexagon .hexagon-shape *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }

  .hexagon .hexagon-shape .hexagon-shape-inner {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    opacity: 1;
  }

  .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
  .hexagon .hexagon-shape .hexagon-shape-inner-2 {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: #4d5dd4;
    content: "";
  }

  .hexagon .hexagon-content .content-sub {
    color: #9f9fa0;
  }

  .hexagon.hexagon-with-border .hexagon-shape.content-panel {
    transform: rotate(-30deg) skewX(30deg) scale(0.48);
  }

  .hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
  .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
    background: #eef9fe;
  }

  .hexagon.info .contentTitle {
    color: #54c6f3;
  }

  .hexagon-shape-inner-2 {
    img {
      transform: rotate(-61deg) skewX(30deg) scale(1.2);
    }
  }
}
.wrapper-x {
  position: absolute;
  bottom: 20px;
  right: calc(134px - 0.25rem);
  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  button {
    background: #4d5dd4;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    outline: none;
    padding: 5px;
    position: relative;
    vertical-align: middle;
    .fa,
    .fas {
      vertical-align: middle;
      font-weight: 500;
    }
    &.btn-2 {
      background-color: #4d5dd4;
      border-radius: 50%;
      overflow: hidden;
    }
    &.btn-1 {
      background-color: #ff7976;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
.wrapper {
  position: absolute;
  bottom: 5px;
  right: calc(138px - 0.25rem);
  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  label {
    background: #4d5dd4;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    outline: none;
    padding: 5px;
    position: relative;
    vertical-align: middle;
    .fa,
    .fas {
      vertical-align: middle;
      font-weight: 500;
    }
    &.btn-2 {
      background-color: #4d5dd4;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.vue-dropzone {
  font-family: IRANSansWeb !important;
}
.vue-dropzone > .dz-preview .dz-remove {
  left: 0 !important;
  width: calc(100% - 30px) !important;
}
.step {
  .title {
    button {
      font-weight: bold;
      font-size: 20px;
      line-height: 33px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #4d5dd4;
      background: none;
      border: none;

      &:focus {
        outline: none;
      }
      .cicle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #4d5dd4;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
        margin: -10px;
        padding: 2px;
        border: 8px solid #fff;
      }

      .title-step {
        padding: 0px 10px 0px 10px;
        margin: 0;
      }
    }
  }

  .body-step {
    border-right: 3px solid #4d5dd4;
    margin: 0px 18px;
    padding: 24px 25px;
  }
}

.stepdeactiv {
  .title {
    button {
      color: #afafaf;
      .cicle {
        background: #afafaf;
      }
    }
  }

  .body-step {
    border-right: 3px solid #afafaf;
    // height: 0px;

    .body-stepone,
    .body-steptwo,
    .body-stepthree,
    .body-stepfour,
    .body-stepfive,
    .body-stepsix,
    .body-stepseven,
    .body-stepeight,
    .body-stepnine,
    .body-stepten {
      display: none;
      transition: all 5s;
    }
  }
}

.step {
  .body-stepone {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #303030;
    }

    ol {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #303030;
    }

    .agreement {
      .agree {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }

      .cancel {
        width: 110px;
        height: 50px;
        background: #f44336;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-right: 10px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.step {
  .body-steptwo {
    .dropzone {
      min-height: 100px;
      padding: 0px;
    }

    .vue-dropzone {
      background: #ffffff;
      border: 1px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      .dropzone-custom-content {
        h3 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          span {
            color: #4d5dd4;
          }
        }
        p {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: #9e9e9e;
        }
      }
    }
  }
  .stepnext {
    .next {
      width: 110px;
      height: 50px;
      background: #3bcf93;
      border-radius: 5px;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #fff;
      margin-left: 10px;

      &:focus {
        box-shadow: none;
      }
    }
    .back {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #f44336;
      border-bottom: 1px solid #f44336;
      border-radius: 0px;
      padding: 0px;
      margin: 10px;
    }
  }
}

.step {
  .body-stepthree {
    .stepnext {
      .next {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }
      .back {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #f44336;
        border-bottom: 1px solid #f44336;
        border-radius: 0px;
        padding: 0px;
        margin: 10px;
      }
    }
  }
}
.step {
  .body-stepfour {
    .dropzone {
      min-height: 100px;
      padding: 0px;
    }

    .vue-dropzone {
      background: #ffffff;
      border: 1px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      .dropzone-custom-content {
        h3 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          span {
            color: #4d5dd4;
          }
        }
        p {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: #9e9e9e;
        }
      }
    }
    .stepnext {
      .next {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }
      .back {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #f44336;
        border-bottom: 1px solid #f44336;
        border-radius: 0px;
        padding: 0px;
        margin: 10px;
      }
    }
  }
}
.step {
  .body-stepten {
    .dropzone {
      min-height: 100px;
      padding: 0px;
    }

    .vue-dropzone {
      background: #ffffff;
      border: 1px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      .dropzone-custom-content {
        i {
          font-size: 50px;
          color: #3bcf93;
          padding-bottom: 20px;
        }
        h3 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          span {
            color: #4d5dd4;
          }
        }
        p {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: #9e9e9e;
        }
      }
    }
    .stepfinal {
      .final {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }
      .back {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #f44336;
        border-bottom: 1px solid #f44336;
        border-radius: 0px;
        padding: 0px;
        margin: 10px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body-step .form-group .row {
    display: inline-block;
    width: 100%;
    margin: 0;
    .col {
      padding: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
