<template>
  <div class="col-md-6 col-lg-4">
    <div class="profileTools">
      <div class="d-flex justify-content-between exitNotif">
        <button v-on:click="SignOut()">
          <i class="fal fa-sign-out-alt"></i>
        </button>
        <!-- <button v-on:click="SignOut()"><i class="fal fa-bell"></i></button> -->
      </div>
      <div class="profileImg">
        <div class="hexagon hexagon-with-border">
          <div class="hexagon-shape">
            <div class="hexagon-shape-inner">
              <div class="hexagon-shape-inner-2"></div>
            </div>
          </div>
          <div class="hexagon-shape content-panel">
            <div class="hexagon-shape-inner">
              <div class="hexagon-shape-inner-2">
                <img
                  v-if="
                    data != null && data.avatar != null && data.avatar != ''
                  "
                  :src="data.avatar"
                  :alt="data.profile.full_name"
                />
                <img v-else src="http://karvam.ir/noimage.jpg" alt="" />
              </div>
            </div>
          </div>
          <form v-if="!isChange">
            <div class="wrapper">
              <input type="file" id="file" v-on:change="onFileChange" />
              <label for="file" class="btn-2">
                <i class="fa fa-plus mx-1"></i>
              </label>
            </div>
          </form>
          <div class="wrapper-x" v-else>
            <button class="btn-2" v-on:click="submit" title="ارسال تصویر">
              <i class="fas fa-file-import mx-1"></i>
            </button>
            <button class="btn-1 my-2" v-on:click="removeImage" title="حذف عکس">
              <i class="far fa-trash-alt mx-1"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="nameUser">
        <p>{{ data.profile.full_name }}</p>
      </div>
      <div class="editUser text-center py-2">
        <router-link to="/profile/user/edit">ویرایش اطلاعات</router-link>
      </div>
      <div class="d-flex border">
        <div class="mr-auto p-2 price">{{ formatPrice(amount) }} تومان</div>
        <div class="p-2">
          <router-link title="افزایش اعتبار" to="/profile/payment" class="btn btn-outline-success">
            <i class="fas fa-plus-circle m-0"></i>
          </router-link>
        </div>
        <div class="p-2">
          <router-link title="تقاضای وجه" to="/profile/request" class="btn btn-outline-danger">
            <i class="fas fa-donate"></i>
          </router-link>
        </div>
      </div>
      <button
        class="d-block d-md-none btn btn-outline-success btn-block"
        v-on:click="ShowMenu()"
        v-html="btnText"
      ></button>
      <div :class="{ 'd-none d-md-block': isShowMenu }">
        <div class="profileBtn pt-2">
          <div>
            <router-link to="/profile/dashboard" class="d-flex mb-3 btn p-2">
              <div class="">
                <i class="fal fa-tachometer-alt-average iconBtn"></i>
              </div>
              <div class="px-2 txtBtn">داشبورد</div>
            </router-link>
          </div>
          <div>
            <router-link to="/profile/user/edit" class="d-flex mb-3 btn p-2">
              <div class="">
                <i class="fal fa-user iconBtn"></i>
              </div>
              <div class="px-2 txtBtn">مشخصات من</div>
            </router-link>
          </div>
          <div>
            <router-link to="/profile/projects" class="d-flex mb-3 btn p-2">
              <div class="">
                <i class="fal fa-file-alt iconBtn"></i>
              </div>
              <div class="px-2 txtBtn">پروژه های من</div>
            </router-link>
          </div>
          <div>
            <router-link to="/profile/contribution" class="d-flex mb-3 btn p-2">
              <div class="">
                <i class="fal fa-handshake-alt iconBtn"></i>
              </div>
              <div class="px-2 txtBtn">مشارکت های من</div>
            </router-link>
          </div>
          <div>
            <a href="#" v-on:click="menOpen" class="d-flex mb-3 btn p-2">
              <div>
                <i class="fal fa-coins iconBtn"></i>
              </div>
              <div class="px-2 txtBtn">امور مالی</div>
              <div class="ml-auto">
                <i
                  v-if="menu.open === false"
                  class="far fa-chevron-left arrow"
                ></i>
                <i v-else class="far fa-chevron-down arrow"></i>
              </div>
            </a>
            <ul class="has-menu" v-show="menu.open">
              <li>
                <router-link to="/profile/Gift-transactions">
                  تراکنش‌های اهدایی و اعتباری
                </router-link>
              </li>
              <li>
                <router-link to="/profile/Qarz-transactions">
                  تراکنش‌های قرض الحسنه
                </router-link>
              </li>
              <li>
                <router-link to="/profile/Definite-transactions">
                  تراکنش‌های قطعی
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="protectionBtn">
          <div>
            <router-link
              to="/profile/karvam"
              class="d-flex justify-content-center mb-3 btn p-2"
            >
              <div class="px-2 txtBtn">حمایت از کاروام</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amount: 1013710000,
      file: null,
      isChange: false,
      isShowMenu: true,
      btnText: 'نمایش فهرست <i class="fas fa-angle-down mx-2"></i>',
      menu: {
        open: false,
      },
      data: {
        profile: {
          full_name: "",
        },
        backavatar: "",
        avatar: "http://karvam.ir/noimage.jpg",
      },
    };
  },
  created() {
    this.$http
      .get("user/info?get=id,avatar,profile.full_name,wallet.amount")
      .then(
        (response) => {
          if (response.status == 200) {
            this.data = response.body.data;
            this.amount = response.body.wallet.amount;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.data = data.body.data;
          }
        }
      );
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    ShowMenu() {
      if (!this.isShowMenu) {
        this.btnText = 'نمایش فهرست <i class="fas fa-angle-down mx-2"></i>';
      } else {
        this.btnText = 'بستن فهرست <i class="fas fa-angle-up mx-2"></i>';
      }
      this.isShowMenu = !this.isShowMenu;
    },
    menOpen: function (event) {
      event.preventDefault();
      this.menu.open = this.menu.open === false;
    },
    SignOut: function () {
      this.$http.post("logout").then(
        (response) => {
          if (response.status == 200) {
            this.$cookie.delete("ApiToken");
            this.$cookie.delete("FullName");
            this.$store.commit("LogOut");
            this.$router.push({ name: "index" });
          }
        },
        (data) => {
          if (data.status == 200) {
            this.$cookie.delete("ApiToken");
            this.$cookie.delete("FullName");
            this.$store.commit("LogOut");
            this.$router.push({ name: "index" });
          }
        }
      );
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
      this.createImage(files[0]);
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.data.backavatar = vm.data.avatar;
        vm.data.avatar = e.target.result;
        vm.isChange = true;
      };
      reader.readAsDataURL(file);
    },
    submit() {
      let fd = new FormData();
      fd.append("avatar", this.file);
      console.log(this.file);
      this.$http.post(`user/${this.data.id}/image`, fd).then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.isChange = false;
          }
        },
        (data) => {
          console.log(data);
          // if (data.status == 200) {
          //   this.data = data.body.data;
          // }
        }
      );
    },
    removeImage: function () {
      this.isChange = false;
      this.data.avatar = this.data.backavatar;
    },
  },
};
</script>

<style lang="scss" scoped>
.price{
  line-height: 37px;
}
.wrapper {
  position: absolute;
  bottom: 5px;
  right: calc(138px - 0.25rem);
  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  label {
    background: #4d5dd4;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    outline: none;
    padding: 5px;
    position: relative;
    vertical-align: middle;
    .fa,
    .fas {
      vertical-align: middle;
      font-weight: 500;
    }
    &.btn-2 {
      background-color: #4d5dd4;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
.wrapper-x {
  position: absolute;
  bottom: 20px;
  right: calc(134px - 0.25rem);
  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  button {
    background: #4d5dd4;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    outline: none;
    padding: 5px;
    position: relative;
    vertical-align: middle;
    .fa,
    .fas {
      vertical-align: middle;
      font-weight: 500;
    }
    &.btn-2 {
      background-color: #4d5dd4;
      border-radius: 50%;
      overflow: hidden;
    }
    &.btn-1 {
      background-color: #ff7976;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
///////////////////
* {
  transition: all ease-in-out 0.25s;
}
a {
  text-decoration: none;
}
ul li {
  list-style: none;
}

.has-menu {
  a {
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #616161;
    line-height: 2;
    &:hover {
      color: #3bcf93;
    }
  }
}

.dropdownMenu {
  &.open-has-menu {
    .has-menu {
      display: block;
      transition: all ease-in-out 2ms;
    }
  }
}

.profileTools {
  overflow: hidden;
  //width: 350px;
  //height: 706px;
  transition: all 0.5s;
  background: #f1f1f1;
  border-radius: 10px;
  padding: 25px;
  margin: 0 auto 30px auto;

  .exitNotif {
    button {
      color: #616161;
      border: none;
      &:hover {
        text-decoration: none;
      }

      i {
        font-size: 20px;
      }

      .fa-sign-out-alt {
        color: #f44336;
      }
    }
  }

  .profileImg {
    .hexagon {
      position: relative;
      display: inline-block;
      width: auto;
      height: 115px;
    }

    .hexagon .hexagon-shape {
      position: absolute;
      right: 28px;
      bottom: -40px;
      overflow: hidden;
      display: inline-block;
      width: 240px;
      height: 207px;
      transform: rotate(-30deg) skewX(30deg) scale(0.5);
      border-radius: 20px;
    }

    .hexagon .hexagon-shape *,
    .hexagon .hexagon-shape *:before {
      display: block;
      overflow: hidden;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }

    .hexagon .hexagon-shape .hexagon-shape-inner {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      opacity: 1;
    }

    .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
    .hexagon .hexagon-shape .hexagon-shape-inner-2 {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      background: #4d5dd4;
      content: "";
    }

    .hexagon .hexagon-content .content-sub {
      color: #9f9fa0;
    }

    .hexagon.hexagon-with-border .hexagon-shape.content-panel {
      transform: rotate(-30deg) skewX(30deg) scale(0.48);
    }

    .hexagon
      .hexagon-shape.content-panel:first-child
      .hexagon-shape-inner:before,
    .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
      background: #eef9fe;
    }

    .hexagon.info .contentTitle {
      color: #54c6f3;
    }

    .hexagon-shape-inner-2 {
      img {
        transform: rotate(-61deg) skewX(30deg) scale(1.2);
      }
    }
  }

  .nameUser {
    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #303030;
      margin: 0;
    }
  }

  .editUser {
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #afafaf;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .profileBtn {
    .has-menu {
      .router-link-active {
        color: #3bcf93 !important;
        background: none !important;
        font-weight: 700;
      }
    }
    .router-link-active {
      background: #3bcf93 !important;
      font-weight: 700;

      .txtBtn {
        color: #fff !important;
      }

      .iconBtn {
        color: #fff !important;
      }

      .arrow {
        color: #fff !important;
      }
    }
    .btn {
      background: #ffffff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 50px;
      align-items: center;

      .iconBtn {
        color: #616161;
        font-size: 20px;
      }

      .arrow {
        color: #616161;
      }

      .txtBtn {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #616161;
      }

      &:focus {
        box-shadow: none;
        background: #3bcf93;

        .txtBtn {
          color: #fff;
        }

        .iconBtn {
          color: #fff;
        }

        .arrow {
          color: #fff;
        }
      }

      &:hover {
        background: #3bcf93;

        .txtBtn {
          color: #fff;
        }

        .iconBtn {
          color: #fff;
        }

        .arrow {
          color: #fff;
        }
      }
    }
  }

  .protectionBtn {
    .btn {
      background: #4d5dd4;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 50px;
      align-items: center;

      .txtBtn {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #fff;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
  @media (max-width: 767px) {
    .profileImg {
      width: 297px;
      margin: auto;
    }
  }
}
</style>
