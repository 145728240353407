<template>
  <div class="container py-5">
    <div class="titleNews text-center">
      <h2>آخرين اخبار كاروام</h2>
      <p>
        <router-link to="/blog">
          مطالعه بیشتر
          <i class="fas fa-long-arrow-alt-left"></i>
        </router-link>
      </p>
    </div>
    <div class="pt-4 base">
         <hooper :settings="hooperSettings">
        <slide class="" v-for="item in list" :key="item.id">
          <div class="container rtl">
            <div class="row border">
              <div class="col-md-6 align-self-center py-3">
                <div class="text-center">
                  <img
                    :src="item.pictureURL"
                    style="max-width: 100%"
                    :alt="item.title"
                  />
                </div>
              </div>
              <div class="col-md-6 cardNews pt-3">
                <h3>
                  {{ item.title }}
                </h3>
                <div class="seenNews">
                  <i class="far fa-clock"></i>
                  <span>
                    {{ item.created_at }}
                  </span>
                  <i class="far fa-eye"></i>
                  <span> {{ item.views }} نفر </span>
                </div>
                <p v-html="item.summery"></p>
                <div class="moreNews pt-4 pr-3">
                  <p>
                    <router-link :to="'/blog/single/' + item.id"
                      >مطالعه بیشتر <i class="fas fa-long-arrow-alt-left"></i
                    ></router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </slide>
<hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide ,Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  data() {
    return {
      websiteName: "http://karvam.ir",
      list: [
        {
          created_at: "1399-12-28",
          enabled: 1,
          id: 9,
          pictureURL: "",
          summery:
            '<p>↵	<span style="font-family: &quot;B Mitra&quot;; font-size: 14pt; text-align: justify;">در جلسه روز چهارشنبه ۲۸ بهمن ماه، 18 فقره از پروژه‌های ثبت شده در کاروام ارزیابی و تعیین تکلیف شد. این پروژه‌ها برای ایجاد کار در زمینه‌های مختلف از جمله کشاورزی و دامداری، صنایع دستی، خدمات فنی مهندس و... اس...',
          title: "پرداخت ۵ میژه جدید",
          views: 26,
        },
        {
          created_at: "1399-12-28",
          enabled: 1,
          id: 8,
          pictureURL: "",
          summery:
            "<p>↵	هوالشاهد<br />شامگاه آخرین یکشنبه ماه ذی‌‌القعده و در آستانه شهادت حضرت جوادالائمه علیه السلام، روح مجاهد خستگی‌ناپذیر جبهه‌های فرهنگی، اقتصادی و اقتصادی؛<br />حاج مهدی حبیب‌پور در جوار رحمت حق آرام گرفت.<br />او که به گواهی همه‌ی هم‌رزمانش، در مسیر پاسداری از آرمان‌های انقلاب اسلامی در تمامی...",
          title: "حاج مهدی حام گرفت",
          views: 167,
        },
        {
          created_at: "1399-12-28",
          enabled: 1,
          id: 7,
          pictureURL: "",
          summery:
            '<p>↵	<br /> ↵</p>↵<p>↵</p>↵<p>↵	کاروام در نظر دارد برای حمایت از خانواده‌هایی که معیشت آنان بر اثر شیوع این بیماری دچار آسیب شده است، از <span style="color: rgb(0, 93, 194);"><strong>۲۰ ایده کارآفرینی خرد خانگی</strong></span> با اعطای <strong><span style="color: rgb(0, 142, 2);">وام قرض الحسن...',
          title: "حمایت فوری از ارآفرینی",
          views: 186,
        },
        {
          created_at: "1399-12-28",
          enabled: 1,
          id: 6,
          pictureURL: "",
          summery:
            "در گذر روزهایمان که به جبر دغدغه‌های روزانه، کمتر خبری از مناطق سیل زده سیستان و بلوچستان می‌شنویم؛ رسالت خویش را فراموش نکرده و توسط یکی از خیرین محترم اعتباری به مبلغ ۷۰۰ میلیون تومان برای ایجاد هفتاد پروژه کارآفرینی ده میلیون تومانی در مناطق سیل زده این استان، به کاروام اختصاص داده شد.",
          title: "جذب مشارکت اعتبار ۷۰۰  در مناطق سیل زده",
          views: 206,
        },
        {
          created_at: "1399-12-28",
          enabled: 1,
          id: 3,
          pictureURL: "",
          summery:
            'روحانی حوزه علمیه قم آقای صالحی چند سالی است که با ایجاد یک موسسه خیریه به نام "کاروام" سعی دارد از فعالیت های خرد اقتصادی با هزینه های پایین حمایت کند. این مجموعه با ارایه تسهیلات چندمیلیونی از کارآفرینهایی که نیاز به سرمایه اندک دارند؛ حمایت می کند.↵ایشان در پاسخ به سوال "طلبگی کجا و عرصه کارآفری...',
          title: "طلبگی مررینی وظیفه",
          views: 202,
        },
        {
          created_at: "1399-12-28",
          enabled: 1,
          id: 2,
          pictureURL: "",
          summery:
            "حجة الاسلام و المسلمین  صالحی خبر داد کاروام یک تجربه موفق در زمینه اعطای وام قرض الحسنه و احیای فرهنگ قرض الحسنه                   است ↵این سنت نیک باعث شده است که در سال جاری بسیاری از کارهای بزرگ انجام شود.",
          title: "در سال گذشته بیش از ۳۰۰کسب و کار از طدریافت کردند",
          views: 305,
        },
      ],
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        rtl: true,
        autoPlay: true,
        playSpeed: 2000,
      },
    };
  },
  created() {
    this.$http.get("news").then(
      (response) => {
        if (response.status == 200) {
          this.list = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.list = data.body.data;
        }
      }
    );
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
};
</script>

<style lang="scss" scoped>
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.hooper-navigation.is-rtl .hooper-prev {
  left: auto;
  right: -80px;
}
.hooper-navigation.is-rtl .hooper-prev svg {
  transform: scale(2.3);
}
.hooper-navigation.is-rtl .hooper-next {
  right: auto;
  left: -80px;
}
.hooper-navigation.is-rtl .hooper-next svg {
  transform: scale(2.3);
}
.hooper{
  height: auto !important;
}
.titleNews {
  background-image: url("../../../assets/img/news/Vector-5.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;

  h2 {
    font-family: KalamehBold;
    color: $themeColorDark;
    padding-top: 10px;
    &::before {
      content: url(../../../assets/img/support/Rectangle.png);
      position: relative;
      left: 10px;
    }
    &::after {
      content: url(../../../assets/img/support/Rectangle.png);

      position: relative;
      right: 10px;
    }
  }
  p {
    text-align: left;
    text-align: center;
    a {
      font-family: IRANSansWeb;
      color: $themeColorGreen;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: right;
      letter-spacing: 0.02em;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.cardNews {
  .seenNews {
    color: #afafaf;
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    i {
      margin-left: 5px;
    }
    span {
      margin-left: 10px;
    }
  }
  h3 {
    font-family: IRANSansWeb;
    color: $themeColorDark;
    font-weight: 900;
    padding: 15px 0px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    padding-top: 10px;
  }

  .moreNews {
    text-align: left;

    a {
      font-family: IRANSansWeb;
      color: $themeColorGreen;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: right;
      letter-spacing: 0.02em;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.slick-arrow {
  &::before {
    font-size: 30px;
    font-family: "Font Awesome 5 Pro";
    color: #e5e5e5 !important;
  }
  &:hover {
    &::before {
      color: #888888 !important;
    }
  }
}

.slick-prev {
  left: 50px;
  &::before {
    content: "\f053";
  }
}

.slick-next {
  right: 50px;
  &::before {
    content: "\f054";
  }
}
</style>