<template>
  <div>
    <div class="container alaki">
      <div class="row">
        <div class="col-md-8 py-3 post-blog">
          <div>
            <img :src="data.pictureURL" class="w-100" :alt="data.title" />
          </div>
          <div class="writer-blog pt-3">
            <h2>
              {{ data.title }}
            </h2>
            <dir class="details-blog">
              <span>
                <i class="fal fa-user"></i>
                نویسنده: {{ data.user.profile.full_name }}
              </span>
              <span>
                <i class="fal fa-calendar-alt"></i>
                {{ data.created_at }}
              </span>
              <span>
                <i class="far fa-eye"></i>
                {{ data.views }}
              </span>
              <div class="text-blog pt-3 text-justify" v-html="data.body"></div>
            </dir>
          </div>
          <div class="erasl-nazar">
            <div class="sendcomment">
              <div class="alert alert-danger" v-if="error">
                {{ error }}
              </div>
              <form>
                <div class="alert alert-warning" role="alert" v-if="message">
                  {{ message }}
                </div>
                <div class="form-group py-3">
                  <label for="exampleFormControlTextarea1">ارسال دیدگاه</label>
                  <textarea
                    placeholder="دیدگاه خودرا وارد نمایید..."
                    class="form-control"
                    v-model="body"
                    rows="3"
                  ></textarea>
                </div>
                <div
                  class="form-check form-check-inline"
                  style="margin-right: 23px"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="gavanin"
                  />
                  <label class="form-check-label py-3" for="inlineCheckbox1">
                    <router-link to="/gavanin" style="color: #f44336">
                      قوانین و مقررات
                    </router-link>
                    ارسال دیدگاه در سایت را مطالعه کرده و آن را می پذیرم.
                  </label>
                </div>
                <div class="d-flex flex-row-reverse py-4">
                  <button
                    class="btn btn-succses"
                    type="button"
                    v-on:click="sendComment()"
                  >
                    ثبت دیدگاه
                  </button>
                </div>
              </form>
            </div>
            <div v-for="item in comments" :key="item.id" class="w-100">
              <div class="commentusers my-4">
                <p class="nameuser">{{ item.user }}</p>
                <span>{{ item.created_at }}</span>
                <p class="textcomment">{{ item.body }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 py-3">
          <div class="post-Grouping">
            <div class="categor-post my-3">
              <p>آخرین خبرها</p>
            </div>
            <div
              class="card border-0 my-3"
              style="max-width: 500px"
              v-for="item in list"
              :key="item.id"
            >
              <div class="row no-gutters">
                <div class="col-sm-3">
                  <img :src="item.pictureURL" class="w-100" :alt="item.title" />
                </div>
                <div class="col-sm-9">
                  <div class="card-body">
                    <h5 class="card-title">
                      {{ item.title }}
                    </h5>
                    <p class="card-text">
                      <i class="fal fa-calendar-alt"></i>
                      {{ item.created_at }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        id: "",
        title: "",
        summery: "",
        enabled: "",
        views: "",
        created_at: "",
        pictureURL: "",
        body: "",
        pre_title: "",
        sub_title: "",
        reference: "",
        user: {
          id: 23,
          name: "jm_hodhod",
          email: "jm.hodhod@gmail.com",
          profile: {
            first_name: "جلال",
            last_name: "محرابی",
            full_name: "جلال محرابی",
            father_name: "احمد",
            card_id: "",
            national_id: "1271020033",
            education: "کارشناسی",
            field_of_study: "حوزوی",
            gender: 1,
            householders: "on",
            bank_account_no: "0",
            bank_sheba: "0",
            age: 2,
            presenter: {
              name: "ادمین",
              mobile: "09105291517",
            },
          },
          avatar: "",
        },
      },
      gavanin: false,
      error: null,
      message: null,
      body: null,
      comments: [],
    };
  },
  metaInfo() {
    return {
      title: this.data.title,
      meta: [
        {
          name: "description",
          content: this.data.summery,
        },
        {
          property: "og:title",
          content: this.data.title,
        },
        {
          property: "og:site_name",
          content: "Karvam.ir",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
  methods: {
    sendComment: function () {
      this.error = null;
      if (!this.gavanin) {
        this.error = "لطفا قوانین را مطالعه و تایید کنید";
        return;
      }
      this.$http
        .post("comment/news/" + this.$route.params.id, { body: this.body })
        .then(
          (response) => {
            if (response.status == 200 || response.status == 201) {
              this.message = "نظر شما با موفقیت ثبت شد";
              this.gavanin = false;
              this.body = null;
            } else {
              this.message = "متاسفانه نظر شما ثبت نشد";
            }
          },
          (data) => {
            if (data.status == 200 || data.status == 201) {
              this.message = "نظر شما با موفقیت ثبت شد";
              this.gavanin = false;
              this.body = null;
            } else {
              this.message = "متاسفانه نظر شما ثبت نشد";
            }
          }
        );
    },
  },
  created() {
    this.$http.get("news/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          this.data = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.data;
        }
      }
    );
    this.$http.get("news").then(
      (response) => {
        if (response.status == 200) {
          this.list = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.list = data.body.data;
        }
      }
    );
    this.$http.get("comment/news/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          this.comments = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.comments = data.body.data;
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.alaki {
  padding-top: 200px;
}

.post-blog {
  .tag {
    .btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 122.32%;
      color: #5552d3;
      background: #ffffff;
      border: 1px solid #5552d3;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px 15px;
    }
  }
  .writer-blog {
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 122.32%;
      color: #212121;
    }
    .details-blog {
      padding: 0;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #9e9e9e;
        padding: 0px 5px;
      }
    }
    .text-blog {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 0.02em;
        color: #616161;
      }
      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.02em;
        color: #212121;
      }
      ol {
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 0.02em;
        color: #616161;
      }
      .tag-post {
        .btn {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 122.32%;
          color: #9e9e9e;
          background: #ffffff;
          border: 1px solid #9e9e9e;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 10px 15px;
        }
      }
      .Content {
        .btn {
          font-weight: bold;
          font-size: 14px;
          color: #5552d3;
          padding: 20px 0px;
          i {
            font-size: 20px;
          }
        }
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          color: #212121;
        }
      }
    }
  }
}

.blog-Grouping {
  .categoryArticles {
    border-bottom: 1px solid #9e9e9e;
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      margin: 0;
      padding: 19px 5px 12px 5px;
      color: #616161;
    }
  }
  .card {
    border-bottom: 1px solid #e5e5e5 !important;
    button {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #5e5e5e;
    }
    .card-body {
      background-color: #fff;
      p {
        margin: 0;
        .btn {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 122.32%;
          letter-spacing: 0.02em;
          color: #afafaf;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .card-header {
      background-color: #fff;
      padding: 6px 0px 7px 0px;
      .btn {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .collapsible-link {
    width: 100%;
    position: relative;
    text-align: right;
    &::before {
      content: url("../../assets/img/blog/arrow1.png");
      position: absolute;
      top: 50%;
      left: 0.8rem;
      transform: translateY(-50%);
      display: block;
      font-size: 1.1rem;
    }
    &[aria-expanded="true"] {
      &::before {
        content: url("../../assets/img/blog/arrow2.png");
      }
    }
  }
}
.erasl-nazar {
  .commentusers {
    background-color: #f7f7f7;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    .nameuser {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #303030;
      margin-bottom: 5px;
    }
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #afafaf;
    }

    .textcomment {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #303030;
      padding-top: 20px;
    }
  }

  .morecomment {
    .btn {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #3bcf93;
      background: rgba(59, 207, 147, 0.15);
      border-radius: 10px;
    }
  }

  form {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    input,
    textarea {
      &:focus {
        background: rgba(59, 207, 147, 0.1);
        border: 1px solid #3bcf93;

        letter-spacing: 0.02em;
        color: #3bcf93;
      }
    }
    input ~ .checkmark {
      background-color: #ccc;
    }
    button {
      color: #fff;
      background-color: #3bcf93;
    }
  }
}
.post-Grouping {
  .categor-post {
    border-bottom: 1px solid #9e9e9e;
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      margin: 0;
      padding: 19px 5px 12px 5px;
      color: #616161;
    }
  }
  .card-body {
    padding: 6px;
    h5 {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      color: #212121;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #9e9e9e;
    }
  }
}
</style>