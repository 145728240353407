<template>
  <div class="fix-menu">
    <div class="container">
      <div class="pt-4">
        <div class="card card-bio">
          <div class="row">
            <div class="col-md-6">
              <div class="col-md">
                <div class="adviser">
                  <div class="hexagon hexagon-with-border">
                    <div class="hexagon-shape">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2"></div>
                      </div>
                    </div>
                    <div class="hexagon-shape content-panel">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2">
                          <img
                            src="../../assets/img/projectsingle/pic1.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="brief-biography">
                  <h3>احمد اکبر زاده</h3>
                  <h5>مدیرگرافیک هلدینگ آریانا</h5>
                  <div class="rate">
                    4.6
                    <span><i class="fas fa-star"></i></span>
                    <span><i class="fas fa-star"></i></span>
                    <span><i class="fas fa-star"></i></span>
                    <span><i class="fas fa-star"></i></span>
                    <span><i class="far fa-star"></i></span>
                  </div>
                  <p>
                    <i class="fal fa-hand-holding"></i>
                    تعداد مشاوره
                    <span> 80مورد </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 py-5 align-self-center">
              <div class="adviser-info px-5">
                <div class="d-flex justify-content-between">
                  <div id="line-info-1"></div>
                  <span class="py-2 pr-2"
                    ><i class="fal fa-calendar-exclamation"></i>سن:</span
                  >
                  <span class="py-2 pl-2">39 سال</span>
                </div>
                <div class="d-flex justify-content-between">
                  <div id="line-info-2"></div>

                  <span class="py-2 pr-2"
                    ><i class="far fa-graduation-cap"></i>تحصیلات:</span
                  >
                  <span class="py-2 pl-2">لیسانس</span>
                </div>
                <div class="d-flex justify-content-between">
                  <div id="line-info-3"></div>

                  <span class="py-2 pr-2"
                    ><i class="fal fa-map-marker-alt"></i>استان:</span
                  >
                  <span class="py-2 pl-2">خراسان رضوی</span>
                </div>
                <div class="d-flex justify-content-between">
                  <div id="line-info-4"></div>

                  <span class="py-2 pr-2"
                    ><i class="fal fa-map-marker-alt"></i>شهر:</span
                  >
                  <span class="py-2 pl-2">مشهد</span>
                </div>
                <p>
                  <i class="fal fa-trophy-alt"></i>کارآفرین برتر سایت کاروام در
                  سال 1399 از سوی کاربران!!
                </p>
                <p><i class="fal fa-trophy-alt"></i>مشاور برتر حوزه کشاورزی</p>
                <p>
                  <i class="fal fa-trophy-alt"></i>مالک برتر سامانه کاروام از
                  سوی کارشناسان!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0 py-5">
      <div class="container">
        <div class="topnav">
          <router-link class="active" to="/">بیوگرافی </router-link>
          <router-link to="/">پروژه های تحت مشاوره</router-link>
          <router-link to="/">پروژه های تحت مالکیت</router-link>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="container">
      <div class="proDescription py-4">
        <h2>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم</h2>
        <p>
          از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه
          روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
          تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
          باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
          جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای
          طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی
          ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در
          ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
          حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود
          طراحی اساسا مورد استفاده قرار گیرد.
        </p>
        <p>
          از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه
          روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
          تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
          باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
          جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای
          طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی
          ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در
          ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
          حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود
          طراحی اساسا مورد استفاده قرار گیرد.
        </p>
      </div>
      <div class="proItemDescription py-4">
        <h2>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم</h2>
        <p>
          <i class="fas fa-circle"></i>
          از صنعت چاپ، و با استفاده از طراحان گرافیک
        </p>
        <p>
          <i class="fas fa-circle"></i>
          مجله در ستون و سطر
        </p>
        <p>
          <i class="fas fa-circle"></i>
          برای شرایط فعلی تکنولوژی
        </p>
        <p>
          <i class="fas fa-circle"></i>
          کاربردهای متنوع با هدف بهبود
        </p>
      </div>
    </div>
    <div class="container">
      <div class="text-left listProject d-flex align-items-center">
        <button
          class="btnList"
          v-on:click="isList = true"
          v-bind:class="{ active: isList }"
        >
          <i class="fas fa-th gallery"></i>
        </button>
        <button
          class="btnList"
          v-on:click="isList = false"
          v-bind:class="{ active: !isList }"
        >
          <i class="fas fa-th-list list"></i>
        </button>
        <div class="form-group w-25 m-0 mr-3">
          <select
            class="form-control bg-light text-secondary"
            id="exampleFormControlSelect1"
          >
            <option>درحال تامین</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group w-25 m-0">
          <select
            class="form-control bg-light text-secondary"
            id="exampleFormControlSelect1"
          >
            <option>همه شهرها</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="wrap ml-auto">
          <div class="search">
            <input type="text" class="searchTerm" placeholder="جست و جو ..." />
            <button type="submit" class="searchButton">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center" v-if="isList">
      <div class="row">
        <div class="col-md-4 pt-4">
          <div class="card cardSlider cardUser border-1 rounded-0">
            <div>
              <span class="Deadline">مهلت تامین : 23 روز مانده</span>

              <img
                src="../../assets/img/Projects/Rectangle51.png"
                class="card-img-top rounded-0"
                alt="..."
              />
            </div>

            <div class="card-body text-left">
              <div class="mb-3 jobType">
                <span class="btn">رستوران</span>
                <div class="d-inline-block ml-3 snip">
                  <i class="fal fa-map-marker-alt mr-1"></i>
                  مشهد
                </div>
              </div>
              <h2>رستوران لاکچری ممد آقا</h2>
              <h3 class="user mt-2">توسط : ممد آقا غلامی</h3>

              <div class="jobCategory py-1">
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک ...
                </p>
              </div>

              <div class="d-flex justify-content-between price">
                <span>تامین شده: 16،000،000</span>
                <span>43%</span>
              </div>
              <div
                class="progress rounded-0 flex-row-reverse"
                style="height: 10px"
              >
                <div
                  :style="{ width: '43%' }"
                  class="progress-bar progress-bar-striped"
                  role="progressbar"
                  aria-valuenow="43"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="moneyNeeded mt-2">
                مبلغ مورد نیاز:
                <span style="color: #3bcf93">27،000،000 تومان</span>
              </p>
              <div class="text-right userBtn mt-4">
                <router-link class="btn">مشاهده و حمایت</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-4">
          <div class="card cardSlider cardUser border-1 rounded-0">
            <div>
              <span class="Deadline">مهلت تامین : 23 روز مانده</span>

              <img
                src="../../assets/img/Projects/Rectangle51.png"
                class="card-img-top rounded-0"
                alt="..."
              />
            </div>

            <div class="card-body text-left">
              <div class="mb-3 jobType">
                <router-link class="btn">رستوران</router-link>
                <div class="d-inline-block ml-3 snip">
                  <i class="fal fa-map-marker-alt mr-1"></i>
                  مشهد
                </div>
              </div>
              <h2>رستوران لاکچری ممد آقا</h2>
              <h3 class="user mt-2">توسط : ممد آقا غلامی</h3>

              <div class="jobCategory py-1">
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک ...
                </p>
              </div>

              <div class="d-flex justify-content-between price">
                <span>تامین شده: 16،000،000</span>
                <span>43%</span>
              </div>
              <div
                class="progress rounded-0 flex-row-reverse"
                style="height: 10px"
              >
                <div
                  :style="{ width: '43%' }"
                  class="progress-bar progress-bar-striped"
                  role="progressbar"
                  aria-valuenow="43"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="moneyNeeded mt-2">
                مبلغ مورد نیاز:
                <span style="color: #3bcf93">27،000،000 تومان</span>
              </p>
              <div class="text-right userBtn mt-4">
                <router-link class="btn">مشاهده و حمایت</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-4">
          <div class="card cardSlider cardUser border-1 rounded-0">
            <div>
              <span class="Deadline">مهلت تامین : 23 روز مانده</span>

              <img
                src="../../assets/img/Projects/Rectangle51.png"
                class="card-img-top rounded-0"
                alt="..."
              />
            </div>

            <div class="card-body text-left">
              <div class="mb-3 jobType">
                <router-link class="btn">رستوران</router-link>
                <div class="d-inline-block ml-3 snip">
                  <i class="fal fa-map-marker-alt mr-1"></i>
                  مشهد
                </div>
              </div>
              <h2>رستوران لاکچری ممد آقا</h2>
              <h3 class="user mt-2">توسط : ممد آقا غلامی</h3>

              <div class="jobCategory py-1">
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک ...
                </p>
              </div>

              <div class="d-flex justify-content-between price">
                <span>تامین شده: 16،000،000</span>
                <span>43%</span>
              </div>
              <div
                class="progress rounded-0 flex-row-reverse"
                style="height: 10px"
              >
                <div
                  :style="{ width: '43%' }"
                  class="progress-bar progress-bar-striped"
                  role="progressbar"
                  aria-valuenow="43"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p class="moneyNeeded mt-2">
                مبلغ مورد نیاز:
                <span style="color: #3bcf93">27،000،000 تومان</span>
              </p>
              <div class="text-right userBtn mt-4">
                <router-link class="btn">مشاهده و حمایت</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination py-5">
        <router-link to="/">&raquo;</router-link>
        <router-link to="/" class="active">1</router-link>
        <router-link to="/">2</router-link>
        <router-link to="/">3</router-link>
        <router-link to="/">&laquo;</router-link>
      </div>
    </div>
    <div class="container text-center" v-else>
      <div class="pt-4">
        <div class="card listCard border-1 rounded-0">
          <div class="row">
            <div class="col-6">
              <span class="Deadline">مهلت تامین : 23 روز مانده</span>
              <img
                src="../../assets/img/Projects/Rectangle51.png"
                class="card-img-top rounded-0 w-100 h-100"
                alt="..."
              />
            </div>
            <div class="col-6">
              <div class="card-body pb-0 text-left">
                <div class="mb-2 d-flex">
                  <router-link class="btn px-4 d-inline-block">رستوران</router-link>
                  <div class="pr-3 ml-auto snip">
                    <i class="fal fa-map-marker-alt mr-1"></i>
                    مشهد
                  </div>
                </div>
                <h2 class="text-weight my-3">رستوران لاکچری ممد آقا</h2>
                <h3 class="my-1">توسط : ممد آقا غلامی</h3>
                <p class="my-3">
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک است
                </p>

                <div class="">
                  <div class="d-flex justify-content-between price pt-3">
                    <span>تامین شده: 16،000،000</span>
                    <span>43%</span>
                  </div>
                </div>
                <div
                  class="progress rounded-0 flex-row-reverse"
                  style="height: 10px"
                >
                  <div
                    :style="{ width: '43%' }"
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    aria-valuenow="43"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="moneyNeeded mt-2">
                  مبلغ مورد نیاز:
                  <span style="color: #3bcf93">27،000،000 تومان</span>
                </p>
                <div class="mb-0 text-right userBtn">
                  <router-link class="btn d-inline-block">مشاهده و حمایت</router-link>
                </div>
                <div>
                  <div class="hexagon hexagon-with-border">
                    <div class="hexagon-shape">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2"></div>
                      </div>
                    </div>
                    <div class="hexagon-shape content-panel">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2">
                          <img
                            src="../../assets/img/projectsingle/pic1.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="consultantName">
                    <h6>معرف (امین)</h6>
                    <h5>احمد اکبرزاده</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4">
        <div class="card listCard border-1 rounded-0">
          <div class="row">
            <div class="col-6">
              <span class="Deadline">مهلت تامین : 23 روز مانده</span>
              <img
                src="../../assets/img/Projects/Rectangle51.png"
                class="card-img-top rounded-0 w-100 h-100"
                alt="..."
              />
            </div>
            <div class="col-6">
              <div class="card-body pb-0 text-left">
                <div class="mb-2 d-flex">
                  <router-link class="btn px-4 d-inline-block">رستوران</router-link>
                  <div class="pr-3 ml-auto snip">
                    <i class="fal fa-map-marker-alt mr-1"></i>
                    مشهد
                  </div>
                </div>
                <h2 class="text-weight my-3">رستوران لاکچری ممد آقا</h2>
                <h3 class="my-1">توسط : ممد آقا غلامی</h3>
                <p class="my-3">
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک است
                </p>

                <div class="">
                  <div class="d-flex justify-content-between price pt-3">
                    <span>تامین شده: 16،000،000</span>
                    <span>43%</span>
                  </div>
                </div>
                <div
                  class="progress rounded-0 flex-row-reverse"
                  style="height: 10px"
                >
                  <div
                    :style="{ width: '43%' }"
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    aria-valuenow="43"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="moneyNeeded mt-2">
                  مبلغ مورد نیاز:
                  <span style="color: #3bcf93">27،000،000 تومان</span>
                </p>
                <div class="mb-0 text-right userBtn">
                  <router-link class="btn d-inline-block">مشاهده و حمایت</router-link>
                </div>
                <div>
                  <div class="hexagon hexagon-with-border">
                    <div class="hexagon-shape">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2"></div>
                      </div>
                    </div>
                    <div class="hexagon-shape content-panel">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2">
                          <img
                            src="../../assets/img/projectsingle/pic1.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="consultantName">
                    <h6>معرف (امین)</h6>
                    <h5>احمد اکبرزاده</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination py-5">
        <router-link to="#">&raquo;</router-link>
        <router-link to="#" class="active">1</router-link>
        <router-link to="#">2</router-link>
        <router-link to="#">3</router-link>
        <router-link to="#">&laquo;</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isList: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-bio {
  border: 1px solid #3bcf93;
  box-sizing: border-box;
  border-radius: 10px;
}
.adviser {
  .hexagon {
    position: relative;
    display: inline-block;
    width: 234px;
    height: 210px;
  }
  .hexagon .hexagon-shape {
    position: absolute;
    right: 66px;
    top: 72px;
    overflow: hidden;
    display: inline-block;
    width: 240px;
    height: 207px;
    transform: rotate(-30deg) skewX(30deg) scale(1.19);
    border-radius: 20px;
  }
  .hexagon .hexagon-shape *,
  .hexagon .hexagon-shape *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
  .hexagon .hexagon-shape .hexagon-shape-inner {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    opacity: 1;
  }
  .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
  .hexagon .hexagon-shape .hexagon-shape-inner-2 {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: #4d5dd4;
    content: "";
  }

  .hexagon .hexagon-content .content-sub {
    color: #9f9fa0;
  }
  .hexagon.hexagon-with-border .hexagon-shape.content-panel {
    transform: rotate(-30deg) skewX(30deg) scale(1.15);
  }

  .hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
  .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
    background: #eef9fe;
  }
  .hexagon.info .contentTitle {
    color: #54c6f3;
  }
  .hexagon-shape-inner-2 {
    img {
      transform: rotate(-61deg) skewX(30deg) scale(1);
    }
  }
  .consultantName {
    position: absolute;
    top: 116px;
    right: 111px;
    h6 {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #303030;
    }
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #303030;
    }
  }
}
.brief-biography {
  position: relative;
  top: -100px;
  right: 344px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #303030;
  }
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #afafaf;
  }
  .rate {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #303030;
    .fas {
      color: #ffc820;
    }
    .far {
      color: #afafaf;
    }
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #afafaf;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: #303030;
    }
  }
}
.adviser-info {
  border-right: 1px solid #afafaf;
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #303030;
    background-color: #fff;
    position: relative;
    z-index: 99;
    i {
      color: #afafaf;
      padding-left: 5px;
    }
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    margin: 0px;
    color: #303030;
    padding: 7px 0px;

    i {
      color: #afafaf;
      padding-left: 5px;
    }
  }
  #line-info-1 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 68px;
    right: 83px;
  }
  #line-info-2 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 105px;
    right: 83px;
  }
  #line-info-3 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 142px;
    right: 83px;
  }
  #line-info-4 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 179px;
    right: 83px;
  }
}
.topnav {
  overflow: hidden;
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  letter-spacing: 0.02em;
  a {
    float: right;
    display: block;
    color: #afafaf;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border-bottom: 2px solid #f1f1f1;
    &:hover {
      border-bottom: 2px solid #4d5dd4;
    }
  }
  .active {
    border-bottom: 2px solid #4d5dd4;
    color: #4d5dd4;
  }
}
.line {
  width: 100%;
  height: 3px;
  background-color: #f1f1f1;
  position: relative;
  bottom: 3px;
  z-index: -99999;
}
.proDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }
}

.proItemDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;

    padding-bottom: 15px;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 13px;
    i {
      font-size: 10px;
    }
  }
}
.listProject {
  .btnList {
    outline: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    margin: 6px;
    font-size: 22px;
    padding-top: 8px;
    color: #afafaf;
    transform: rotate(180deg);
  }
  .active {
    color: #3bcf93;
  }
}

.search {
  display: flex;
  .searchTerm {
    width: 100%;
    border: 1px solid #3bcf93;
    border-left: none;
    background: rgba(59, 207, 147, 0.1);
    padding-right: 20px;
    height: 37px;
    border-radius: 0px 5px 5px 0px;
    outline: none;
    color: #9dbfaf;
    &::placeholder {
      color: #3bcf93;
      font-weight: 500;
    }
    &:focus {
      color: #3bcf93;
      font-weight: 600;
    }
  }

  .searchButton {
    width: 40px;
    height: 37px;
    border: 1px solid #3bcf93;
    border-right: none;
    background: rgba(59, 207, 147, 0.1);
    text-align: center;
    color: #3bcf93;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
    padding-top: 6px;
    &:focus {
      outline: none;
    }
  }
}

.cardUser {
  font-family: IRANSansWeb;
  font-style: normal;
  text-align: right;
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    top: 194px;
    left: 18px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .jobType {
    .btn {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      background-color: #388a69;
      border-radius: 5px;
      padding: 4px 20px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #2e2e2e;
  }
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #afafaf;
  }
  .jobCategory {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      color: #5e5e5e;
    }
  }

  .price {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #afafaf;
  }

  .moneyNeeded {
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #303030;
  }
  .userBtn {
    .btn {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #fff;
      background-color: #3bcf93;
      border-radius: 5px;
      padding: 12px 22px;
      &:hover {
        background-color: #388a69;
      }
    }
  }
}
.listCard {
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    top: 350px;
    left: 39px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .card-body {
    .btn {
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      color: #fff;
      background: #388a69;
      border-radius: 5px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      color: #2e2e2e;
    }
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      color: #afafaf;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #5e5e5e;
    }
    .price {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    .moneyNeeded {
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #303030;
    }
    .hexagon {
      position: relative;
      display: inline-block;
    }
    .hexagon .hexagon-shape {
      position: absolute;
      right: -89px;
      bottom: -62px;
      overflow: hidden;
      display: inline-block;
      width: 240px;
      height: 207px;
      transform: rotate(-30deg) skewX(30deg) scale(0.3);
      border-radius: 20px;
    }
    .hexagon .hexagon-shape *,
    .hexagon .hexagon-shape *:before {
      display: block;
      overflow: hidden;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }
    .hexagon .hexagon-shape .hexagon-shape-inner {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      opacity: 1;
    }
    .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
    .hexagon .hexagon-shape .hexagon-shape-inner-2 {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      background: #4d5dd4;
      content: "";
    }

    .hexagon .hexagon-content .content-sub {
      color: #9f9fa0;
    }
    .hexagon.hexagon-with-border .hexagon-shape.content-panel {
      transform: rotate(-30deg) skewX(30deg) scale(0.28);
    }

    .hexagon
      .hexagon-shape.content-panel:first-child
      .hexagon-shape-inner:before,
    .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
      background: #eef9fe;
    }
    .hexagon.info .contentTitle {
      color: #54c6f3;
    }
    .hexagon-shape-inner-2 {
      img {
        transform: rotate(-61deg) skewX(30deg) scale(1);
      }
    }
    .consultantName {
      position: absolute;
      bottom: 21px;
      right: 111px;
      h6 {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #303030;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #303030;
      }
    }
    .userBtn {
      margin-top: 37px;
      .btn {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #fff;
        background-color: #3bcf93;
        border-radius: 5px;
        padding: 12px 22px;

        &:hover {
          background-color: #388a69;
        }
      }
    }
  }
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #3bcf93;
  color: white;
  border: 1px solid #3bcf93;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
</style>