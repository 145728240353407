<template>
  <div>
    <app-header></app-header>
    <div class="container py-5">
      <div class="step" :class="step == 1 ? 'active1' : 'stepdeactiv'">
        <div class="title">
          <button href="#" class="d-flex">
            <p class="cicle">1</p>
            <p class="title-step">معرفی طرح</p>
          </button>
        </div>
        <div class="body-step">
          <div class="body-steptwo">
            <form>
              <div class="form-group">
                <label for="exampleFormControlInput1">عنوان طرح</label>
                <input v-model="model.title" type="text" class="form-control" />
              </div>
              <div class="form-group">
                <div class="row my-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">تاریخ شروع</label>
                      <date-picker
                        clearable
                        class="w-100"
                        v-model="model.start_time"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">تاریخ پایان</label>
                      <date-picker
                        clearable
                        class="w-100"
                        v-model="model.end_time"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">شرح مختصر</label>
                <textarea
                  class="form-control"
                  v-model="model.desc"
                  rows="3"
                  placeholder="شرح مختصر کسب و کار (ایده، روش اجرا، اهداف و...)"
                ></textarea>
              </div>

              <div class="text-right stepnext pt-4">
                <button class="btn next" @click.prevent="nextStep">
                  گام بعد
                </button>
                <button class="btn back" @click.prevent="prevStep">
                  گام قبل
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="step" :class="step == 2 ? 'active2' : 'stepdeactiv'">
        <div class="title">
          <button href="#" class="d-flex">
            <p class="cicle">2</p>
            <p class="title-step">مختصات جغرافیایی</p>
          </button>
        </div>

        <div class="body-step">
          <div class="body-stepthree">
            <div class="row">
              <div class="col-sm-6">
                <p>استان</p>
                <div class="form-group">
                  <select
                    class="form-control bg-light text-secondary"
                    @change="provinceChange($event)"
                    v-model="model.state"
                  >
                    <option
                      :value="item.id"
                      v-for="item in province"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <p>شهر</p>
                <div class="form-group">
                  <select class="form-control bg-light text-secondary" v-model="model.city">
                    <option :value="item.id" v-for="item in city" :key="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <p>منطقه</p>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="model.area"
                    class="form-control"
                    placeholder="منطقه"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <p>کد پستی</p>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="model.postal_code"
                    placeholder="کدپستی"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">آدرس </label>
              <textarea
                class="form-control"
                rows="3"
                placeholder="شرح آدرس "
                v-model="model.address"
              ></textarea>
            </div>
            <div class="text-right stepnext pt-4">
              <button class="btn next" @click.prevent="nextStep">
                گام بعد
              </button>
              <button class="btn back" @click.prevent="prevStep">
                گام قبل
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="step" :class="step == 3 ? 'active3' : 'stepdeactiv'">
        <div class="title">
          <button href="#" class="d-flex">
            <p class="cicle">3</p>
            <p class="title-step">انتخاب معرف</p>
          </button>
        </div>
        <div class="body-step">
          <div class="body-stepfour">
            <div class="py-3">
              <label for="">معرف</label>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <select v-model="model.state" class="form-control"></select>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right stepnext pt-4">
              <button class="btn next" @click.prevent="nextStep">
                گام بعد
              </button>
              <button class="btn back" @click.prevent="prevStep">
                گام قبل
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="step" :class="step == 4 ? 'active4' : 'stepdeactiv'">
        <div class="title">
          <button href="#" class="d-flex">
            <p class="cicle">4</p>
            <p class="title-step">ثبت نیاز مالی</p>
          </button>
        </div>
        <div class="body-step">
          <div class="body-stepfour">
            <form action="">
              <div class="py-3">
                <div class="row">
                  <div class="col-sm">
                    <label for="">هزینه کل پروژه</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="هزینه کل پروژه مثلا : 321000 تومان"
                        v-model="model.total_expense"
                      />
                    </div>
                  </div>
                  <div class="col-sm">
                    <label for="">وام مورد نیاز</label>
                    <div class="input-group">
                      <input
                        v-model="model.loan"
                        type="text"
                        class="form-control"
                        placeholder="وام مورد نیاز"
                      />
                    </div>
                  </div>
                  <div class="col-sm">
                    <label for="">مهلت تامین</label>
                    <div class="input-group">
                      <date-picker
                        clearable
                        class="w-100"
                        v-model="model.expierDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-3">
                <label for="">الگوی بازپرداخت</label>
                <div class="row">
                  <div class="col">
                    <div class="input-group">
                      <select
                        v-model="model.installment_type"
                        class="form-control"
                      ></select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="model.installment_no"
                        placeholder="تعداد اقساط"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right stepnext pt-4">
                <button class="btn next" @click.prevent="nextStep">
                  گام بعد
                </button>
                <button class="btn back" @click.prevent="prevStep">
                  گام قبل
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="step" :class="step == 5 ? 'active5' : 'stepdeactiv'">
        <div class="title">
          <button class="d-flex">
            <p class="cicle">5</p>
            <p class="title-step">تصویر</p>
          </button>
        </div>
        <div class="body-step">
          <div class="body-stepfour">
            <form action="">
              <div class="py-3">
                <label for="">ارسال جدول هزینه ها</label>
                <vue-dropzone
                  :options="dropzoneOptions"
                  id="dropzone"
                  :useCustomSlot="true"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      فایل پیوست خود را بکشید و اینجا رها کنید یا

                      <span> انتخاب کنید </span>
                    </h3>
                    <p class="dropzone-custom-title">
                      اندازه فایل کمتر از 2 مگابایت باشد / فرمت های jpg , png ,
                      word , zip , mp4 و...
                    </p>
                  </div>
                </vue-dropzone>
              </div>

              <div class="text-right stepnext pt-4">
                <button class="btn next" @click.prevent="nextStep">ثبت</button>
                <button class="btn back" @click.prevent="prevStep">
                  گام قبل
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import header from "./Components/Header";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  components: {
    "app-header": header,
    vueDropzone: vue2Dropzone,
  },
  created() {
    this.$http.get("enum/province").then(
      (response) => {
        if (response.status == 200) {
          this.province = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.province = data.body;
        }
      }
    );
    this.$http.get("case/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          this.model.title = response.body.data.info.title;
          this.model.desc = response.body.data.info.desc;
          this.model.start_time = response.body.data.info.start_time;
          this.model.end_time = response.body.data.info.end_time;
          this.model.state = response.body.data.info.state;
          this.model.loan = response.body.data.info.loan;
          this.model.expierDate = response.body.data.info.expierDate;
          this.model.subject = response.body.data.info.subject;
          this.model.job_type = response.body.data.info.job_type;
          this.model.city = response.body.data.info.city;
          this.model.area = response.body.data.info.area;
          this.model.address = response.body.data.info.address;
          this.model.postal_code = response.body.data.info.postal_code;
          this.model.total_expense = response.body.data.info.total_expense;
          this.model.installment_no = response.body.data.info.installment_no;
          this.model.installment_type =
            response.body.data.info.installment_type;
          this.model.installment_respite =
            response.body.data.info.installment_respite;
          this.model.direct_job = response.body.data.info.direct_job;
          this.model.indirect_job = response.body.data.info.indirect_job;
          this.model.executive_features =
            response.body.data.info.executive_features;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.model.title = data.body.data.info.title;
          this.model.desc = data.body.data.info.desc;
          this.model.start_time = data.body.data.info.start_time;
          this.model.end_time = data.body.data.info.end_time;
          this.model.state = data.body.data.info.state;
          this.model.loan = data.body.data.info.loan;
          this.model.expierDate = data.body.data.info.expierDate;
          this.model.subject = data.body.data.info.subject;
          this.model.job_type = data.body.data.info.job_type;
          this.model.city = data.body.data.info.city;
          this.model.area = data.body.data.info.area;
          this.model.address = data.body.data.info.address;
          this.model.postal_code = data.body.data.info.postal_code;
          this.model.total_expense = data.body.data.info.total_expense;
          this.model.installment_no = data.body.data.info.installment_no;
          this.model.installment_type = data.body.data.info.installment_type;
          this.model.installment_respite =
            data.body.data.info.installment_respite;
          this.model.direct_job = data.body.data.info.direct_job;
          this.model.indirect_job = data.body.data.info.indirect_job;
          this.model.executive_features =
            data.body.data.info.executive_features;
        }
      }
    );
  },
  data() {
    return {
      license: "",
      province: [],
      city:[],
      dropzoneOptions: {
        url: "/case/" + this.$route.params.id + "/image",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      step: 1,
      model: {
        title: "",
        desc: "",
        start_time: "",
        end_time: "",
        state: 0,
        loan: 0,
        expierDate: "",
        subject: 0,
        job_type: 0,
        city: 0,
        area: "",
        address: "",
        postal_code: 0,
        total_expense: 0,
        installment_no: 0,
        installment_type: 0,
        installment_respite: 0,
        direct_job: 0,
        indirect_job: 0,
        executive_features: "",
      },
      registration: {
        name: null,
        email: null,
        street: null,
        city: null,
        state: null,
        numtickets: 0,
        shirtsize: "XL",
      },
    };
  },
  methods: {
    provinceChange: function (event) {
      this.model.city = 0;
      this.city = [];
      this.$http.get("enum/province/" + event.target.value).then(
        (response) => {
          if (response.status == 200) {
            this.city = response.body.counties;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.city = data.body.counties;
          }
        }
      );
      this.UpdatePage();
    },
    prevStep: function () {
      this.step--;
    },
    nextStep: function () {
      this.step++;
    },
  },
};
</script>
<style lang="scss" scoped>
.step {
  .title {
    button {
      font-weight: bold;
      font-size: 20px;
      line-height: 33px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #4d5dd4;
      background: none;
      border: none;

      &:focus {
        outline: none;
      }
      .cicle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #4d5dd4;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #ffffff;
        margin: -10px;
        padding: 2px;
        border: 8px solid #fff;
      }

      .title-step {
        padding: 0px 10px 0px 10px;
        margin: 0;
      }
    }
  }

  .body-step {
    border-right: 3px solid #4d5dd4;
    margin: 0px 18px;
    padding: 24px 25px;
  }
}

.stepdeactiv {
  .title {
    button {
      color: #afafaf;
      .cicle {
        background: #afafaf;
      }
    }
  }

  .body-step {
    border-right: 3px solid #afafaf;
    // height: 0px;

    .body-stepone,
    .body-steptwo,
    .body-stepthree,
    .body-stepfour,
    .body-stepfive,
    .body-stepsix,
    .body-stepseven,
    .body-stepeight,
    .body-stepnine,
    .body-stepten {
      display: none;
      transition: all 5s;
    }
  }
}

.step {
  .body-stepone {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #303030;
    }

    ol {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      letter-spacing: 0.02em;
      color: #303030;
    }

    .agreement {
      .agree {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }

      .cancel {
        width: 110px;
        height: 50px;
        background: #f44336;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-right: 10px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.step {
  .body-steptwo {
    .dropzone {
      min-height: 100px;
      padding: 0px;
    }

    .vue-dropzone {
      background: #ffffff;
      border: 1px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      .dropzone-custom-content {
        h3 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          span {
            color: #4d5dd4;
          }
        }
        p {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: #9e9e9e;
        }
      }
    }
  }
  .stepnext {
    .next {
      width: 110px;
      height: 50px;
      background: #3bcf93;
      border-radius: 5px;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #fff;
      margin-left: 10px;

      &:focus {
        box-shadow: none;
      }
    }
    .back {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #f44336;
      border-bottom: 1px solid #f44336;
      border-radius: 0px;
      padding: 0px;
      margin: 10px;
    }
  }
}

.step {
  .body-stepthree {
    .stepnext {
      .next {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }
      .back {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #f44336;
        border-bottom: 1px solid #f44336;
        border-radius: 0px;
        padding: 0px;
        margin: 10px;
      }
    }
  }
}
.step {
  .body-stepfour {
    .dropzone {
      min-height: 100px;
      padding: 0px;
    }

    .vue-dropzone {
      background: #ffffff;
      border: 1px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      .dropzone-custom-content {
        h3 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          span {
            color: #4d5dd4;
          }
        }
        p {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: #9e9e9e;
        }
      }
    }
    .stepnext {
      .next {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }
      .back {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #f44336;
        border-bottom: 1px solid #f44336;
        border-radius: 0px;
        padding: 0px;
        margin: 10px;
      }
    }
  }
}
.step {
  .body-stepten {
    .dropzone {
      min-height: 100px;
      padding: 0px;
    }

    .vue-dropzone {
      background: #ffffff;
      border: 1px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      .dropzone-custom-content {
        i {
          font-size: 50px;
          color: #3bcf93;
          padding-bottom: 20px;
        }
        h3 {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          span {
            color: #4d5dd4;
          }
        }
        p {
          font-family: IRANSansWeb;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          color: #9e9e9e;
        }
      }
    }
    .stepfinal {
      .final {
        width: 110px;
        height: 50px;
        background: #3bcf93;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fff;
        margin-left: 10px;

        &:focus {
          box-shadow: none;
        }
      }
      .back {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #f44336;
        border-bottom: 1px solid #f44336;
        border-radius: 0px;
        padding: 0px;
        margin: 10px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .body-step .form-group .row {
    display: inline-block;
    width: 100%;
    margin: 0;
    .col {
      padding: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
