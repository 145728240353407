<template>
  <div class="fix-menu">
    <app-header></app-header>
    <div class="container pt-5">
      <div class="row">
        <div class="col-lg-8 col-md-8 d-flex align-items-center">
          <div class="form-group w-25 m-0 mr-3">
            <select
              class="form-control bg-light text-secondary"
              @change="provinceChange($event)"
              v-model="filter.province"
            >
              <option value="0">استان ها</option>
              <option :value="item.id" v-for="item in province" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="form-group w-25 m-0">
            <select
              class="form-control bg-light text-secondary"
              @change="cityChange($event)"
              v-model="filter.city"
            >
              <option value="0">همه شهرها</option>
              <option :value="item.id" v-for="item in city" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="wrap ml-auto">
            <div class="search">
              <input
                type="text"
                class="searchTerm"
                v-model="filter.search"
                placeholder="جست و جو ..."
              />
              <button
                type="button"
                v-on:click="UpdatePage()"
                class="searchButton"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        class="alert alert-primary w-100 my-4 text-center"
        role="alert"
        v-if="data.length == 0"
      >
        هیچ موردی یافت نشد
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-md-4 col-sm-6"
          v-for="item in data"
          :key="item.id"
        >
          <div class="card mt-5">
            <div class="image-user">
              <div class="hexagon hexagon-with-border">
                <div class="hexagon-shape">
                  <div class="hexagon-shape-inner">
                    <div class="hexagon-shape-inner-2"></div>
                  </div>
                </div>
                <div class="hexagon-shape content-panel">
                  <div class="hexagon-shape-inner">
                    <div class="hexagon-shape-inner-2">
                      <img
                        :src="item.avatar_url"
                        v-if="item.avatar_url"
                        :alt="item.full_name"
                      />
                      <img src="http://karvam.ir/noimage.jpg" alt="" v-else />
                    </div>
                  </div>
                </div>
              </div>
              <div class="personal-info">
                <h5 v-bind:title="item.full_name">
                  {{ textRestrictions(item.full_name, 15) }}
                </h5>
                <div class="rank">
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-on.png" alt="on" />
                  <img src="../../assets/img/star/star-off.png" alt="off" />
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="py-3">
                <div class="d-flex justify-content-between">
                  <span style="color: #afafaf">میزان حمایت:</span>
                  <span>123456789 تومان</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span style="color: #afafaf">تعداد پروژه:</span>
                  <span>{{ item.total_case }} مورد</span>
                </div>
              </div>
              <div class="text-center py-3">
                <router-link class="btn" :to="'/consultants/single/' + item.id"
                  >مشاهده پروژه ها</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center" v-if="page.last_page > 1">
      <div class="pagination py-5">
        <div class="pagination py-5">
          <router-link
            v-if="page.current_page > 1"
            :to="'/consultants?' + GetQueryString(page.current_page - 1)"
            >&raquo;</router-link
          >
          <router-link
            v-for="index in page.last_page"
            :key="index"
            :to="'/consultants?' + GetQueryString(index)"
            :class="page.current_page == index ? 'active' : ''"
            >{{ index }}</router-link
          >
          <router-link
            v-if="page.current_page < page.last_page"
            :to="'/consultants?' + GetQueryString(page.current_page + 1)"
            >&laquo;</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import header from "./Components/Header";

export default {
  data() {
    return {
      data: {},
      filter: {
        search: null,
        city: 0,
        province: 0,
      },
      page: {
        current_page: 0,
        from: 0,
        last_page: 0,
      },
      province: [],
      city: [],
    };
  },
  methods: {
    provinceChange: function (event) {
      this.filter.city = 0;
      this.city = [];
      this.filter.province = parseInt(event.target.value);
      this.$http.get("enum/province/" + event.target.value).then(
        (response) => {
          if (response.status == 200) {
            this.city = response.body.counties;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.city = data.body.counties;
          }
        }
      );
      this.UpdatePage();
    },
    textRestrictions(text, value) {
      let length = text.length;
      if (length >= value) {
        return text.substr(0, value) + "...";
      } else {
        return text;
      }
    },
    cityChange: function (event) {
      this.filter.city = event.target.value;
      this.UpdatePage();
    },
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    GetQueryString(page = 1) {
      var query = "";
      if (page) {
        query += "page=" + page;
      }

      if (this.filter.province) {
        query += "&province=" + this.filter.province;
      }
      if (this.filter.city) {
        query += "&city=" + this.filter.city;
      }
      if (this.filter.search) {
        query += "&search=" + this.filter.search;
      }
      return query;
    },
    UpdatePage: function () {
      this.$http.get("advisor?" + this.GetQueryString()).then(
        (response) => {
          if (response.status == 200) {
            this.data = response.body.data;
            this.page = response.body.meta;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.data = data.body.data;

            this.page = data.body.meta;
          }
        }
      );
    },
  },
  created() {
    if (this.$route.query.province) {
      this.filter.province = this.$route.query.province;
      this.$http.get("enum/province/" + this.filter.province).then(
        (response) => {
          if (response.status == 200) {
            this.city = response.body.counties;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.city = data.body.counties;
          }
        }
      );
    }
    if (this.$route.query.city) {
      this.filter.city = this.$route.query.city;
    }
    if (this.$route.query.search) {
      this.filter.search = this.$route.query.search;
    }

    this.$http
      .get("advisor?" + this.GetQueryString(this.$route.query.page))
      .then(
        (response) => {
          if (response.status == 200) {
            this.data = response.body.data;
            this.page = response.body.meta;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.data = data.body.data;
            this.page = data.body.meta;
          }
        }
      );
    this.$http.get("enum/province").then(
      (response) => {
        if (response.status == 200) {
          this.province = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.province = data.body;
        }
      }
    );
  },
  components: {
    "app-header": header,
  },
  metaInfo() {
    return {
      title: "عنوان",
      meta: [
        {
          name: "description",
          content:
            "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
        },
        {
          property: "og:title",
          content: "Epiloge - Build your network in your field of interest",
        },
        {
          property: "og:site_name",
          content: "Epiloge",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.bg-sabz {
  background-color: $themeColorGreen;
}
.text-sabz {
  color: $themeColorGreen;
}
.bg-custom-gray {
  background-color: $themeColorLight;
}
.text-custom-gray {
  color: $themeColorLight;
}
.text-bold {
  font-weight: bold;
}

.search {
  display: flex;
  .searchTerm {
    width: 100%;
    border: 1px solid #3bcf93;
    border-left: none;
    background: rgba(59, 207, 147, 0.1);
    padding-right: 20px;
    height: 37px;
    border-radius: 0px 5px 5px 0px;
    outline: none;
    color: #9dbfaf;
    &::placeholder {
      color: #3bcf93;
      font-weight: 500;
    }
    &:focus {
      color: #3bcf93;
      font-weight: 600;
    }
  }

  .searchButton {
    width: 40px;
    height: 37px;
    border: 1px solid #3bcf93;
    border-right: none;
    background: rgba(59, 207, 147, 0.1);
    text-align: center;
    color: #3bcf93;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
    padding-top: 6px;
    &:focus {
      outline: none;
    }
  }
}

.image-user {
  display: flex;
  flex-flow: wrap;
  position: relative;
  width: 100%;
  .hexagon {
    width: 110px;
    position: relative;
    margin: auto;
  }
  .personal-info {
    text-align: center;
    width: 100%;
    .medal {
      position: absolute;
      top: -25px;
      left: -34px;
      width: 25px;
      height: 25px;
    }
  }
}

.hexagon {
  position: relative;
  display: inline-block;
  width: auto;
  height: 150px;
}
.hexagon .hexagon-shape {
  position: absolute;
  right: -40px;
  overflow: hidden;
  display: inline-block;
  margin: -20px 0px;
  width: 200px;
  height: 207px;
  transform: rotate(-30deg) skewX(30deg) scale(0.5);
  border-radius: 20px;
}
.hexagon .hexagon-shape *,
.hexagon .hexagon-shape *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}
.hexagon .hexagon-shape .hexagon-shape-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  opacity: 1;
}
.hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
.hexagon .hexagon-shape .hexagon-shape-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  background: #4d5dd4;
  content: "";
}

.hexagon .hexagon-content .content-sub {
  color: #9f9fa0;
}
.hexagon.hexagon-with-border .hexagon-shape.content-panel {
  transform: rotate(-30deg) skewX(30deg) scale(0.48);
}

.hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
.hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
  background: #eef9fe;
}
.hexagon.info .contentTitle {
  color: #54c6f3;
}
.hexagon-shape-inner-2 {
  img {
    transform: rotate(-61deg) skewX(30deg) scale(1.2);
  }
}

.card {
  background: #ffffff;
  border-radius: 10px;
  .card-body {
    .card-title {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #303030;
    }
    .checked {
      color: #ffc820;
    }
    .unchecked {
      color: #afafaf;
    }
    span {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: #303030;
    }
    .btn {
      border: 1px solid #3bcf93;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #3bcf93;
      padding: 10px 40px;
      &:hover {
        color: #fff;
        background-color: #3bcf93;
      }
    }
  }
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #3bcf93;
  color: white;
  border: 1px solid #3bcf93;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

@media only screen and (max-width: 768px) {
  .form-group {
    width: 49% !important;
    margin-bottom: 15px !important;
  }
}
</style>
