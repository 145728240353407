<template>
  <div>
    <div class="container contprofile py-5">
      <div class="row">
        <menu-profile></menu-profile>
        <div class="col-md-6 col-lg-8">
          <div v-if="data == null || this.data.length == 0">
            <div class="alert alert-primary" role="alert">
              هیچ نتیجه ای یافت نشد
            </div>
          </div>
          <!-- <div
            class="alert alert-primary w-100 my-4 text-center"
            role="alert"
            v-if="data.length == 0"
          >
            هیچ موردی یافت نشد
          </div> -->
          <div class="container text-center mb-2" v-if="isList">
            <div class="row">
              <div
                class="col-lg-6 col-md-12 pt-4"
                v-for="item in data"
                :key="item.id"
              >
                <projectItem :item="item" :link="'/profile/Showproject/'+item.id"></projectItem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu from "../Shared/MenuProfile";
import projectItem from "../../Shared/Componensts/projectItem.vue";

export default {
  data() {
    return {
      isList: true,
      show: false,
      data: [],
      page: {
        current_page: 0,
        from: 0,
        last_page: 0,
      },
    };
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

  },
  created() {
    this.$http.get("user/case").then(
      (response) => {
        if (response.status == 200) {
          this.data = response.body.owner.data;
        }
        console.log(this.data.length);
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.owner.data;
        }
      }
    );
  },
  components: {
    projectItem,
    "menu-profile": menu,
  },
};
</script>

<style lang="scss" scoped>
.contprofile {
  margin-top: 130px;
}
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.bg-sabz {
  background-color: $themeColorGreen;
}
.text-sabz {
  color: $themeColorGreen;
}
.bg-custom-gray {
  background-color: $themeColorLight;
}
.text-custom-gray {
  color: $themeColorLight;
}
.text-bold {
  font-weight: bold;
}
.relative {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(59, 207, 147, 0.1);
  height: 200px;
}
.showAll {
  text-align: center;
  .headerGrouping {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    padding: 10px 0px;
    margin: 15px;
    &:hover {
      text-decoration: none;
    }

    .active {
      width: 15px;
      height: 15px;
      background-color: $themeColorGreen;
      position: absolute;
      top: 0px;
    }

    img {
      width: 51px;
      height: 50px;
    }
    h4 {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #303030;
      padding-top: 25px;
    }
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: $themeColorGreen;
    padding-top: 20px;
    a {
      color: $themeColorGreen;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.collapsmore {
  .navbar {
    justify-content: center;
  }
  .navbar-toggler {
    border-color: 0;
    &:focus {
      outline: none;
    }
  }
  .otherTopics {
    .btn {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #303030;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 15px 40px;
      margin: 10px 0px;
      &:hover {
        border: 1px solid #4d5dd4;
        color: #4d5dd4;
      }
      &:focus {
        background-color: rgba(77, 93, 212, 0.15);
        box-shadow: none;
        border: 1px solid #4d5dd4;
        color: #4d5dd4;
      }
    }
  }
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    color: #3bcf93;
  }
}
.listProject {
  .btnList {
    outline: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    margin: 6px;
    font-size: 22px;
    padding-top: 8px;
    color: #afafaf;
    transform: rotate(180deg);
  }
  .active {
    color: #3bcf93;
  }
}

.search {
  display: flex;
  .searchTerm {
    width: 100%;
    border: 1px solid #3bcf93;
    border-left: none;
    background: rgba(59, 207, 147, 0.1);
    padding-right: 20px;
    height: 37px;
    border-radius: 0px 5px 5px 0px;
    outline: none;
    color: #9dbfaf;
    &::placeholder {
      color: #3bcf93;
      font-weight: 500;
    }
    &:focus {
      color: #3bcf93;
      font-weight: 600;
    }
  }

  .searchButton {
    width: 40px;
    height: 37px;
    border: 1px solid #3bcf93;
    border-right: none;
    background: rgba(59, 207, 147, 0.1);
    text-align: center;
    color: #3bcf93;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
    padding-top: 6px;
    &:focus {
      outline: none;
    }
  }
}

.cardUser {
  font-family: IRANSansWeb;
  font-style: normal;
  text-align: right;
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    bottom: 18px;
    left: 18px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .jobType {
    .btn {
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      background-color: #388a69;
      border-radius: 5px;
      padding: 4px 20px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #2e2e2e;
    min-height: 60px;
  }
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #afafaf;
  }
  .jobCategory {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: justify;
      color: #5e5e5e;
      min-height: 125px;
    }
  }

  .price {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #afafaf;
  }

  .moneyNeeded {
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #303030;
  }
  .userBtn {
    .btn {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #fff;
      background-color: #3bcf93;
      border-radius: 5px;
      padding: 12px 22px;
      &:hover {
        background-color: #388a69;
      }
    }
  }
}
.listCard {
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    bottom: 15px;
    left: 30px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .card-body {
    .btn {
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      color: #fff;
      background: #388a69;
      border-radius: 5px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      color: #2e2e2e;
    }
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      color: #afafaf;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #5e5e5e;
    }
    .price {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    .moneyNeeded {
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #303030;
    }
    .hexagon {
      position: relative;
      display: inline-block;
    }
    .hexagon .hexagon-shape {
      position: absolute;
      right: -89px;
      bottom: -62px;
      overflow: hidden;
      display: inline-block;
      width: 240px;
      height: 207px;
      transform: rotate(-30deg) skewX(30deg) scale(0.3);
      border-radius: 20px;
    }
    .hexagon .hexagon-shape *,
    .hexagon .hexagon-shape *:before {
      display: block;
      overflow: hidden;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }
    .hexagon .hexagon-shape .hexagon-shape-inner {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      opacity: 1;
    }
    .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
    .hexagon .hexagon-shape .hexagon-shape-inner-2 {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      background: #4d5dd4;
      content: "";
    }

    .hexagon .hexagon-content .content-sub {
      color: #9f9fa0;
    }
    .hexagon.hexagon-with-border .hexagon-shape.content-panel {
      transform: rotate(-30deg) skewX(30deg) scale(0.28);
    }

    .hexagon
      .hexagon-shape.content-panel:first-child
      .hexagon-shape-inner:before,
    .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
      background: #eef9fe;
    }
    .hexagon.info .contentTitle {
      color: #54c6f3;
    }
    .hexagon-shape-inner-2 {
      img {
        transform: rotate(-61deg) skewX(30deg) scale(1);
      }
    }
    .consultantName {
      position: absolute;
      bottom: 21px;
      right: 111px;
      h6 {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #303030;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #303030;
      }
    }
    .userBtn {
      margin-top: 37px;
      .btn {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #fff;
        background-color: #3bcf93;
        border-radius: 5px;
        padding: 12px 22px;

        &:hover {
          background-color: #388a69;
        }
      }
    }
  }
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #3bcf93;
  color: white;
  border: 1px solid #3bcf93;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
</style>