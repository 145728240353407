<template>
  <section class="groupSupport py-5">
    <div class="container">
      <div class="titleSupport">
        <h2>همین الان شروع کنید</h2>
      </div>
      <div class="card-deck cardSupport py-4 ">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card text-center bodySupport rounded-0 boarder-0 pt-4 pb-4 border-0">
              <div class="card-body">
                <div class="cardImg">
                  <img src="../../../assets/img/support/Vector-1.png" alt="" />
                </div>
                <h5 class="card-title">ایجاد پروژه جدید</h5>
                <p class="card-text text-justify">
                    در کار خیر حاجت هیچ استخاره نیست رو درست برای همینجا گفتن، اگر ایده‌ای داری دست بجنبون و ثبت کن تا با کمک دوستان کاروامی‌ت تبدیل به کار شه. لااقل کلیک کن و فرایند ایجاد پروژه جدید آشنا شو.
                </p>
                <router-link to="project/create" class="btnSupport">ایجاد پروژه جدید</router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card text-center bodySupport rounded-0 pt-4 pb-4 border-0">
              <div class="card-body">
                <div class="cardImg">
                  <img src="../../../assets/img/support/Vector-2.png" alt="" />
                </div>
                <h5 class="card-title">حمایت از پروژه‌ها</h5>
                <p class="card-text text-justify">
                  اگر پول یا اعتباری داری که مدتی بهش نیاز نداری، اینجا می‌تونی با یک تیر ۲ نشون بزنی، هم به راه‌اندازی یه کسب و کار کمک کنی و هم حساب اون دنیات رو پر کنی. سر موعد هم پولت رو از کاروام پس می‌گیری.
                </p>
                <router-link to="/projects" class="btnSupport">دیدن پروژه‌ها</router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card text-center bodySupport rounded-0 pt-4 pb-4 border-0">
              <div class="card-body">
                <div class="cardImg">
                  <img src="../../../assets/img/support/Vector-3.png" alt="" />
                </div>
                <h5 class="card-title">حمایت از کاروام</h5>
                <p class="card-text text-justify">
                  شما جزو اون دسته نیستید که فکر می‌کنن خود کاروام، یعنی همین پلتفرمی که تمام این فرایندها و هزارتا کار دیگری که اینجا هست، با فتوسنتز کار می‌کنه؟ اگر جویای حال ما هستی، سری به اینجا بزن. 
                </p>
                <router-link to="/profile/karvam" class="btnSupport">حمایت از کاروام</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.groupSupport {
  text-align: center;
  background-color: $themeColorLight;

  .titleSupport {
    background-image: url("../../../assets/img/support/titleSupport.png");
    background-repeat: no-repeat;
    background-position: center;
    padding: 29px 0px;

    h2 {
      font-family: KalamehBold;
      color: $themeColorDark;
      &::before {
        content: url(../../../assets/img/support/Rectangle.png);
        position: relative;
        left: 10px;
      }
      &::after {
        content: url(../../../assets/img/support/Rectangle.png);

        position: relative;
        right: 10px;
      }
    }
  }
  .cardSupport {
    text-align: center;
    .bodySupport {
      background-color: #fff;
       flex: none;
      margin-bottom: 10px;
      &:hover {
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
      }
    }
    .cardImg {
      background-image: url("../../../assets/img/support/Vector.png");
      background-repeat: no-repeat;
      background-position: center;

      img {
        padding: 20px;
      }
    }
    p {
      padding: 20px 10px;
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;
    }
    h5 {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 33px;
      color: $textColorDark;
      padding-top: 40px;
    }
    .btnSupport {
      padding: 8px 33px;
      background-color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      text-align: right;
      color: #3bcf93;
      border: 2px solid #3bcf93;
      box-sizing: border-box;
      border-radius: 5px;
      &:hover {
        text-decoration: none;
        color: #fff;
        background: #3bcf93;
        border: 2px solid #3bcf93;
        box-sizing: border-box;
        box-shadow: 0px 5px 10px rgba(56, 138, 105, 0.5);
        border-radius: 5px;
      }
    }
  }
}
</style>

