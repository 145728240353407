<template>
  <header>
    <div class="align-items-center d-flex flex-column text-center titleHeader">
      <h2 class="mt-auto">پروژه های کاروام</h2>
      <p class="mb-auto">ایده های مطرح شده و در انتظار حمایت</p>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss">
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.titleHeader {
  background-image: url("../../../assets/img/Projects/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;

  h2 {
    font-family: KalamehBold;
    color: #fff;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 67px;
    letter-spacing: 0.02em;

    &::before {
      content: url(../../../assets/img/support/Rectangle.png);
      position: relative;
      left: 10px;
    }
    &::after {
      content: url(../../../assets/img/support/Rectangle.png);

      position: relative;
      right: 10px;
    }
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #fff;
  }
}
</style>