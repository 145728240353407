<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="consulting mb-3">
          <router-link to="/" class="btn py-3">
            <div class="d-flex">
              <div class="icon-Consul">
                <i class="far fa-chalkboard-teacher"></i>
              </div>
              <div class="px-2 content-box text-left ml-2">
                <p class="content-box-name m-0">مشاوره‌های من</p>
                <span class="content-box-title"
                  >{{ data.cases.qty }} پروژه</span
                >
                <span class="content-box-description"
                  >به ارزش {{ data.cases.amount }} میلیون تومان</span
                >
              </div>
              <div class="box-icon ml-auto">
                <i class="far fa-chalkboard-teacher"></i>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-md-12">
        <div class="registeredProjects mb-3">
          <router-link to="/" class="btn py-3">
            <div class="d-flex">
              <div class="icon-Consul">
                <i class="fal fa-cogs"></i>
              </div>
              <div class="px-2 content-box text-left ml-2">
                <p class="content-box-name m-0">پروژه‌های ثبت شده</p>
                <span class="content-box-title"
                  >{{ data.benefet.qty }} پروژه</span
                >
                <span class="content-box-description"
                  >به ارزش {{ data.benefet.amount }} میلیون تومان</span
                >
              </div>
              <div class="box-icon ml-auto">
                <i class="far fa-cogs"></i>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-md-12">
        <div class="total mb-3">
          <router-link to="/" class="btn py-3">
            <div class="d-flex">
              <div class="icon-Consul">
                <i class="fas fa-money-bill-wave"></i>
              </div>
              <div class="px-2 content-box text-left ml-2">
                <p class="content-box-name m-0">مجموع مشارکت</p>
                <span class="content-box-title"
                  >{{ data.advisor.qty }} پروژه</span
                >
                <span class="content-box-description"
                  >به ارزش {{ data.advisor.amount }} میلیون تومان</span
                >
              </div>
              <div class="box-icon ml-auto">
                <i class="far fa-money-bill-wave"></i>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="box-content col-lg-6 col-md-12">
        <div class="content py-3 mb-3">
          <div class="d-flex">
            <div class="content-icon greenLight mr-3">
              <i class="fas fa-wallet"></i>
            </div>
            <div class="content-title">
              <span>کیف پول</span>
            </div>
            <div class="point mr-1 ml-auto">
              <span class="point-one"></span>
              <span class="point-two"></span>
              <span class="point-three"></span>
            </div>
          </div>
          <div class="description text-left mt-2">
            <span>{{ formatPrice(amount) }} تومان</span>
          </div>
        </div>
      </div>
      <div class="box-content col-lg-6 col-md-12">
        <div class="content py-3 mb-3">
          <div class="d-flex">
            <div class="content-icon purple mr-3">
              <i class="fas fa-credit-card"></i>
            </div>
            <div class="content-title">
              <span>اطلاعات حساب</span>
            </div>
            <div class="point mr-1 ml-auto">
              <span class="point-one"></span>
              <span class="point-two"></span>
              <span class="point-three"></span>
            </div>
          </div>
          <div class="description text-left mt-2">
            <p>شماره کارت : {{ bank_card_no }}</p>
            <p>شماره شبا : {{ bank_sheba }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      data: {
        advisor: {
          amount: 0,
          qty: 0,
        },
        benefet: {
          amount: 1010990000,
          qty: 25,
        },
        cases: {
          amount: 0,
          qty: 0,
        },
      },
      amount: 1013710000,
      bank_sheba: 0,
      bank_card_no: 0,
    };
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  created() {
    if (!this.$cookie.get("ApiToken")) {
      this.$router.push({ name: "index" });
    }
    this.$http.get("user/info").then(
      (response) => {
        console.log(response);
        if (response.status == 200) {
          this.data = response.body.statistics;
          this.amount = response.body.wallet.amount;
          this.bank_sheba = response.body.data.profile.bank_sheba;
          this.bank_card_no = response.body.data.profile.bank_card_no;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.statistics;
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.box-icon {
  position: relative;

  i {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 60px;
    color: rgba(255, 121, 118, 0.1);
    background: #ffffff;
  }
}

.icon-Consul {
  position: relative;

  i {
    position: absolute;
    top: 25px;
    left: 20px;
    font-size: 45px;
  }
}

.consulting {
  &:hover {
    background: #ff7976;
    border-radius: 10px;

    .box-icon {
      i {
        color: #ffffff;
        background: transparent;
      }
    }

    .btn {
      background: #ff7976;
      border: 1px solid #ff7976;
      color: #ffffff;

      .icon-Consul {
        background: #ffffff;
        color: #ff7976;
      }
    }
  }

  .btn {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;

    .icon-Consul {
      background: #ff7976;
      border-radius: 13px;
      width: 100px;
      height: 100px;
      color: #fff;
      padding: 10px;
    }
  }
}

.registeredProjects {
  .box-icon {
    i {
      color: rgba(93, 218, 180, 0.1);
    }
  }

  &:hover {
    background: #5ddab4;
    border-radius: 10px;

    .box-icon {
      i {
        color: #ffffff;
        background: transparent;
      }
    }

    .btn {
      background: #5ddab4;
      border: 1px solid #5ddab4;
      color: #ffffff;

      .icon-Consul {
        background: #ffffff;
        color: #5ddab4;
      }
    }
  }

  .btn {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;

    .icon-Consul {
      background: #5ddab4;
      border-radius: 13px;
      width: 100px;
      height: 100px;
      color: #fff;
      padding: 10px;
    }
  }
}

.participations {
  &:hover {
    background: #57caeb;
    border-radius: 10px;

    .btn {
      background: #57caeb;
      border: 1px solid #57caeb;
      color: #ffffff;

      .icon-Consul {
        background: #ffffff;
        color: #57caeb;
      }
    }
  }

  .btn {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;

    .icon-Consul {
      background: #57caeb;
      border-radius: 13px;
      width: 100px;
      height: 100px;
      color: #fff;
      padding: 10px;
    }
  }
}

.total {
  .box-icon {
    i {
      color: rgba(150, 148, 255, 0.1);
    }
  }

  &:hover {
    background: #9694ff;
    border-radius: 10px;

    .box-icon {
      i {
        color: #ffffff;
        background: transparent;
      }
    }

    .btn {
      background: #9694ff;
      border: 1px solid #9694ff;
      color: #ffffff;

      .icon-Consul {
        background: #ffffff;
        color: #9694ff;
      }
    }
  }

  .btn {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;

    .icon-Consul {
      background: #9694ff;
      border-radius: 13px;
      width: 100px;
      height: 100px;
      color: #fff;
      padding: 10px;
    }
  }
}

.content-box {
  z-index: 1;

  span {
    display: block;
  }

  .content-box-name {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
  }

  .content-box-title {
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 2.2;
  }

  .content-box-description {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
  }
}

.box-content {
  &:hover {
    .content {
      .point {
        visibility: visible;
      }
    }
  }
  .content {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 0.375rem 0.75rem;

    .content-icon {
      padding: 15px;
      border-radius: 13px;
      &.greenLight {
        background: #5ddab4;
      }

      &.purple {
        background: #9694ff;
      }

      i {
        color: #ffffff;
      }
    }

    .content-title {
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        color: #616161;
      }
    }

    .point {
      position: relative;
      visibility: hidden;
      transition: all ease-in-out 0.25s;
      span {
        position: absolute;
        width: 3px;
        height: 3px;
        background: #c4c4c4;
      }

      span.point-one {
        right: 0;
        top: 0;
      }
      span.point-two {
        right: 0;
        top: 6px;
      }
      span.point-three {
        right: 0;
        top: 12px;
      }
    }

    .description {
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        color: #616161;
      }
      p {
        font-size: 12px;
        padding: 0;
        line-height: 1.5;
        margin: 5px;
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .box-icon {
    display: none;
  }
}
</style>