<template>
  <div>
    <div class="container contprofile py-5">
      <div class="row">
        <div id="payment" class="col-md-6 col-lg-8 m-auto">
          <div class="row">
            <div class="col-md-4 d-none d-md-block">
              <img
                class="img-fluid"
                src="../../../assets/img/payment/Rectangle.png"
                alt="logo"
                style="width: 100%"
              />
            </div>
            <div class="col-md form">
              <h6 class="userTitle">تقاضای وجه</h6>
              <div class="userForm">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="errorMessage != null"
                >
                  {{errorMessage}}
                </div>
                <div class="form-row moneyPayment">
                  <label>ورود مبلغ دلخواه</label>
                  <input
                    v-model="model.price"
                    type="text"
                    class="form-control"
                  />
                  <div v-for="(item, index) in error.price" :key="index">
                    <span class="message-error">{{ item }}</span>
                  </div>
                </div>
                <div class="form-group">
                  <div class="userBtn">
                    <router-link to="/profile/Dashboard" class="userBtnCancel"
                      >بازگشت به پروفایل
                    </router-link>
                    <button
                      type="button"
                      v-on:click="Submit()"
                      class="userBtnSend"
                    >
                      ثبت درخواست
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: {
        price: 0,
      },
      errorMessage: null,
      error: {
        price: [],
      },
    };
  },
  created() {},
  methods: {
    Submit() {
      this.$http.post("pay/request", this.model).then(
        (response) => {
          console.log(response.body);
          if (response.status == 200) {
            this.message = response.body.message;
          } else {
            this.errorMessage = response.body.message;
          }
        },
        (data) => {
          console.log(data);
                   if (data.status == 200) {
            this.message = data.body.message;
          } else {
            this.errorMessage = data.body.message;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.contprofile {
  margin-top: 130px;
}
a {
  text-decoration: none;
}

ul li {
  list-style: none;
}

#payment {
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .form {
    padding: 10px;
  }
}

.userTitle {
  font-weight: bold;
  margin: 25px auto;
}

.userForm {
  .form-row {
    margin-bottom: 0.5rem;
    padding: 0 15px;
  }
  .moneyPayment {
    position: relative;
    width: 98%;
    padding: 0 5px;
    margin: auto;
    label {
      width: 40%;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #ffffff;
      background: #3bcf93;
      border-radius: 0 5px 5px 0;
      height: 50px;
      font-weight: 700;
      padding: 0 7%;
    }
    input {
      width: 60%;
      display: inline-block;
      text-align: left;
      border-radius: 5px 0 0 5px !important;
      color: #3bcf93;
      font-size: 18px !important;
      font-weight: 700 !important;
    }
  }
  input[type="text"],
  input[type="number"],
  input[type="email"],
  select,
  textarea {
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: #f1f1f1;

    &:hover {
      border: 2px solid #4d5dd4;
      border-radius: 0;
    }

    &::placeholder {
      color: #9e9e9e;
      font-weight: 400;
    }
  }
  input[type="radio"] {
    // height: 70px;
  }
  textarea {
    height: 100px;
  }
  .userLable {
    color: #9e9e9e;
    background-color: #f1f1f1;
    padding: 15px 40px;
    border-radius: 5px;
  }
  .userBtn {
    float: left;
  }
  .userBtnSend {
    color: #fff;
    background-color: #3bcf93;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin-right: 30px;
  }
  .userBtnCancel {
    color: #54698d;
    font-weight: 700;
    text-decoration: none;
    border-bottom: 1px solid #54698d;
  }
}
</style>