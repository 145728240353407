<template>
  <div class="w-100">
        <div class="title">
          <button v-on:click="GoTo(5)" class="d-flex">
            <p class="cicle">5</p>
            <p class="title-step">ثبت نیاز مالی</p>
          </button>
        </div>
        <div class="body-step">
          <div class="body-stepfour">
            <form action="">
              <div class="py-3">
                <div class="row">
                  <div class="col-sm">
                    <label for="">هزینه کل پروژه</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="هزینه کل پروژه مثلا : 321000 تومان"
                        v-model="model.total_expense"
                      />
                    </div>
                  </div>
                  <div class="col-sm">
                    <label for="">وام مورد نیاز</label>
                    <div class="input-group">
                      <input
                        v-model="model.loan"
                        type="text"
                        class="form-control"
                        placeholder="وام مورد نیاز"
                      />
                    </div>
                  </div>
                  <div class="col-sm">
                    <label for="">مهلت تامین</label>
                    <div class="input-group">
                      <date-picker
                        clearable
                        class="w-100"
                        v-model="model.expierDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-3">
                <label for="">الگوی بازپرداخت</label>
                <div class="row">
                  <div class="col">
                    <div class="input-group">
                      <select
                        v-model="model.installment_type"
                        class="form-control"
                      >
                        <option
                          :value="item.id"
                          v-for="item in vam"
                          :key="item.id"
                        >
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="model.installment_no"
                        placeholder="تعداد اقساط"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right stepnext pt-4">
                <button class="btn next" type="button" v-on:click="submit()">گام بعد</button>
                <button class="btn back" v-on:click="GoTo(4)">
                  گام قبل
                </button>
              </div>
            </form>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
    id: String,
  },
  data(){
      return{
           vam: [],
      }
  },
  created() {

    this.$http.get("enum/case/vam-refound").then(
      (response) => {
        if (response.status == 200) {
          this.vam = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.vam = data.body;
        }
      }
    );

  },
  methods: {
    GoTo(index) {
      this.$emit("GoTo", index);
    },
    submit() {
      if (this.id != null) {
        this.model._method = "put";
        this.$http.post(`case/${this.id}`, this.model).then(
          (response) => {
            if (response.status == 200) {
              this.$emit("Message", response.body.message);
              this.GoTo(6);
            } else {
              this.$emit("ErrorMessage", response.data.message);
            }
          },
          (data) => {
            window.scrollTo(0, 0);
            this.$emit("ErrorMessage", data.data.message);
          }
        );
      } else {
        window.scrollTo(0, 0);
        this.$emit("ErrorMessage", "لطفا صفحه را مجدد بارگذاری کنید");
      }
    },
  },
};
</script>

<style>
</style>