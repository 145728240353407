<template>
  <div class="pt-5 mt-3">
    <div class="container py-5">
      <div class="">
        <video class="img-fluid w-100 videoPlayer" controls>
          <source
            :src="'https://karvam.ir'+item.video"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="content-wK my-5">
        <div class="w-100" v-html="item.description"></div>
        <div class="content-wK-gallery my-3 py-3">
          <div class="row">
            <div class="col-md-5 d-flex flex-wrap justify-content-around my-2">
              <img
                :src="item.top_image"
                class="img-fluid pb-3"
                alt="pic"
              />
              <img
                :src="item.botton_image"
                class="img-fluid"
                alt="pic"
              />
            </div>
            <div class="col-md-7 d-flex flex-wrap justify-content-around my-2">
              <img
                :src="item.large_image"
                class="img-fluid"
                alt="pic"
              />
            </div>
          </div>
        </div>
        <div class="content-wK-link my-4">
          <span class="content-wK-link-before"></span>
          <h6 class="content-wK-link-title">مطالب پیشنهادی :</h6>
          <router-link :to="item.suggest.link" class="ml-3 content-wK-link-description">
            {{item.suggest.title}}
          </router-link>
        </div>
        <div class="w-100" v-html="item.end_note"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        rtl: true,
        autoPlay: true,
        playSpeed: 2000,
      },
    };
  },
  created() {
    this.$http.get("help/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          console.log(response.body);
          this.item = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.item = data.body;
        }
      }
    );
  },
};
</script>
<style lang="scss" scoped>
.videoPlayer {
  background: rgba(48, 48, 48, 0.5);
  border-radius: 10px;
  img {
  }
}
.content-wK {
  .content-wK-title {
    font-weight: 700;
    color: #212121;
    font-style: normal;
    font-size: 24px;
  }

  .content-wK-description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #616161;
    text-align: justify;
  }

  .content-wK-ul {
    list-style: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #616161;
  }
  .content-wK-title-child {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #212121;
  }
  .content-wK-description-child {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #616161;
    text-align: justify;
  }
  .content-wK-link {
    position: relative;
    display: block;
    padding: 30px 40px;
    background: #f7f7f7;
    border-radius: 5px;
    .content-wK-link-before {
      position: absolute;
      width: 5px;
      height: 60px;
      background: #3bcf93;
      border-radius: 9px;
      top: 17px;
      right: 20px;
    }
    .content-wK-link-title {
      display: inline-block;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.02em;
      color: #212121;
    }
    .content-wK-link-description {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.02em;
      color: #616161;
    }
  }
}
</style>
