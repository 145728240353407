<template>
  <footer class="text-left text-lg-start secFooter">
    <!-- Grid container -->
    <div class="container py-5">
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
          <img src="../../assets/img/footer/logo.png" alt="" />
          <p class="py-3 text-justify">
            {{ about.descriptions }}
          </p>

        </div>

        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 footerTamas">
          <!-- <h5 class="text-uppercase">اطلاعات تماس</h5>
          <p class="py-3">
             <img src="../../assets/img/footer/logo.png" alt="" />
            آدرس: لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
            با استفاده از طراحان گرافیک است، چاپگرها و متون
          </p> -->

          <ul class="list-unstyled mb-0">
            <li v-for="item in data" :key="item.type">
              <img
                :src="'http://karvam.ir' + item.icon_url"
                :alt="item.lable"
              />
              {{ item.lable }}: {{ item.value }}
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0 footerEasy">
          <h5 class="text-uppercase mb-0">دسترسی آسان</h5>

          <ul class="list-unstyled">
            <li>
              <i class="fas fa-chevron-left">
                <router-link to="/about">درباره ما</router-link>
              </i>
            </li>
            <li>
              <i class="fas fa-chevron-left">
                <router-link to="/project">پروژه ها</router-link>
              </i>
            </li>
            <li>
              <i class="fas fa-chevron-left"
                >
                <router-link to="/Supports">تامین کنندگان</router-link>
                </i
              >
            </li>
            <li>
              <i class="fas fa-chevron-left">
                <router-link to="/blog">اخبار</router-link>
                </i>
            </li>
            <li>
              <i class="fas fa-chevron-left">
                <router-link to="/consultants">معرفان</router-link>
              </i>
            </li>
          </ul>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
    <!-- Grid container -->

    <!-- Copyright -->
    <div class="py-4 footerbrand text-center" style="background-color: #3bcf93">
      <p class="m-auto">تمامی حقوق اين وب سايت برای كاروام محفوظ می باشد.</p>
    </div>
    <div class="py-2 powerby" style="background-color: rgb(46 202 138)">
      <p class="m-auto">
        طراحی شده توسط
        <a href="http://ptoos.ir" target="_blank">شرکت نرم افـزاری پتـوس </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      about: {
        descriptions: "",
      },
      data: [],
    };
  },
  created() {
    this.$http.get("contact-us").then(
      (response) => {
        if (response.status == 200) {
          this.data = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body;
        }
      }
    );
    this.$http.get("about").then(
      (response) => {
        if (response.status == 200) {
          this.about = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.about = data.body;
        }
      }
    );
  },
};
</script>

<style lang="scss">
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.secFooter {
  background-color: $themeColorDark;
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    color: #fff;
  }
  .social-icons {
    i {
      color: #fff;
      font-size: 20px;
      margin-left: 20px;
    }
  }
}

.footerTamas,
.footerEasy {
  h5 {
    font-family: KalamehBold;
    font-style: normal;
    font-size: 24px;
    line-height: 27px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #fff;
  }
}

.footerTamas {
  color: #fff;
  img {
    width: 20px;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-align: right;
  }
  li {
    padding: 5px 0px;
  }
  i {
    color: $themeColorGreen;
  }
}

.footerEasy {
  li {
    color: #fff;
    border-bottom: 1px solid #5e5e5e;
    padding: 10px 0;
    a {
      color: #fff;
      text-decoration: none;
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      padding: 0px 8px;
    }
    i {
      font-size: 10px;
      color: #e5e5e5;
    }
  }
}

.footerbrand {
  p {
    text-align: center;
    font-family: KalamehBold;
    color: #fff;
    font-size: 20px;
  }
}

.powerby {
  p {
    text-align: center;

    font-family: IRANSansWeb;
    color: #fff;
    font-size: 15px;
    a {
      text-decoration: none;
      color: #fff;
      padding-left: 10px;
    }
  }
}
</style>