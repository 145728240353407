<template>
  <div class="fix-menu">
    <div class="container">
      <div class="pt-4">
        <div class="card card-bio">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="col-md">
                <div class="adviser">
                  <div class="hexagon hexagon-with-border">
                    <div class="hexagon-shape">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2"></div>
                      </div>
                    </div>
                    <div class="hexagon-shape content-panel">
                      <div class="hexagon-shape-inner">
                        <div class="hexagon-shape-inner-2">
                          <img :src="data.avatar_url" :alt="data.full_name" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="brief-biography">
                  <h3>{{ data.full_name }}</h3>
                  <h5>{{ data.post }}</h5>
                  <div class="rate">
                    {{ data.rate }}
                    <span
                      ><i
                        :class="data.rate > 1 ? 'fas fa-star' : 'far fa-star'"
                      ></i
                    ></span>
                    <span
                      ><i
                        :class="data.rate > 2 ? 'fas fa-star' : 'far fa-star'"
                      ></i
                    ></span>
                    <span
                      ><i
                        :class="data.rate > 3 ? 'fas fa-star' : 'far fa-star'"
                      ></i
                    ></span>
                    <span
                      ><i
                        :class="data.rate > 4 ? 'fas fa-star' : 'far fa-star'"
                      ></i
                    ></span>
                    <span
                      ><i
                        :class="data.rate > 5 ? 'fas fa-star' : 'far fa-star'"
                      ></i
                    ></span>
                  </div>
                  <p>
                    <i class="fal fa-hand-holding"></i>
                    تعداد مشاوره
                    <span> {{ data.total_case }} مورد </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 py-5 align-self-center">
              <div class="adviser-info px-5">
                <div class="d-flex justify-content-between">
                  <div id="line-info-1"></div>
                  <span class="py-2 pr-2"
                    ><i class="fal fa-calendar-exclamation"></i>سن:</span
                  >
                  <span class="py-2 pl-2">{{ data.age }} سال</span>
                </div>
                <div class="d-flex justify-content-between">
                  <div id="line-info-2"></div>

                  <span class="py-2 pr-2"
                    ><i class="far fa-graduation-cap"></i>تحصیلات:</span
                  >
                  <span class="py-2 pl-2">لیسانس</span>
                </div>
                <div class="d-flex justify-content-between">
                  <div id="line-info-3"></div>

                  <span class="py-2 pr-2"
                    ><i class="fal fa-map-marker-alt"></i>استان:</span
                  >
                  <span class="py-2 pl-2">{{ data.province }}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <div id="line-info-4"></div>

                  <span class="py-2 pr-2"
                    ><i class="fal fa-map-marker-alt"></i>شهر:</span
                  >
                  <span class="py-2 pl-2">{{ data.city }}</span>
                </div>
                <p v-for="item in data.attribute" :key="item">
                  <i class="fal fa-trophy-alt"></i>{{ item }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0 py-5">
      <div class="container">
        <div class="topnav">
          <button
            :class="activeMenu == 1 ? 'active' : ''"
            v-on:click="ChangeMenu(1)"
          >
            بیوگرافی
          </button>
          <button
            :class="activeMenu == 2 ? 'active' : ''"
            v-on:click="ChangeMenu(2)"
          >
            پروژه های تحت مشاوره
          </button>
          <button
            :class="activeMenu == 3 ? 'active' : ''"
            v-on:click="ChangeMenu(3)"
          >
            پروژه های تحت مالکیت
          </button>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div v-if="activeMenu == 1">
      <div class="container">
        <div class="proDescription py-4" v-html="data.bio"></div>
      </div>
    </div>
    <div v-if="activeMenu == 2">
      <div class="container text-center mb-4">
        <div class="row">
          <div
            class="alert alert-danger w-100"
            v-if="data.advisor_case.count == 0"
            role="alert"
          >
            هیچ موردی یافت نشد
          </div>
          <div
            class="col-lg-4 col-md-6 pt-4"
            v-for="item in data.advisor_case.data"
            :key="item.id"
          >
            <div class="card cardSlider cardUser border-1 rounded-0">
              <div class="relative">
                <span class="Deadline"
                  >تاریخ تامین : {{ item.info.end_time }}</span
                >
                <img
                  :src="item.images.cover"
                  class="card-img-top rounded-0 h-100"
                  :alt="item.info.title"
                  v-if="item && item.info && item.info.title"
                />
                <img src="http://karvam.ir/noimage.jpg" v-else />
              </div>

              <div class="card-body text-left">
                <div class="mb-3 jobType">
                  <span class="btn">{{ item.info.state }}</span>
                  <div class="d-inline-block ml-3 snip">
                    <i class="fal fa-map-marker-alt mr-1"></i>
                    {{ item.info.city }}
                  </div>
                </div>
                <h2>{{ item.info.title }}</h2>
                <h3
                  class="user mt-2"
                  v-if="
                    item && item.owner.profile && item.owner.profile.full_name
                  "
                >
                  توسط : {{ item.owner.profile.full_name }}
                </h3>

                <div class="jobCategory py-1">
                  <p>
                    {{ item.info.desc }}
                  </p>
                </div>

                <div class="d-flex justify-content-between price">
                  <span>
                    تامین شده: {{ formatPrice(item.finance.collected) }} تومان
                  </span>
                  <span>
                    {{
                      $Utility.GetPercent(item.finance.collected,item.info.loan)
                    }}%</span
                  >
                </div>
                <div
                  class="progress rounded-0 flex-row-reverse"
                  style="height: 10px"
                >
                  <div
                    :style="{
                      width:
                        $Utility.GetPercent(item.finance.collected,item.info.loan) + '%',
                    }"
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="moneyNeeded mt-2">
                  مبلغ مورد نیاز:
                  <span style="color: #3bcf93"
                    >{{
                      formatPrice(item.finance.loan_need - item.finance.collected)
                    }}
                    تومان</span
                  >
                </p>
                <div class="text-right userBtn mt-4">
                  <router-link class="btn" :to="'/project/single/' + item.id">
                    مشاهده و حمایت
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeMenu == 3">
      <div class="container text-center mb-4">
        <div class="row">
          <div
            class="alert alert-danger w-100"
            v-if="data.owner_case.count == 0"
            role="alert"
          >
            هیچ موردی یافت نشد
          </div>
          <div
            class="col-md-4 pt-4"
            v-for="item in data.owner_case.data"
            :key="item.id"
          >
            <div class="card cardSlider cardUser border-1 rounded-0">
              <div class="relative">
                <span class="Deadline"
                  >تاریخ تامین : {{ item.info.end_time }}</span
                >
                <img
                  :src="item.images.cover"
                  class="card-img-top rounded-0 h-100"
                  :alt="item.info.title"
                  v-if="item && item.info && item.info.title"
                />
                <img src="http://karvam.ir/noimage.jpg" v-else />
              </div>

              <div class="card-body text-left">
                <div class="mb-3 jobType">
                  <span class="btn">{{ item.info.state }}</span>
                  <div class="d-inline-block ml-3 snip">
                    <i class="fal fa-map-marker-alt mr-1"></i>
                    {{ item.info.city }}
                  </div>
                </div>
                <h2>{{ item.info.title }}</h2>
                <h3
                  class="user mt-2"
                  v-if="
                    item && item.owner.profile && item.owner.profile.full_name
                  "
                >
                  توسط : {{ item.owner.profile.full_name }}
                </h3>

                <div class="jobCategory py-1">
                  <p>
                    {{ item.info.desc }}
                  </p>
                </div>

                <div class="d-flex justify-content-between price">
                  <span>
                    تامین شده: {{ formatPrice(item.finance.collected) }} تومان
                  </span>
                  <span
                    >{{
                      $Utility.GetPercent(item.finance.collected,item.info.loan)
                    }}%</span
                  >
                </div>
                <div
                  class="progress rounded-0 flex-row-reverse"
                  style="height: 10px"
                >
                  <div
                    :style="{
                      width:
                        $Utility.GetPercent(item.finance.collected,item.info.loan) + '%',
                    }"
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="moneyNeeded mt-2">
                  مبلغ مورد نیاز:
                  <span style="color: #3bcf93"
                    >{{
                      formatPrice(item.finance.loan_need - item.finance.collected)
                    }}
                    تومان</span
                  >
                </p>
                <div class="text-right userBtn mt-4">
                  <router-link class="btn" :to="'/project/single/' + item.id">
                    مشاهده و حمایت
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      activeMenu: 1,
    };
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    ChangeMenu: function (item) {
      this.activeMenu = item;
    },
  },
  created() {
    this.$http.get("advisor/" + this.$route.params.id).then(
      (response) => {
        if (response.status == 200) {
          this.data = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.data;
        }
      }
    );
    this.$http.get("enum/province").then(
      (response) => {
        if (response.status == 200) {
          this.province = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.province = data.body;
        }
      }
    );
  },
  metaInfo() {
    return {
      title: "عنوان",
      meta: [
        {
          name: "description",
          content:
            "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
        },
        {
          property: "og:title",
          content: "Epiloge - Build your network in your field of interest",
        },
        {
          property: "og:site_name",
          content: "Epiloge",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "robots",
          content: "index,follow",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(59, 207, 147, 0.1);
  height: 100%;
}
.card-bio {
  border: 1px solid #3bcf93;
  box-sizing: border-box;
  border-radius: 10px;
}
.adviser {
  .hexagon {
    position: relative;
    display: inline-block;
    width: 234px;
    height: 210px;
  }
  .hexagon .hexagon-shape {
    position: absolute;
    right: 66px;
    top: 72px;
    overflow: hidden;
    display: inline-block;
    width: 240px;
    height: 207px;
    transform: rotate(-30deg) skewX(30deg) scale(1.19);
    border-radius: 20px;
  }
  .hexagon .hexagon-shape *,
  .hexagon .hexagon-shape *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
  .hexagon .hexagon-shape .hexagon-shape-inner {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    opacity: 1;
  }
  .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
  .hexagon .hexagon-shape .hexagon-shape-inner-2 {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: #4d5dd4;
    content: "";
  }

  .hexagon .hexagon-content .content-sub {
    color: #9f9fa0;
  }
  .hexagon.hexagon-with-border .hexagon-shape.content-panel {
    transform: rotate(-30deg) skewX(30deg) scale(1.15);
  }

  .hexagon .hexagon-shape.content-panel:first-child .hexagon-shape-inner:before,
  .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
    background: #eef9fe;
  }
  .hexagon.info .contentTitle {
    color: #54c6f3;
  }
  .hexagon-shape-inner-2 {
    img {
      transform: rotate(-61deg) skewX(30deg) scale(1);
    }
  }
  .consultantName {
    position: absolute;
    top: 116px;
    right: 111px;
    h6 {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #303030;
    }
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #303030;
    }
  }
}
.brief-biography {
  position: relative;
  top: -100px;
  right: 344px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #303030;
  }
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #afafaf;
  }
  .rate {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #303030;
    .fas {
      color: #ffc820;
    }
    .far {
      color: #afafaf;
    }
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #afafaf;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: #303030;
    }
  }
}
.adviser-info {
  border-right: 1px solid #afafaf;
  span {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #303030;
    background-color: #fff;
    position: relative;
    z-index: 99;
    i {
      color: #afafaf;
      padding-left: 5px;
    }
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    margin: 0px;
    color: #303030;
    padding: 7px 0px;

    i {
      color: #afafaf;
      padding-left: 5px;
    }
  }
  #line-info-1 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 68px;
    right: 83px;
  }
  #line-info-2 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 105px;
    right: 83px;
  }
  #line-info-3 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 142px;
    right: 83px;
  }
  #line-info-4 {
    background-color: #e5e5e5;
    width: 70%;
    height: 1px;
    position: absolute;
    top: 179px;
    right: 83px;
  }
}
.topnav {
  overflow: hidden;
  font-family: IRANSansWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  letter-spacing: 0.02em;
  button {
    float: right;
    display: block;
    color: #afafaf;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border: none;
    background: transparent;
    border-bottom: 2px solid #f1f1f1;
    &:hover {
      border-bottom: 2px solid #4d5dd4;
    }
  }
  .active {
    border-bottom: 2px solid #4d5dd4;
    color: #4d5dd4;
  }
}
.line {
  width: 100%;
  height: 3px;
  background-color: #f1f1f1;
  position: relative;
  bottom: 3px;
  z-index: -99999;
}
.proDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }
}

.proItemDescription {
  h2 {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 188.22%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #303030;

    padding-bottom: 15px;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    padding-right: 13px;
    i {
      font-size: 10px;
    }
  }
}
.listProject {
  .btnList {
    outline: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    margin: 6px;
    font-size: 22px;
    padding-top: 8px;
    color: #afafaf;
    transform: rotate(180deg);
  }
  .active {
    color: #3bcf93;
  }
}

.search {
  display: flex;
  .searchTerm {
    width: 100%;
    border: 1px solid #3bcf93;
    border-left: none;
    background: rgba(59, 207, 147, 0.1);
    padding-right: 20px;
    height: 37px;
    border-radius: 0px 5px 5px 0px;
    outline: none;
    color: #9dbfaf;
    &::placeholder {
      color: #3bcf93;
      font-weight: 500;
    }
    &:focus {
      color: #3bcf93;
      font-weight: 600;
    }
  }

  .searchButton {
    width: 40px;
    height: 37px;
    border: 1px solid #3bcf93;
    border-right: none;
    background: rgba(59, 207, 147, 0.1);
    text-align: center;
    color: #3bcf93;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
    padding-top: 6px;
    &:focus {
      outline: none;
    }
  }
}

.cardUser {
  font-family: IRANSansWeb;
  font-style: normal;
  text-align: right;
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    bottom: 12px;
    left: 15px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .jobType {
    .btn {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      background-color: #388a69;
      border-radius: 5px;
      padding: 4px 20px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #2e2e2e;
  }
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #afafaf;
  }
  .jobCategory {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      color: #5e5e5e;
    }
  }

  .price {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #afafaf;
  }

  .moneyNeeded {
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #303030;
  }
  .userBtn {
    .btn {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #fff;
      background-color: #3bcf93;
      border-radius: 5px;
      padding: 12px 22px;
      &:hover {
        background-color: #388a69;
      }
    }
  }
}
.listCard {
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    top: 350px;
    left: 39px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .card-body {
    .btn {
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      color: #fff;
      background: #388a69;
      border-radius: 5px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      color: #2e2e2e;
    }
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      color: #afafaf;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #5e5e5e;
    }
    .price {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    .moneyNeeded {
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #303030;
    }
    .hexagon {
      position: relative;
      display: inline-block;
    }
    .hexagon .hexagon-shape {
      position: absolute;
      right: -89px;
      bottom: -62px;
      overflow: hidden;
      display: inline-block;
      width: 240px;
      height: 207px;
      transform: rotate(-30deg) skewX(30deg) scale(0.3);
      border-radius: 20px;
    }
    .hexagon .hexagon-shape *,
    .hexagon .hexagon-shape *:before {
      display: block;
      overflow: hidden;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }
    .hexagon .hexagon-shape .hexagon-shape-inner {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      opacity: 1;
    }
    .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
    .hexagon .hexagon-shape .hexagon-shape-inner-2 {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      background: #4d5dd4;
      content: "";
    }

    .hexagon .hexagon-content .content-sub {
      color: #9f9fa0;
    }
    .hexagon.hexagon-with-border .hexagon-shape.content-panel {
      transform: rotate(-30deg) skewX(30deg) scale(0.28);
    }

    .hexagon
      .hexagon-shape.content-panel:first-child
      .hexagon-shape-inner:before,
    .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
      background: #eef9fe;
    }
    .hexagon.info .contentTitle {
      color: #54c6f3;
    }
    .hexagon-shape-inner-2 {
      img {
        transform: rotate(-61deg) skewX(30deg) scale(1);
      }
    }
    .consultantName {
      position: absolute;
      bottom: 21px;
      right: 111px;
      h6 {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #303030;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #303030;
      }
    }
    .userBtn {
      margin-top: 37px;
      .btn {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #fff;
        background-color: #3bcf93;
        border-radius: 5px;
        padding: 12px 22px;

        &:hover {
          background-color: #388a69;
        }
      }
    }
  }
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #3bcf93;
  color: white;
  border: 1px solid #3bcf93;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
</style>