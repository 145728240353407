<template>
  <div>
    <div class="container contprofile py-5">
      <div class="row">
        <menu-profile></menu-profile>
        <div class="col-md-6 col-lg-8">
          <!-- <div class="row">
            <div class="box-content col-lg-6 col-md-12">
              <div class="content py-3 mb-3">
                <div class="d-flex">
                  <div class="content-icon greenLight mr-3">
                    <i class="fas fa-wallet"></i>
                  </div>
                  <div class="content-title">
                    <span>کیف پول</span>
                  </div>
                  <div class="point mr-1 ml-auto">
                    <span class="point-one"></span>
                    <span class="point-two"></span>
                    <span class="point-three"></span>
                  </div>
                </div>
                <div class="description text-left mt-2">
                  <span>{{ formatPrice(amount) }} تومان</span>
                </div>
              </div>
            </div>
            <div class="box-content col-lg-6 col-md-12">
              <div class="content py-3 mb-3">
                <div class="d-flex">
                  <div class="content-icon purple mr-3">
                    <i class="fas fa-credit-card"></i>
                  </div>
                  <div class="content-title">
                    <span>اطلاعات حساب</span>
                  </div>
                  <div class="point mr-1 ml-auto">
                    <span class="point-one"></span>
                    <span class="point-two"></span>
                    <span class="point-three"></span>
                  </div>
                </div>
                <div class="description text-left mt-2">
                  <p>شماره کارت : {{ bank_card_no }}</p>
                  <p>شماره شبا : {{ bank_sheba }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="box-btn my-5">
            <router-link to="/profile/payment" class="mr-3"
              >افزایش اعتبار</router-link
            >
            <router-link to="/profile/request" class="ml-3"
              >تقاضای وجه</router-link
            >
          </div> -->
          <h6 class="title-style">تراکنش‌های اهدایی و اعتباری</h6>
          <div v-if="data == null || this.data.length == 0">
            <div class="alert alert-primary" role="alert">
              هیچ نتیجه ای یافت نشد
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-style">
              <thead>
                <tr>
                  <th>تاریخ واریز</th>
                  <th>شرح</th>
                  <th>نوع</th>
                  <th>مبلغ</th>
                  <th>وضعیت</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item.id">
                  <td class="text-nowrap">{{ item.created_at }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.amount }} تومان</td>
                  <td>
                    <label class="label-success">{{ item.status }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu from "../Shared/MenuProfile";

export default {
  components: {
    "menu-profile": menu,
  },
  data() {
    return {
      isList: true,
      show: false,
      data: {},
      amount: 0,
      bank_sheba: 0,
      bank_card_no: 0,
    };
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  created() {
    this.$http.get("user/donate").then(
      (response) => {
        if (response.status == 200) {
          this.data = response.body.data;
          console.log(this.data);
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.data;
        }
      }
    );
    this.$http
      .get("user/info?get=wallet,profile.bank_sheba,profile.bank_card_no")
      .then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.amount = response.body.wallet.amount;
            this.bank_sheba = response.body.data.profile.bank_sheba;
            this.bank_card_no = response.body.data.profile.bank_card_no;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.amount = data.body.wallet.amount;
            this.bank_sheba = data.body.data.profile.bank_sheba;
            this.bank_card_no = data.body.data.profile.bank_card_no;
          }
        }
      );
  },
};
</script>

<style lang="scss" scoped>
.contprofile {
  margin-top: 130px;
}
a {
  text-decoration: none;
}

ul li {
  list-style: none;
}

.box-btn {
  display: flex;
  justify-content: center;
  a {
    padding: 10px 25px;
    border: 1px solid #3bcf93;
    color: #3bcf93;
    border-radius: 5px;
    width: 176px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    transition: all ease 0.25s;
    &:hover {
      color: #ffffff;
      background: #3bcf93;
    }
  }
}

.title-style {
  font-weight: bold;
  margin: 25px auto;
}

.btn-suc {
  color: #ffffff;
  background: #3bcf93;
  padding: 10px 20px;
}

.btn-total {
  display: block;
  width: 100%;
  height: 50px;
  color: #ffffff;
  background: #3bcf93;
  border: none;
  border-radius: 5px;
}

.table-style {
  border-collapse: collapse;
  th,
  td {
    border: none;
    padding: 1rem;
    vertical-align: middle;
  }
  thead {
    tr {
      th {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #afafaf;
        background: #f1f1f1;
        text-align: center;
        &:after {
          content: "|";
          float: left;
          color: #dee4eb;
        }
        &:first-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:last-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:last-child:after {
          display: none;
        }
      }
    }
  }
  tbody {
    &:before {
      content: "-";
      display: block;
      line-height: 1em;
      color: transparent;
    }
    tr {
      &:hover {
        td {
          color: #4d5dd4;
        }
      }
      td {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        color: #5e5e5e;
      }
      .label-success {
        color: #3bcf93;
        padding: 10px 30px;
      }
      .label-error {
        color: #f44336;
        padding: 10px 30px;
      }
    }
  }
}

.box-content {
  &:hover {
    .content {
      .point {
        visibility: visible;
      }
    }
  }
  .content {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 0.375rem 0.75rem;

    .content-icon {
      padding: 15px;
      border-radius: 13px;
      &.greenLight {
        background: #5ddab4;
      }

      &.purple {
        background: #9694ff;
      }

      i {
        color: #ffffff;
      }
    }

    .content-title {
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        color: #616161;
      }
    }

    .point {
      position: relative;
      visibility: hidden;
      transition: all ease-in-out 0.25s;
      span {
        position: absolute;
        width: 3px;
        height: 3px;
        background: #c4c4c4;
      }

      span.point-one {
        right: 0;
        top: 0;
      }
      span.point-two {
        right: 0;
        top: 6px;
      }
      span.point-three {
        right: 0;
        top: 12px;
      }
    }

    .description {
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        color: #616161;
      }
      p {
        font-size: 12px;
        padding: 0;
        line-height: 1.5;
        margin: 5px;
      }
    }
  }
}
</style>