<template>
  <app-menu v-bind:IsScroll="this.IsScroll"></app-menu>
</template>
<script>
import menu from "./Componensts/Menu";
export default {
  name: "App",
   props: {
    IsScroll: Boolean,
  },
  components: {
    "app-menu": menu,
  },
  computed: {
    IsUserAuthenticated() {
      return this.$store.getters.IsUserAuthenticated;
    },
    UserFullName() {
      return this.$store.getters.GetUserFullName;
    }
  },
};
</script>
<style>
</style>