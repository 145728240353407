<template>
  <div>
    <div>
      <div
        class="align-items-center d-flex flex-column text-center titleHeader"
      >
        <h2 class="mt-auto">درباره ما</h2>
        <p class="mb-auto">
          درباره کاروام، مسیر راه و افرادی که کاروام را همراهی می کنند
        </p>
      </div>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6 about-karvam-pic">
          <div class="row">
            <div class="col-6 text-right about-pic-right">
              <div>
                <img :src="'http://karvam.ir'+about.images.top" class="my-2" />
                <img :src="'http://karvam.ir'+about.images.left" class="my-2" />
              </div>
              <div class="pattern-1">
                <img src="../../assets/img/about/pattern.png" alt="" />
              </div>
            </div>
            <div class="col-6 about-pic-left">
              <div>
                <img :src="'http://karvam.ir'+about.images.bottom" class="w-100" />
              </div>
              <div class="pattern-2">
                <img src="../../assets/img/about/pattern-1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 about-karvam py-4">
          <h2>درباره کاروام</h2>
          <p class="text-justify">
            {{about.descriptions}}
          </p>
          <div class="weDo">
            <p v-for="item in about.attributes" :key="item">
              <i class="fas fa-check-square"></i>
              {{item}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="titleTimeline py-5">
        <h2>مسیر راه ما</h2>
      </div>
      <div class="timeline">
        <div class="timeline-start"></div>
        <div class="container-1 my-5" v-for="item in about.steps" :key="item.title">
          <div class="content">
            <div class="timelinePic">
              <img :src="'http://karvam.ir'+item.icon_url" :alt="item.title" />
            </div>
            <h2>{{item.title}}</h2>
            <p>
              {{item.description}}
            </p>
          </div>
        </div>
        <div class="timeline-end"></div>
      </div>
    </div>
    <div class="container">
      <div class="titleTimeline mb-5">
        <h2>تیم ها</h2>
      </div>
      <div class="timgroup">
        <div class="d-flex justify-content-between tim">
          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-6 timOne text-center" v-for="item in about.teams" :key="item.full_name">
              <div class="box-image-user">
                <img class="img-fluid" :src="item.avatar_url" :alt="item.full_name" />
              </div>
              <h5>{{item.full_name}}</h5>
              <h6>{{item.post}}</h6>
            </div>
          </div>
        </div>
 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      about: {
        images: {
          top: "/images/about/top.png",
          bottom: "/images/about/bottom.png",
          left: "/images/about/left.png",
        },
        descriptions:"",
        attributes: [
          "",
        ],
        steps: [
          {
            icon_url: "",
            title: "",
            description: "",
          },
          {
            icon_url: "",
            title: "",
            description: "",
          },
          {
            icon_url: "",
            title: "",
            description: "",
          },
          {
            icon_url: "",
            title: "",
            description: "",
          },
        ],
        teams: [
          {
            full_name: "",
            avatar_url: "",
            post: "",
          },
          {
            full_name: "",
            avatar_url: "",
            post: "",
          },
          {
            full_name: "",
            avatar_url: "",
            post: "",
          },
          {
            full_name: "",
            avatar_url: "",
            post: "",
          },
          {
            full_name: "",
            avatar_url: "",
            post: "",
          },
          {
            full_name: "",
            avatar_url: "",
            post: "",
          },
        ],
      },
    };
  },
  created() {
    this.$http.get("about").then(
      (response) => {
        if (response.status == 200) {
          this.about = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.about = data.body;
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>

.titleHeader {
  margin-top: 99px;
  background-image: url("../../assets/img/about/pic-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;

  h2 {
    font-family: KalamehBold;
    color: #fff;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 67px;
    letter-spacing: 0.02em;

    &::before {
      content: url(../../assets/img/support/Rectangle.png);
      position: relative;
      left: 10px;
    }
    &::after {
      content: url(../../assets/img/support/Rectangle.png);

      position: relative;
      right: 10px;
    }
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #fff;
  }
}
.about-karvam-pic {
  .about-pic-right {
    img {
      box-shadow: 0px 0px 15px 2px rgba(6, 87, 155, 0.25);
      border-radius: 10px;
    }
    .pattern-1 {
      position: absolute;
      top: 91px;
      z-index: -1;
      right: -5px;
      img {
        box-shadow: none;
      }
    }
  }
  .about-pic-left {
    img {
      box-shadow: 0px 0px 15px 2px rgba(6, 87, 155, 0.25);
      border-radius: 10px;
      margin: 30px 0px 30px 0px;
    }
    .pattern-2 {
      position: absolute;
      top: -45px;
      z-index: -1;
      right: 64px;
      img {
        box-shadow: none;
      }
    }
  }
}
.about-karvam {
  h2 {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 150%;
    color: #303030;
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #5e5e5e;
  }
  .weDo {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 185%;
      color: #303030;
      i {
        color: #4d5dd4;
      }
    }
  }
}

.titleTimeline {
  padding: 25px 0px;

  h2 {
    font-family: KalamehBold;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #303030;
    text-align: center;

    &::before {
      content: url(../../assets/img/support/Rectangle.png);
      position: relative;
      left: 10px;
    }
    &::after {
      content: url(../../assets/img/support/Rectangle.png);
      position: relative;
      right: 10px;
    }
  }
}
.timeline {
  position: relative;
  // max-width: 1200px;
  margin: 0px auto;
  padding: 90px 0;
  &::after {
    content: "";
    position: absolute;
    width: 3px;
    background-color: #c4c4c4;
    top: 0px;
    bottom: 0;
    right: 50%;
    margin-right: 0px;
  }

  .timeline-start {
    width: 15px;
    height: 15px;
    background: #e5e5e5;
    border: 3px solid #5552d3;
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    right: calc(50% - 6px);
    z-index: 2;
    top: 0px;
  }
  .timeline-end {
    width: 15px;
    height: 15px;
    background: #e5e5e5;
    border: 3px solid #5552d3;
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    right: calc(50% - 6px);
    z-index: 2;
    bottom: 0px;
  }

  .timelinePic {
    width: 75px;
    height: 75px;
    background: #5552d3;
    border: 1px solid #3d34b9;
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    top: -18px;
    img {
      width: 40px;
      height: 40px;
      margin: 15px;
    }
  }
  .container-1 {
    padding: 10px 50px;
    position: relative;
    background-color: inherit;
    width: 50%;
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      left: -17px;
      background-color: #5552d3;
      border: 5px solid #fff;
      top: 15px;
      border-radius: 50%;
      z-index: 1;
    }
  }

  .container-1:nth-child(even) {
    right: 0;
    &::before {
      content: " ";
      height: 0;
      position: absolute;
      top: 28px;
      width: 98px;
      z-index: 1;
      left: 11px;
      border: 1px solid #e5e5e5;
    }
  }

  .container-1:nth-child(odd) {
    right: 50%;
    &::before {
      content: " ";
      height: 0;
      position: absolute;
      top: 28px;
      width: 98px;
      z-index: 1;
      right: 11px;
      border: 1px solid #e5e5e5;
    }
    &::after {
      right: -13px;
    }
  }
  .content {
    padding: 20px 20px;
    position: relative;
    background: #f6f6f6;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: IRANSansWeb;
    font-style: normal;
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 122.32%;
      color: #5552d3;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 175%;
      text-align: right;
      color: #5e5e5e;
      margin: 0px;
    }
  }
}

@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline {
    &::after {
      right: 30px;
    }
    .container-1 {
      width: 100%;
      padding-right: 70px;
      padding-left: 25px;
    }
    .container-1 {
      &::before {
        right: 29px;
        border: 1px solid #e5e5e5;
        border-width: 0px 5px 1px 0;

        // border-color: transparent white transparent transparent;
      }
    }

    .right,
    .left {
      &::after {
        right: 17px;
      }
    }
    .left {
      right: 0%;
    }
  }
}

.timgroup {
  padding: 0px 0px 80px 0px;
  .box-image-user {
    height: 160px;
    width: 160px;
    margin: auto;
  }
}
.tim {
  padding: 0px 50px;
  //display: flex;
  //flex-flow: wrap;

  .timOne {
    &:hover {
      h5 {
        color: #212121;
      }
      h6 {
        color: #5552d3;
      }
      img {
        filter: none;
        border: 4px solid #4f45d9;
      }
    }
    img {
      border: 4px solid #c4c4c4;
      box-sizing: border-box;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      filter: grayscale(100%);
    }
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 122.32%;
      color: #616161;
      padding-top: 20px;
    }
    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 122.32%;
      color: #9e9e9e;
    }
  }
}
.tim-2 {
  padding: 120px 155px !important;
}

@media (max-width: 600px) {
  .timeline .timeline-start {
    right: 24px;
  }
  .timeline .container-1:nth-child(2n+1) {
    right: 0;
  }
  .timeline .container-1::after {
    right: 17px;
  }
  .timeline .container-1:nth-child(2n+1)::after {
    right: 17px;
  }
  .timeline .timeline-end {
    right: 24px;
  }
}

@media (max-width: 991px) {
  .timeline .timelinePic {
    display: none;
  }
}

</style>