<template>
  <div class="card cardSlider cardUser border-1 rounded-0" v-if="item && item.info">
    <div class="relative" style="height: 200px; overflow: hidden">
      <span class="Deadline"> مهلت تامین {{ item.finance.loan_need_deadline }} </span>
      <img
        :src="item.media.cover"
        class="card-img-top rounded-0 h-100"
        :alt="item.media.cover"
        v-if="item && item.media && item.media.cover"
      />
      <img
        src="http://karvam.ir/noimage.jpg"
        class="card-img-top rounded-0 h-100"
        alt="no image"
        v-else
      />
    </div>

    <div class="card-body text-left">
      <div class="mb-3 jobType">
        <span class="btn">{{ item.info.master_subject }}</span>
        <div class="d-inline-block ml-3 snip">
          <i class="fal fa-map-marker-alt mr-1"></i>
          {{ item.info.state }} / {{ item.info.city }}
        </div>
      </div>
      <h2 v-bind:title="item.info.title">
          {{ textRestrictions(item.info.title, 60) }}
          </h2>
      <h3
        class="user mt-2"
        v-if="item && item.owner && item.owner.profile && item.owner.profile.full_name"
      >
        توسط : {{ item.owner.profile.full_name }}
      </h3>

      <div class="jobCategory py-1">
        <p style="min-height: 90px; overflow: hidden">
          {{ item.info.desc }}
        </p>
      </div>

      <div class="d-flex justify-content-between price"
      v-if="item.finance">
        <span>
          تامین شده: {{ formatPrice(item.finance.collected) }} تومان
        </span>
        <span
          >{{
            $Utility.GetPercent(item.finance.collected, item.finance.loan_need)
          }}%</span
        >
      </div>
      <div class="progress rounded-0 flex-row-reverse" style="height: 10px">
        <div
          :style="{
            width:
              $Utility.GetPercent(item.finance.collected, item.finance.loan_need) + '%',
          }"
          class="progress-bar progress-bar-striped"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <p class="moneyNeeded mt-2">
        مبلغ مورد نیاز:
        <span style="color: #3bcf93"
          >{{
            formatPrice(item.finance.loan_need - item.finance.collected)
          }}
          تومان</span
        >
      </p>
      <div class="text-right userBtn mt-4">
        <router-link
          :to="link"
          class="btn d-inline-block"
        >
          مشاهده و حمایت
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    link:String
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
        textRestrictions(text, value) {
      let length = text.length;
      if (length >= value) {
        return text.substr(0, value) + "...";
      } else {
        return text;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
* {
  scroll-behavior: smooth;
}
.cardUser {
  font-family: IRANSansWeb;
  font-style: normal;
  text-align: right;
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    bottom: 18px;
    left: 18px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .jobType {
    .btn {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      background-color: #388a69;
      border-radius: 5px;
      padding: 4px 20px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #2e2e2e;
  }
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #afafaf;
  }
  .jobCategory {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      color: #5e5e5e;
    }
  }

  .price {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #afafaf;
  }

  .moneyNeeded {
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #303030;
  }
  .userBtn {
    .btn {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #fff;
      background-color: #3bcf93;
      border-radius: 5px;
      padding: 12px 22px;
      &:hover {
        background-color: #388a69;
      }
    }
  }
}

.listCard {
  &:hover {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
  .Deadline {
    position: absolute;
    bottom: 15px;
    left: 30px;
    background-color: rgba(77, 93, 212, 0.6);
    border-radius: 5px;
    color: #fff;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
  }
  .card-body {
    .btn {
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      color: #fff;
      background: #388a69;
      border-radius: 5px;
    }
    .snip {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      color: #2e2e2e;
    }
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      color: #afafaf;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #5e5e5e;
    }
    .price {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: #afafaf;
    }
    .moneyNeeded {
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      color: #303030;
    }
    .hexagon {
      position: relative;
      display: inline-block;
    }
    .hexagon .hexagon-shape {
      position: absolute;
      right: -89px;
      bottom: -62px;
      overflow: hidden;
      display: inline-block;
      width: 240px;
      height: 207px;
      transform: rotate(-30deg) skewX(30deg) scale(0.3);
      border-radius: 20px;
    }
    .hexagon .hexagon-shape *,
    .hexagon .hexagon-shape *:before {
      display: block;
      overflow: hidden;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }
    .hexagon .hexagon-shape .hexagon-shape-inner {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      opacity: 1;
    }
    .hexagon .hexagon-shape:first-child .hexagon-shape-inner:before,
    .hexagon .hexagon-shape .hexagon-shape-inner-2 {
      transform: skewX(-30deg) rotate(60deg) skewX(30deg);
      background: #4d5dd4;
      content: "";
    }

    .hexagon .hexagon-content .content-sub {
      color: #9f9fa0;
    }
    .hexagon.hexagon-with-border .hexagon-shape.content-panel {
      transform: rotate(-30deg) skewX(30deg) scale(0.28);
    }

    .hexagon
      .hexagon-shape.content-panel:first-child
      .hexagon-shape-inner:before,
    .hexagon.info .hexagon-shape.content-panel .hexagon-shape-inner-2 {
      background: #eef9fe;
    }
    .hexagon.info .contentTitle {
      color: #54c6f3;
    }
    .hexagon-shape-inner-2 {
      img {
        transform: rotate(-61deg) skewX(30deg) scale(1);
      }
    }
    .consultantName {
      position: absolute;
      bottom: 21px;
      right: 111px;
      h6 {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #303030;
      }
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #303030;
      }
    }
    .userBtn {
      margin-top: 37px;
      .btn {
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #fff;
        background-color: #3bcf93;
        border-radius: 5px;
        padding: 12px 22px;

        &:hover {
          background-color: #388a69;
        }
      }
    }
  }
}
</style>