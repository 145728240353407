
import Index from './Pages/Home/Index.vue';
import regitser from './Pages/Account/Register.vue';
import login from './Pages/Account/Login.vue';
import projects from './Pages/Projects/Index.vue';
import detailProject from './Pages/Projects/Details.vue';
import consultants from './Pages/Consultants/Index.vue';
import detailConsultants from './Pages/Consultants/Details.vue';
import Supports from './Pages/Supports/Index.vue';
import detailSupports from './Pages/Supports/Details.vue';
import blog from './Pages/Blog/Index.vue';
import single from './Pages/Blog/Single.vue';
import about from './Pages/About/Index.vue';
import createproject from './Pages/CreateProject/Index.vue';
import editproject from './Pages/EditProject/Index.vue';
import profile from './Pages/Profile/Index.vue';
import dashboard from './Pages/Profile/Dashboard/Dashboard.vue';
import request from './Pages/Profile/Request/index.vue';
import editUser from './Pages/Profile/User/Edit.vue';
import projectsUser from './Pages/Profile/Projects/Projects.vue';
import projectShow from './Pages/Profile/Projects/Showproject.vue';
import contributions from './Pages/Profile/Contributions/Contributions.vue';
import GiftTransactions from './Pages/Profile/loan/Gift-transactions.vue';
import QarzTransactions from './Pages/Profile/loan/Qarz-transactions.vue';
import DefiniteTransactions from './Pages/Profile/loan/Definite-transactions.vue';
import payment from './Pages/Profile/Payment/Payment.vue';
import karvam from './Pages/Profile/Payment/Karvam.vue';
import PageNotFound from './Pages/Error/Notfound.vue';
import WhayKarvam from './Pages/WhayKarvam/WhayKarvam.vue';

export const Routes = [
  {
    name: "index",
    path: '/',
    component: Index,
  },
  {
    path: '/register',
    component: regitser,

  },
  {
    path: '/login',
    component: login,
  },
  {
    path: '/project',
    component: projects,
  },
  {
    path: '/project/single/:id',
    component: detailProject,
  },
  {
    path: '/consultants',
    component: consultants,
  },
  {
    path: '/consultants/single/:id',
    component: detailConsultants,
  },
  {
    path: '/Supports',
    component: Supports,
  },
  {
    path: '/Supports/single/:id',
    component: detailSupports,
  },
  
  {
    path: '/blog',
    component: blog,
  },
  {
    path: '/blog/single/:id',
    component: single,
  },
  {
    path: '/about',
    component: about,
  },
  {
    name:"create-project",
    path: '/project/create/:id',
    component: createproject,
  },
  {
    path: '/project/create',
    component: createproject,
  },
  {
    path: '/project/edit/:id',
    component: editproject,
  },
  {
    path: '/profile',
    component: profile,
  },
  {
    path: '/profile/dashboard',
    component: dashboard,
    name: "profile",
  },
  {
    path: '/profile/request',
    component: request,
  },
  {
    path: '/profile/User/Edit',
    component: editUser,
  },
  {
    path: '/profile/Projects',
    component: projectsUser,
  },
  {
    path: '/profile/Showproject/:id',
    component: projectShow,
  },
  {
    path: '/profile/contribution',
    component: contributions,
  },
  {
    path: '/profile/Gift-transactions',
    component: GiftTransactions,
  },
  {
    path: '/profile/Qarz-transactions',
    component: QarzTransactions,
  },
  {
    path: '/profile/Definite-transactions',
    component: DefiniteTransactions,
  },
  {
    path: '/profile/payment',
    component: payment,
  },
  {
    path: '/profile/karvam',
    component: karvam,
  },
  {
    path: '/page/:id',
    component: WhayKarvam,
  },
  {  path: "*", component: PageNotFound }
];
