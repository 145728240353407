<template>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-4  py-4 cardProjrct">
          <div class="imgfluid">
            <img src="../../../assets/img/card/Union1.svg" alt="" />
          </div>

          <div class="cardservic">
            <p><router-link to="/page/what2do">کاروام چه کار می کند؟</router-link></p>
       
          </div>
        </div>
        <div class="col-12 col-md-4 py-4 cardProjrct">
          <div class="imgfluid">
            <img src="../../../assets/img/card/Union2.svg" alt="" />
          </div>
          <div class="cardservic">
            <p><router-link to="/page/how2loan">چگونه به یک پروژه قرض بدهیم؟</router-link></p>
          </div>
        </div>
        <div class="col-12 col-md-4  py-4 cardProjrct">
          <div class="imgfluid">
            <img src="../../../assets/img/card/Union3.svg" alt="" />
          </div>
          <div class="cardservic">
            <p><router-link to="/page/how2cp">چگونه یک پروژه ایجاد کنیم؟</router-link></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;

.cardProjrct {
  background-color: $themeColorGreen;
  text-align: center;
  padding-right: 20px;

  &:hover {
    background-color: $themeColorDark;
  }

  img {
    width: 45px;
    margin: 17px;
  }

  p {
    a {
      text-decoration: none;
      color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.02em;

      &:hover {
        color: #fff;
      }
    }
  }

  .cardservic {
    text-align: right;
    display: inline-grid;
    .tozihat {
      p {
        text-align: right;
        a {
          font-weight: 500;
          font-size: 14px;
        }

        i {
          padding: 5px;
        }
      }
    }
  }
}
</style>