<template>
  <div class="w-100">
    <div class="title">
      <button v-on:click="GoTo(4)" class="d-flex">
        <p class="cicle">4</p>
        <p class="title-step">انتخاب معرف</p>
      </button>
    </div>
    <div class="body-step">
      <div class="body-stepfour">
        <div class="py-3">
          <label for="">معرف</label>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <select v-model="model.advisor_id" class="form-control">
                  <option
                    :value="item.id"
                    v-for="item in advisor"
                    :key="item.id"
                  >
                    {{ item.full_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right stepnext pt-4">
          <button class="btn next" v-on:click="submit()">گام بعد</button>
          <button class="btn back" v-on:click="GoTo(3)">گام قبل</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
    id: String,
  },
  data(){
      return{
        advisor: [],
      }
  },
  created() {

    this.$http.get("advisor").then(
      (response) => {
        if (response.status == 200) {
          this.advisor = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.advisor = data.body;
        }
      }
    );

  },
  methods: {
    GoTo(index) {
      this.$emit("GoTo", index);
    },
    submit() {
      if (this.id != null) {
        this.model._method = "put";
        this.$http.post(`case/${this.id}`, this.model).then(
          (response) => {
            if (response.status == 200) {
              this.$emit("Message", response.body.message);
              this.GoTo(5);
            } else {
              this.$emit("ErrorMessage", response.data.message);
            }
          },
          (data) => {
            window.scrollTo(0, 0);
            this.$emit("ErrorMessage", data.data.message);
          }
        );
      } else {
        window.scrollTo(0, 0);
        this.$emit("ErrorMessage", "لطفا صفحه را مجدد بارگذاری کنید");
      }
    },
  },
};
</script>

<style>
</style>