<template>
  <div class="w-100">
    <div class="title">
      <button v-on:click="GoTo(2)" class="d-flex">
        <p class="cicle">2</p>
        <p class="title-step">معرفی طرح</p>
      </button>
    </div>
    <div class="body-step">
      <div class="body-steptwo">
        <form>
          <div class="form-group">
            <label for="exampleFormControlInput1">عنوان طرح</label>
            <input v-model="model.title" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <div class="row my-3">
              <div class="col">
                <div class="form-group">
                  <label for="exampleFormControlInput1">تاریخ شروع</label>
                  <date-picker clearable v-model="model.start_time" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleFormControlInput1">تاریخ پایان</label>
                  <date-picker clearable v-model="model.end_time" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">شرح مختصر</label>
            <textarea
              class="form-control"
              v-model="model.desc"
              rows="3"
              placeholder="شرح مختصر کسب و کار (ایده، روش اجرا، اهداف و...)"
            ></textarea>
          </div>

          <div class="text-right stepnext pt-4">
            <button class="btn next" type="button" v-on:click="submit()">
              ثبت
            </button>
            <button class="btn back" type="button" v-on:click="GoTo(1)">
              گام قبل
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {},
    id: String,
  },
  created() {},
  methods: {
    GoTo(index) {
      this.$emit("GoTo", index);
    },
    submit() {
      if (this.id != null) {
        this.model._method = "put";
        this.$http.post(`case/${this.id}`, this.model).then(
          (response) => {
            if (response.status == 200) {
              this.$emit("Message", response.body.message);
              this.$emit("IsSubmit", true);
              this.GoTo(3);
            } else {
              this.$emit("ErrorMessage", response.data.message);
            }
          },
          (data) => {
            window.scrollTo(0, 0);
            this.$emit("ErrorMessage", data.data.message);
          }
        );
      }
      else {
        this.$http.post("case", this.model).then(
          (response) => {
            if (response.status == 200) {
              window.scrollTo(0, 0);
              this.$emit("Message", response.body.message);
              this.$emit("setId", response.body.data.id);
              this.$emit("IsSubmit", true);
              this.GoTo(3);
              // window.location.href = "https://karvam.ptoos.ir/project/create/"+response.body.data.id+"#step-3";
              this.$emit("editUrl", response.body.data.id);
            } else {
              this.$emit("ErrorMessage", response.data.message);
            }
          },
          (data) => {
            window.scrollTo(0, 0);
            this.$emit("ErrorMessage", data.data.message);
          }
        );
      }
    },
  },
};
</script>

<style>
</style>