<template>
  <div>
    <div class="align-items-center d-flex flex-column text-center titleHeader">
      <h2 class="mt-auto">ایجاد پروژه جدید</h2>
      <p class="mb-auto">برای ثبت پروژه جدید لطفا مراحل زیر را انجام دهید</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.titleHeader {
  margin-top: 99px;
  background-image: url("../../../assets/img/createproject/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;

  h2 {
    font-family: KalamehBold;
    color: #fff;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 67px;
    letter-spacing: 0.02em;

    &::before {
      content: url(../../../assets/img/support/Rectangle.png);
      position: relative;
      left: 10px;
    }
    &::after {
      content: url(../../../assets/img/support/Rectangle.png);

      position: relative;
      right: 10px;
    }
  }
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #fff;
  }
}
</style>