<template>
  <div class="w-100">
    <div class="title">
      <button v-on:click="GoTo(1)" class="d-flex">
        <p class="cicle">1</p>
        <p class="title-step">قوانین و مقررات</p>
      </button>
    </div>
    <div class="body-step">
      <div class="body-stepone">
        <div v-html="license.text"></div>
        <div class="text-right agreement pt-4">
          <button class="btn agree" type="button" v-on:click="submit()">
            موافقم
          </button>
          <button class="btn cancel" type="button" @click.prevent="prevStep">
            انصراف
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      license: {},
    };
  },
  created(){
    this.$http.get("enum/case/license").then(
      (response) => {
        if (response.status == 200) {
          this.license = response.body;
        
        }
      },
      (data) => {
        if (data.status == 200) {
          this.list = data.body;
        }
      }
    );
  },
  methods: {
    GoTo(index) {
        this.$emit("Message", "");
        this.$emit("ErrorMessage", "");
      this.$emit("GoTo", index);
    },
    submit(){
        this.$emit("license_id", this.license.id);
        this.GoTo(2);
    }
  },
};
</script>

<style>
</style>