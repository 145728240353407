<template>
  <div class="app">
       <hooper :settings="hooperSettings">
      <slide v-for="(item, index) in list" class="" :key="index">
        <div
          class="headerBanner"
          :style="{ 'background-image': 'url(' + item.image + ')' }"
        >
          <div
            class="rtl container align-items-center d-flex bd-highlight h-100"
          >
            <div class="">
              <h1 class="flex-row" v-html="item.title"></h1>
              <h2 class="flex-row" v-html="item.desc"></h2>
              <!-- <router-link to="/project/create" class="btn btnIde">ثبت ایده جدید</router-link> -->
            </div>
          </div>
        </div>
      </slide>
        <hooper-pagination slot="hooper-addons"  mode="fraction"></hooper-pagination>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide,Navigation as HooperPagination  } from "hooper";
import "hooper/dist/hooper.css";
export default {
  data() {
    return {
      list: [
        {
          desc: null,
          id: 8,
          image: "https://www.pixsy.com/wp-content/uploads/2021/04/ben-sweet-2LowviVHZ-E-unsplash-1.jpeg",
          is_visible: 1,
          title: null,
        },
        {
          desc: "تامین پروژه‌های کارآفرینی خرد",
          id: 7,
          image: "https://www.pixsy.com/wp-content/uploads/2021/04/ben-sweet-2LowviVHZ-E-unsplash-1.jpeg",
          is_visible: 1,
          title: "تامین مالی پروژه‌های کارآفرینی خرد",
        },
        {
          desc: "تامین مالی پروژه‌های خرد",
          id: 6,
          image: "",
          is_visible: 1,
          title: "تامین مالی پروژه‌های کارآفرینی خرد",
        },
      ],
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        rtl: true,
        autoPlay: true,
        playSpeed: 2000,
      },
      h1Text: `<span>ایـده هـات</span> بیـار اینجا <br />تا تبدیل به <span>کار</span> بشه`,
      h2Text: `ماجرای ایده تو در میان دوستان کاروامی مطرح <br />میشه و براش سرمایه پیدا میشه`,
    };
  },
  created() {
    this.$http.get("banners").then(
      (response) => {
        if (response.status == 200) {
          this.list = response.body.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.list = data.body.data;
        }
      }
    );
  },
  components: {    
    Hooper,
    Slide, 
    HooperPagination
  },
  computed: {
    filteredItems() {
      return this.list.filter((item) => {
        return item.image != null;
      });
    },
  },
};
</script>

<style lang="scss">
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;

.hooper{
  height: auto !important;
}
.agile__slide--active {
  z-index: 99999999999999999999999999999;
}
.app {
  display: flex;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin: 0 auto;
  max-width: 100%;
  height: 100vh;
  direction: ltr;
  .agile__actions {
    top: auto;
    bottom: 15px !important;
    .agile__dot--current button, .agile__dot:hover button{
      background-color: #fff;
    }
    .agile__dot--current button, .app .agile__actions .agile__dot:hover button{
      background-color: #fff !important;
    }
    .agile__dot{
      button{
        border-radius:0 !important;
        width: 60px !important;
        height: 5px !important;
        background-color:rgba(255,255,255, 0.5)
      }
    }
  }
}
.agile__list {
  height: 100%;
}
.agile__track {
  height: 100vh;
}
.agile {
  width: 100%;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.slide {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100vh;
  background-position: center;
  background-size: cover;
  justify-content: center;
}
.slide h3 {
  font-size: 32px;
  font-weight: 300;
}
.headerBanner {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  h1 {
    color: $themeColorLight;
    font-family: KalamehBold;
    font-size: 10vh;
    font-weight: 900;
    line-height: 10vh;
    letter-spacing: 0.02em;
    padding-top: 50px;

    span {
      color: $themeColorGreen;
    }
  }

  h2 {
    color: $themeColorLight;
    font-family: KalamehRegular;
    font-size: 5vh;
    font-style: normal;
    font-weight: normal;
    margin: 20px 0px;
    line-height: 5vh;
    letter-spacing: 0.02em;
  }

  .btnIde {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #ffffff;
    background: #3bcf93;
    border-radius: 5px;
    padding: 10px 15px;
    &:hover {
      background-color: #388a69;
      color: #fff;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
</style>