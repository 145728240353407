<template>
  <div class="w-100">
    <div class="title">
      <button v-on:click="GoTo(3)" class="d-flex">
        <p class="cicle">3</p>
        <p class="title-step">مختصات جغرافیایی</p>
      </button>
    </div>

    <div class="body-step">
      <div class="body-stepthree">
        <div class="row">
          <div class="col-sm-6">
            <p>استان</p>
            <div class="form-group">
              <select
                class="form-control bg-light text-secondary"
                @change="provinceChange($event)"
                v-model="model.state"
              >
                <option
                  :value="item.id"
                  v-for="item in province"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <p>شهر</p>
            <div class="form-group">
              <select
                class="form-control bg-light text-secondary"
                v-model="model.city"
              >
                <option :value="item.id" v-for="item in city" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <p>منطقه</p>
            <div class="form-group">
              <input
                type="text"
                v-model="model.area"
                class="form-control"
                placeholder="منطقه"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <p>کد پستی</p>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="model.postal_code"
                placeholder="کدپستی"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">آدرس </label>
          <textarea
            class="form-control"
            rows="3"
            placeholder="شرح آدرس "
            v-model="model.address"
          ></textarea>
        </div>
        <div class="text-right stepnext pt-4">
          <button class="btn next" v-on:click="submit()">گام بعد</button>
          <button class="btn back" v-on:click="GoTo(2)">گام قبل</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
    id: String,
    province: Array,
    city: Array,
  },
  created() {

  },
  methods: {
    GoTo(index) {
      this.$emit("GoTo", index);
    },
    submit() {
      if (this.id != null) {
        this.model._method = "put";
        this.$http.post(`case/${this.id}`, this.model).then(
          (response) => {
            if (response.status == 200) {
              this.$emit("Message", response.body.message);
              this.GoTo(4);
            } else {
              this.$emit("ErrorMessage", response.data.message);
            }
          },
          (data) => {
            window.scrollTo(0, 0);
            this.$emit("ErrorMessage", data.data.message);
          }
        );
      } else {
        window.scrollTo(0, 0);
        this.$emit("ErrorMessage", "لطفا صفحه را مجدد بارگذاری کنید");
      }
    },
    provinceChange: function (event) {
      this.city = [];
      this.$http.get("enum/province/" + event.target.value).then(
        (response) => {
          if (response.status == 200) {
            this.city = response.body.cities;
          }
        },
        (data) => {
          if (data.status == 200) {
            this.city = data.body.cities;
          }
        }
      );
    },
  },
};
</script>

<style>
</style>