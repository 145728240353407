export class Utility{
    constructor(){

    }
    static GetPercent(val1,val2){
        var number1=0;
        var number2 =0;
        if(val1 != null){
            number1 = parseInt(val1);
        }
        if(val2 != null){
            number2 = parseInt(val2);
        }
        if(number2 <= 0){
            return 0;
        }
        return Number(((number1 / number2) *100).toFixed(1));
    }
}