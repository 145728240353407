<template>
  <div>
    <div class="container contprofile py-5">
      <div class="row">
        <menu-profile></menu-profile>
        <div class="col-md-6 col-lg-8">
          <div v-if="data == null || this.data.length == 0">
            <div class="alert alert-primary" role="alert">
              هیچ نتیجه ای یافت نشد
            </div>
          </div>
          <div
            class="contribution Contribution-again my-4"
            v-for="item in data"
            :key="item.id"
          >
            <div class="form-row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <img
                  class="image-full"
                  :src="item.images.cover"
                  :alt="item.info.title"
                />
              </div>
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="mt-2 d-flex">
                  <button class="title">رستوران</button>
                  <div class="pr-3 ml-auto location">
                    <i class="fal fa-map-marker-alt mr-1"></i> مشهد
                  </div>
                </div>
                <h2 class="text-weight cart-title my-3">
                  {{ item.info.title }}
                </h2>
                <h3
                  class="my-1 cart-name"
                  v-if="item.advisor != null && item.advisor.profile != null"
                >
                  توسط : {{ item.advisor.profile.full_name }}
                </h3>
                <h3 class="my-1 cart-name" v-else>توسط :</h3>
                <div>
                  <div class="d-flex justify-content-between box-progress pt-2">
                    <span class="title-progress mb-1">میزان پیشرفت پروژه</span>
                    <span class="number-progress mb-1"
                      >{{
                        $Utility.GetPercent(item.finance.collected,item.info.loan)
                      }}%</span
                    >
                  </div>
                </div>
                <div
                  class="progress rounded-0 flex-row-reverse"
                  style="height: 10px"
                >
                  <div
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar bg-primary"
                    :style="{
                      width:
                        $Utility.GetPercent(item.finance.collected,item.info.loan) + '%',
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="box-description my-3">
              <p class="content-desc">
                <span>مبلغ مورد نیاز:</span>
                <span class="number"
                  >{{ formatPrice(item.finance.loan_need_need) }} تومان</span
                >
              </p>
              <p class="content-desc">
                <span>میزان مشارکت شما:</span>
                <span class="number"
                  >{{ formatPrice(item.finance.remaining) }} تومان</span
                >
              </p>
              <p class="content-desc">
                <span>مهلت تامین:</span>
                <span class="number">{{ item.finance.loan_need_deadline }}</span>
              </p>
            </div>
            <div class="box-description-link my-4">
              <router-link to="/" class="link-again">مشارکت مجدد</router-link>
              <router-link to="/" class="link-back">بازگشت وجه</router-link>
            </div>
          </div>
          <!-- <div class="contribution Contribution-success my-4">
            <div class="form-row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <img class="image-full" src="../../../assets/img/Projects/Rectangle 10.png" alt="pic">
              </div>
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="mt-2 d-flex">
                  <router-link target="_blank" to="/" class="title">رستوران</router-link>
                  <div class="pr-3 ml-auto location">
                    <i class="fal fa-map-marker-alt mr-1"></i> مشهد
                  </div>
                </div>
                <h2 class="text-weight cart-title my-3">ایجاد مغازه سوپر مارکتی</h2>
                <h3 class="my-1 cart-name">توسط : ممد آقا غلامی</h3>
                <div>
                  <div class="d-flex justify-content-between box-progress pt-2">
                    <span class="title-progress mb-1 bc-success">میزان پیشرفت پروژه</span>
                    <span class="number-progress mb-1 bc-success">100%</span>
                  </div>
                </div>
                <div class="progress rounded-0 flex-row-reverse" style="height: 10px;">
                  <div role="progressbar" aria-valuemin="0" aria-valuemax="100"
                       class="progress-bar bg-success" style="width: 100%;"></div>
                </div>
              </div>
            </div>
            <div class="box-description my-3">
              <p class="content-desc">
                <span>مبلغ مورد نیاز:</span>
                <span class="number">27,000,000 تومان</span>
              </p>
              <p class="content-desc">
                <span>میزان مشارکت شما:</span>
                <span class="number">10,000,000 تومان</span>
              </p>
              <p class="content-desc">
                <span>مهلت تامین:</span>
                <span class="number">27 روز مانده</span>
              </p>
            </div>
            <div class="box-description-link my-4">
              <router-link to="/" class="link-success">تامین شد</router-link>
              <router-link to="/" class="link-back">بازگشت وجه</router-link>
            </div>
          </div>
          <div class="contribution Contribution-error my-4">
            <div class="form-row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <img class="image-full" src="../../../assets/img/Projects/Rectangle 10.png" alt="pic">
              </div>
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="mt-2 d-flex">
                  <router-link target="_blank" to="/" class="title">رستوران</router-link>
                  <div class="pr-3 ml-auto location">
                    <i class="fal fa-map-marker-alt mr-1"></i> مشهد
                  </div>
                </div>
                <h2 class="text-weight cart-title my-3">ایجاد مغازه سوپر مارکتی</h2>
                <h3 class="my-1 cart-name">توسط : ممد آقا غلامی</h3>
                <div>
                  <div class="d-flex justify-content-between box-progress pt-2">
                    <span class="title-progress mb-1 bc-danger">موفق نشد</span>
                    <span class="number-progress bc-danger mb-1">80%</span>
                  </div>
                </div>
                <div class="progress rounded-0 flex-row-reverse" style="height: 10px;">
                  <div role="progressbar" aria-valuemin="0" aria-valuemax="100"
                       class="progress-bar bg-danger" style="width: 80%;"></div>
                </div>
              </div>
            </div>
            <div class="box-description my-3">
              <p class="content-desc">
                <span>مبلغ مورد نیاز:</span>
                <span class="number">27,000,000 تومان</span>
              </p>
              <p class="content-desc">
                <span>میزان مشارکت شما:</span>
                <span class="number">10,000,000 تومان</span>
              </p>
              <p class="content-desc">
                <span>مهلت تامین:</span>
                <span class="number">27 روز مانده</span>
              </p>
            </div>
            <div class="box-description-link my-4">
              <router-link to="/" class="link-error">موفق نشد</router-link>
              <router-link to="/" class="link-back">بازگشت وجه</router-link>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu from "../Shared/MenuProfile";

export default {
  data() {
    return {
      isList: true,
      show: false,
      data: [],
      page: {
        current_page: 0,
        from: 0,
        last_page: 0,
      },
    };
  },
  methods: {
    formatPrice(value) {
      if (value < 0) {
        value = 0;
      }
      let val = value / 1;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  created() {
    this.$http.get("user/case").then(
      (response) => {
        if (response.status == 200) {
          console.log(response.body.owner);
          this.data = response.body.owner.data;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.data = data.body.owner.data;
        }
      }
    );
  },
  components: {
    "menu-profile": menu,
  },
};
</script>

<style lang="scss" scoped>
.contprofile {
  margin-top: 130px;
}

.image-full {
  max-width: 100%;
  width: 100%;
}
.bc-danger {
  color: #f44336;
}
.bc-success {
  color: #388a69;
}
.contribution {
  position: relative;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 20px 25px;

  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }
  img {
    border-radius: 10px;
  }
  a {
    text-decoration: none;
  }
  .title {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
    background: #388a69;
    border-radius: 5px;
    padding: 5px 20px;
  }
  .location {
    color: #afafaf;
    font-size: 12px;
  }
  .cart-title {
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
  }
  .cart-name {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #afafaf;
  }
  .box-progress {
    font-style: normal;
    font-size: 12px;
    color: #afafaf;
  }
  .box-description {
    background: #f1f1f1;
    text-align: center;
    min-height: 50px;
    padding: 10px 15px;
    .content-desc {
      display: inline-block;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin: 1px 10px;
      .number {
        color: #3bcf93;
        margin-right: 10px;
      }
    }
  }
  .box-description-link {
    text-align: center;
    a {
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      padding: 12px 40px;
      box-sizing: border-box;
      border-radius: 5px;
      color: #3bcf93;
      margin: 10px 20px;
      &:hover {
        border: 1px solid #4d5dd4;
      }
      &.link-back {
        border: 1px solid #3bcf93;
        &:hover {
          border: 1px solid #4d5dd4;
        }
      }
      &.link-again {
        color: #ffffff;
        background: #3bcf93;
      }
      &.link-success {
        background: url("../../../assets/img/Projects/stamp-suscess.png");
        color: #4d5dd4;
      }
      &.link-error {
        background: url("../../../assets/img/Projects/stamp-denger.png");
        color: #f44336;
      }
    }
  }
}
</style>