<template>
  <section class="groupNewsmail py-3">
    <div class="container">
      <div class="titleNewmail">
        <h2>عضویت در خبرنامه</h2>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="content py-3">
            <div>
              <p>
                {{ message }}
              </p>
              <div class="w-100 text-center" v-if="resultMessage != null">
                {{ resultMessage }}
              </div>
              <div class="input-group" v-else>
                <span class="input-group-btn">
                  <button class="btn" type="button" v-on:click="send()">
                    عضویت
                  </button>
                </span>
                <input
                  type="email"
                  class="form-control"
                  placeholder=" مثلا:  test@mail.com"
                  v-model="email"
                />
              </div>
              <div
                v-for="(item, index) in error.email"
                :key="index"
                class="w-100"
              >
                <span class="message-error">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      error:[],
      message:
        "برای مطلع شدن از آخرين اخبار كاروام و عضويت در خبرنامه، ايميل خود را وارد نماييد.",
      resultMessage: null,
    };
  },
  methods: {
    send: function () {
      this.error=[];
      this.$http.post("subscribe", { email: this.email }).then(
        (response) => {
          this.resultMessage = response.body.message;
        },
        (data) => {
          this.error = data.body.errors;
        }
      );
    },
  },
};
</script>

<style lang="scss">
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #f7f7f7;
$textColorDark: #303030;
.groupNewsmail {
  text-align: center;
  background-color: $themeColorLight;

  .titleNewmail {
    background-image: url("../../../assets/img/newsmail/Vector.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 40px;
    h2 {
      font-family: KalamehBold;
      color: $themeColorDark;
      &::before {
        content: url(../../../assets/img/support/Rectangle.png);
        position: relative;
        left: 10px;
      }
      &::after {
        content: url(../../../assets/img/support/Rectangle.png);

        position: relative;
        right: 10px;
      }
    }
  }
}
.content {
  max-width: 605px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
  p {
    font-family: IRANSansWeb;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 0px;
  }
  .form-control {
    height: 65px;
    border-color: #ffffff;
    border-radius: 5px 0px 0px 5px;

    &::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: right;
    }
    &:focus {
      box-shadow: none;

      border: 2px solid $themeColorGreen;
    }
  }
  .btn {
    min-height: 65px;
    border-radius: 0px 5px 5px 0px;
    background: $themeColorGreen;
    color: #fff;
    font-weight: 600;
    padding: 0px 40px;
    &:focus {
      box-shadow: none;
    }
  }
}
</style>