import Vue from 'vue';
import VueRouter from 'vue-router';
import 'bootstrap-v4-rtl';
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.css';
import App from './App.vue';
import VueMeta from 'vue-meta';
import './assets/css/all.min.css';
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueResource from 'vue-resource';
import VueCookie from 'vue-cookie';
import { Routes } from './Routes.js';
import VueApexCharts from 'vue-apexcharts'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import Vuex from 'vuex'
import VueLoading from 'vuejs-loading-plugin'
import {Utility} from './Pages/utility';
import VueSimpleAlert from "vue-simple-alert";

Vue.prototype.$Utility = Utility;
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    IsLogin: false
  },
  mutations: {
    Login(state) {
      state.IsLogin = true
    },
    LogOut(state) {
      state.IsLogin = false
    }
  }
})

Vue.use(VueSmoothScroll, {
  duration: 700,
  updateHistory: true,
});

Vue.use(VueLoading, {
  dark: true, // default false
  text: 'درحال بارگذاری', // default 'Loading'
  loading: true, // default false
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
});
// Vue.use(VueLoading);
Vue.use(VueApexCharts);
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(VueMeta);
Vue.use(VueCookie);
Vue.use(VueSimpleAlert);
Vue.component('date-picker', VuePersianDatetimePicker);


Vue.component('apexchart', VueApexCharts);
Vue.http.options.root = "https://www.karvam.ir/api";
Vue.http.interceptor.before = function () {

  this.$loading(true);

};
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Accept', 'application/json');
  request.headers.set('Access-Control-Allow-Origin', '*');
  var token = Vue.cookie.get('ApiToken');
  if (token != null) {
    request.headers.set('Authorization', 'Bearer ' + token);
  }
  next();
  return function () {
    this.$loading(false);
  };
});
Vue.config.productionTip = true

export const router = new VueRouter({
  routes: Routes,
  // mode: 'history',
  mode: 'hash',
  base: "/vu",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else if (to.hash) {
      return {
        behavior: 'smooth',
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  }
});

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app');


