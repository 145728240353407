<template>
  <section class="section-rank py-5">
    <div class="titleSlider">
      <h2>کارنامه ما</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="card" style="border: none">
            <div class="row no-gutters py-3">
              <div class="col-3 align-self-center">
                <img
                  src="../../../assets/img/rank/ir1.png"
                  class="card-img"
                  alt="..."
                />
              </div>
              <div class="col-9">
                <div class="card-body">
                  <h3 class="card-title">
                    {{ formatPrice(item.statistics.projects) }} پروژه
                  </h3>
                  <p class="card-text">پروژه های ایجاد شده</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="card" style="border: none">
            <div class="row no-gutters py-3">
              <div class="col-3 align-self-center">
                <img
                  src="../../../assets/img/rank/lightbulb1.png"
                  class="card-img"
                  alt="..."
                />
              </div>
              <div class="col-9">
                <div class="card-body">
                  <h3 class="card-title">
                    {{ formatPrice(item.statistics.participation_rate) }} تومان
                  </h3>
                  <p class="card-text">مشارکت ها</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="card" style="border: none">
            <div class="row no-gutters py-3">
              <div class="col-3 align-self-center">
                <img
                  src="../../../assets/img/rank/stopwatch1.png"
                  class="card-img"
                  alt="..."
                />
              </div>
              <div class="col-9">
                <div class="card-body">
                  <h3 class="card-title">
                    {{ formatPrice(item.statistics.project_in_queue) }} مورد
                  </h3>
                  <p class="card-text">در انتظار تأمین</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="card" style="border: none">
            <div class="row no-gutters py-3">
              <div class="col-3 align-self-center">
                <img
                  src="../../../assets/img/rank/team1.png"
                  class="card-img"
                  alt="..."
                />
              </div>
              <div class="col-9">
                <div class="card-body">
                  <h3 class="card-title">
                    {{ formatPrice(item.statistics.jobs.direct) }} مورد
                  </h3>
                  <p class="card-text">اشتغال ایجاد شده</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="card" style="border: none">
            <div class="row no-gutters py-3">
              <div class="col-3 align-self-center">
                <img
                  src="../../../assets/img/rank/Group1.png"
                  class="card-img"
                  alt="..."
                />
              </div>
              <div class="col-9">
                <div class="card-body">
                  <h3 class="card-title">
                    {{ formatPrice(item.statistics.benefactors) }} نفر
                  </h3>
                  <p class="card-text">تأمین شده</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="card" style="border: none">
            <div class="row no-gutters py-3">
              <div class="col-3 align-self-center">
                <img
                  src="../../../assets/img/rank/team1.png"
                  class="card-img"
                  alt="..."
                />
              </div>
              <div class="col-9">
                <div class="card-body">
                  <h3 class="card-title">
                    {{ formatPrice(item.statistics.consultants) }} نفر
                  </h3>
                  <p class="card-text">معرفان</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-services id="services"></app-services>
  </section>
</template>

<script>
import services from "../Components/Services";
export default {
  data() {
    return {
      item: {
        statistics: {
          participation_rate: 1668992000,
          projects: 89,
          province: 18,
          project_in_queue: 2,
          jobs: {
            direct: 131,
            indirect: 131,
          },
          benefactors: 5,
          consultants: 24,
        },
      },
    };
  },
  components: {
    "app-services": services,
  },
  methods: {
    formatPrice(value) {
      var ir = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
      var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      let val = value / 1;
      var result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      result = result.toString();
      for (let index = 0; index < en.length; index++) {
        result = result.replaceAll(en[index], ir[index]);
      }
      return result;
    },
  },
  created() {
    this.$http.get("statistics").then(
      (response) => {
        if (response.status == 200) {
          this.item = response.body;
        }
      },
      (data) => {
        if (data.status == 200) {
          this.item = data.body;
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>
$themeColorGreen: #3bcf93;
$themeColorDark: #2e2e2e;
$themeColorLight: #afafaf;
$textColorDark: #303030;

.section-rank {
  .titleSlider {
    background-image: url("../../../assets/img/rank/Vector-56.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    h2 {
      font-family: KalamehBold;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.02em;
      color: #303030;
      text-align: center;
      &::before {
        content: url(../../../assets/img/support/Rectangle.png);
        position: relative;
        left: 10px;
      }
      &::after {
        content: url(../../../assets/img/support/Rectangle.png);
        position: relative;
        right: 10px;
      }
    }
  }
  .card-body {
    h3 {
      color: $themeColorGreen;
      font-weight: bold;
      font-family: KalamehBold;
    }
  }
  .card {
    min-height: 180px;
  }
  .card-body {
    p {
      font-family: IRANSansWeb;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      text-align: right;
      color: #5e5e5e;
    }
  }
}
</style>